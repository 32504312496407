import React, { useEffect, useState } from "react";
import Button from "components/elements/Button";
import Stack from "components/elements/stack/Stack";
import Voices from "../Voices";
import AudioPlayer from "components/elements/AudioPlayer";
import EventBus from "common/EventBus";
import { setActiveVoice } from "hooks/useActiveVoice";
import { Voice } from "types/Voice";

export interface VoicesSelectProps {
	show: boolean
	voices:Voice[]
	onClose: (item:any) => void
}


const VoicesSelect: React.FC<VoicesSelectProps> = ({
	show,
	voices,
	onClose
}) => {
	const [showAdd, setShowAdd] = useState<boolean>(false)
	const [selectedVoices, setSelectedVoices] = useState<Voice[]>([])

	useEffect(() => {
		setSelectedVoices([])
		setShowAdd(show)

		if ( show ) {
			EventBus.dispatch( "stackOpen", null )
		} else {
			EventBus.dispatch( "stackClosed", null )
		}
	}, [show])

	const changeHandler = (e) => {
		setSelectedVoices(e)
	}

	const close = () => {
		setShowAdd(false)
		onClose(null)
		resetData()
	}


	const save = (e) => {
		e.preventDefault()
		setShowAdd(false)
		onClose( selectedVoices )
		resetData()

	}

	const resetData = () => {
		setActiveVoice( null )
	}
	

	return (
		<>
			<Stack size="full" isShow={showAdd} onClose={() => close()} title="Select the voices">
				<div className="px-8 mt-12 mb-8 ">
					<h3 className="text-xl font-medium">Add a voices to your project</h3>
					<p className="text-sm">Select the voices that you would like to add to your project</p>
				</div>

				
				<Voices selectableVoices={true} selectedVoices={voices} onChange={changeHandler}></Voices>

				<form onSubmit={save}>
					<div className="flex px-8 my-12 space-x-4">
						<Button type="primary" submit={true}>Add selected voices</Button>
						<Button type="grayoutline" onClick={() => close()}>Cancel</Button>
					</div>
				</form>
				<AudioPlayer inStack={true} />
			</Stack>
		</>
	)
}

export default VoicesSelect