import React, { useState, useEffect, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import useStyling from 'hooks/useStyling'
import { useGetClients } from 'hooks/api/clients'
import Button from 'components/elements/Button'
import MainPage from 'components/elements/page/MainPage'
import Pagination from 'components/elements/Pagination'
import Panel from 'components/elements/Panel'
import ClientCreate from './client/ClientCreate'
import LoaderSpinning from 'components/elements/LoaderSpinning'
import Empty from 'components/elements/Empty'
import { Client } from "types/Client";

export default function Clients ( props: any ) {
	const history = useHistory()
	const { switchClassNames } = useStyling()

	const pageSize = 30;
	const [currentPage, setCurrentPage] = useState( 1 )
	const [totalItems, setTotalItems] = useState( 0 )

	const { data, isLoading, isFetching } = useGetClients({ itemsPerPage: pageSize, page: currentPage });
	const [clients, setClients] = useState<Client[]>([])
	const [showAddClient, setShowAddClient] = useState<boolean>( false )

	useEffect(() => {
		if (!data) return
		setClients(data.items)
		setTotalItems(data.totalItems)
	}, [data])

	const addClient = () => {
		setShowAddClient( true )
	}

	const closeAddClient = () => {
		setShowAddClient( false )
	}

	const openClient = ( id ) => {
		history.push(`/clients/${id}/details`)
	}

	const actions = useMemo( () => {
		return (
			<Button onClick={() => addClient()}>Add Client</Button>
		)
	}, [] )

	return (
		<MainPage headerActions={actions} isLoading={isLoading}>
			<Panel className="px-0 py-0">
				<div className="flex flex-col">
					<div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
						<div className="inline-block min-w-full align-middle sm:px-6 lg:px-8">
							<div className="overflow-hidden ">
								{isLoading || isFetching ? <LoaderSpinning className="p-8">Loading clients...</LoaderSpinning> :
									(data && clients.length > 0 ?
										<table className="min-w-full divide-y divide-gray-200">
											<thead className="border-b border-gray-200">
												<tr>
													<th scope="col" className="px-6 pt-5 pb-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">Name</th>
													<th scope="col" className="px-6 pt-5 pb-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase md:table-cell">Number of projects</th>
													<th scope="col" className="px-6 pt-5 pb-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase md:table-cell">Number of contacts</th>
												</tr>
											</thead>
											<tbody>
												{data && !isLoading && clients.map( ( client, index ) => (
													<tr key={client.id} className={switchClassNames( index % 2 === 0 ? 'bg-transparent' : 'bg-transparent', 'hover:bg-blacks-50 hover:text-cobalt-500 hover:bg-opacity-5 cursor-pointer transition-colors duration-150' )} onClick={() => openClient( client.id )}>
														<td className="px-6 py-4 text-sm text-cobalt-500 whitespace-nowrap lg:w-96">{client.company}</td>
														<td className="px-6 py-4 text-sm whitespace-nowrap">{client.clientProjects.length}</td>
														<td className="px-6 py-4 text-sm whitespace-nowrap">{client.numberOfContacts}</td>
													</tr>
												))}
											</tbody>
										</table> : <Empty className="p-8">Create a client</Empty>
									)
								}
							</div>
						</div>
					</div>
				</div>
			</Panel>
			<Pagination
				className="pagination-bar"
				currentPage={currentPage}
				totalCount={totalItems}
				pageSize={pageSize}
				onPageChange={page => setCurrentPage( page )}
			/>
			<ClientCreate show={showAddClient} onClose={() => closeAddClient()} />
		</MainPage>
	)
}