import React, { useState } from 'react'
import MainPage from 'components/elements/page/MainPage'
import LoaderSpinning from 'components/elements/LoaderSpinning'
import NotificationItem from 'components/views/app/notification/NotificationItem'
import Button from 'components/elements/Button'
import { useNotifications } from 'hooks/useNotifications'
import Empty from 'components/elements/Empty'


export default function Notifications() {
	const pageSize = 30
	const [currentPage, setCurrentPage] = useState<number>(1)
	const {data: notifications, isFetching, moreAvailable} = useNotifications({ currentPage, pageSize })

	return (
		<MainPage className="overflow-y-scroll">
			{notifications.map((item, index) => (
				<NotificationItem key={index} notification={item} />
			))}

			{moreAvailable &&
				<div className="mx-auto w-fit">
					<Button type='primary' onClick={() => setCurrentPage(currentPage + 1)} loading={isFetching}>More notifications</Button>
				</div>
			}
			{isFetching && notifications.length === 0 && <LoaderSpinning>Loading notifications...</LoaderSpinning>}
			<Empty className="mt-4">Your notifications will appear here</Empty>
		</MainPage>
	)
}