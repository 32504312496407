import { useState, useEffect } from 'react'
import { useQuery, useMutation } from 'react-query'
import { getAddress, postAddress, putAddress } from 'services/requests/addresses'
import useQueryClient from 'hooks/useQueryClient'
import { useGetCustomer } from 'hooks/api/customers'
import { Address, AddressDTO } from 'types/Address'


export const useGetAddressByCustomerId = (customerId) => {
	const [addressId, setAddressId] = useState<number>(0)
	const { data: customerData } = useGetCustomer(customerId)

	useEffect(() => {
		if (!customerData) return
		setAddressId(customerData.defaultAddress.id)
	}, [customerData])

	return useGetAddress(addressId)
}

const useGetAddress = (id: number) => {
	return useQuery(['addresses', id], () => getAddress(id), {
		enabled: !!id
	})
}

export const usePostAddress = () => {
	const queryClient = useQueryClient()
	return useMutation<any, unknown, AddressDTO>(address => postAddress(address), {
		onSuccess: (address) => {
			const addressId = address.customer.replace('/api/v2/shop/customers/', '')
			queryClient.invalidateQueries(['clients', addressId])
		}
	})
}

export const usePutAddress = (id: number) => {
	const queryClient = useQueryClient()
	return useMutation<any, unknown, Address>(address => putAddress(id, address), {
		onSuccess: (address) => {
			const addressId = address.customer.replace('/api/v2/shop/customers/', '')
			queryClient.invalidateQueries(['clients', addressId])
		}
	})
}
