import React from 'react'
import Loader from './Loader';

export interface ButtonProps extends React.HTMLAttributes < HTMLElement > {
	type?: 'primary' | 'danger' | 'warning' | 'outline' | 'grayoutline'
	size?: 's' | 'default'
	disabled?: boolean
	onClick?: (event: React.MouseEvent) => void;
	submit?: boolean
	block?: boolean
	loading?: boolean
    style?: React.CSSProperties
};

const sizeStyle = {
	s: 'px-4 py-1 text-sm font-bold antialiased',
	default: 'px-8 py-2 text-sm md:text-base font-bold antialiased',
};

const typeStyle = {
	primary: 'bg-cobalt-500 text-white border border-cobalt-500 hover:bg-cobalt-400 hover:border-cobalt-400 ',
	danger: 'bg-red-700 text-white border border-red-700 hover:bg-red-500 hover:border-red-500 ',
	warning: 'bg-yellow-500 text-white border border-yellow-500 hover:bg-yellow-400 hover:border-yellow-400 ',
	outline: 'bg-transparent border border-cobalt-500 text-cobalt-500 hover:bg-cobalt-500 hover:text-white ',
	grayoutline: 'bg-transparent border border-gray-700 text-gray-700 hover:bg-gray-100 hover:border-cobalt-500 hover:text-cobalt-500 ',
};

const Button: React.FC<ButtonProps> = ({
										   className = '',
	type = 'primary',
	size = 'default',
	disabled = false,
	onClick,
	submit,
	block = false,
	loading = false,
	children,
    style
}) => (
	<button
		className={` relative font-sans transition-colors duration-300 ease-in-out rounded-md leading-none
    	${typeStyle[type]} 
    	${sizeStyle[size]} 
    	${block ? 'w-full' : 'w-max'} 
		${loading ? 'cursor-wait' : ''}
		${className}
    	${disabled ? 'opacity-50 cursor-not-allowed' : ''}`}
		style={style}
		onClick={onClick}
		disabled={loading || disabled}
		type={submit ? 'submit' : 'button'}
	>
		{children}
		{loading && <Loader />}
	</button>
);

export default Button;
