export interface ThemeColor {
    id: number
    type: string
    color: string
}

export const themeColorInit: ThemeColor =  {
    id: 0,
    type: '',
    color: ''
}


export function serializeThemeColor( data ): ThemeColor {
    if ( !data ) return themeColorInit;

    return {
        ...themeColorInit,
        id: data.id ? data.id : 0,
        type: data.type,
        color: data.color,
    }
}
