import { useEffect, useState } from 'react';

export default function FakeLoader() {

	const [currentStep, setCurrentStep] = useState<number>(0)
	const [progress, setProgress] = useState<number>(0)

	useEffect(() => {
		let interval = setInterval(() => {
			setCurrentStep(currentStep => currentStep + 0.5);
		}, 200);

		return () => {
			clearInterval(interval)
		}
	}, [])

	useEffect(() => {
		setProgress(Math.round(Math.atan(currentStep) / (Math.PI / 2) * 100 * 1000) / 1000)
	},[currentStep])

	const style = {
		width: progress + '%'
	}

	return (
		<div className="h-full transition-all duration-300 ease-in-out bg-cobalt-500" style={style}></div>
	)

}