import React from 'react';
import { RouteElement } from 'Routes'
import { Route } from 'react-router-dom';


const RouteBuilder: React.FC<
	{
		routes: RouteElement[]
		data?: any
	}
> = ( props => {

	return (
		<>
			{props.routes.map((route, index) => (
				<React.Fragment key={route.path}>
					<Route
						key={'route-' + route.path}
						path={route.path}
						strict={true}
						exact={route.exact}
						render={routeProps => (
						
							<route.main {...routeProps} routes={route.routes} data={props.data? props.data: null} />
							
						)}
						
					/>
					{/* {route.routes.length > 0 && <RouteBuilder routes={route.routes} />} */}
				</React.Fragment>
				
				
			))}
		</>
	)
})

export default RouteBuilder