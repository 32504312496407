export interface Comment {
    id?: number
    comment: string
    createdAt?: string
    children?: Comment[]
    payload?: any
    project?: { id: number, idPath: string }
}

export interface CommentDTO {
    id?: number
    comment: string
    customer: string
    project: string
    parent?: string
    payload?: any
}

export const commentInit: Comment =  {
    comment: ''
}

export function serializeComment(data: any): Comment {
    return {
        id: +data['@id'].replace('/api/v2/shop/project-comments/', ''),
        comment: data.comment,
        children: data.children.map(item => typeof(item) === 'string' ? commentInit : serializeComment(item)),
        createdAt: data.createdAt,
        payload: data.payload,
        project: {
            id: +data.project.replace('/api/v2/shop/projects/', ''),
            idPath: data.project
        }
    }
}

export function serializeProjectComments(data: any): {items: Comment[], totalItems: number } {
    return {
        items: data['hydra:member'].map(item => serializeComment(item)),
        totalItems: data['hydra:totalItems']
    }
}

export function serializeComments(data: any): {items: Comment[], totalItems: number } {
    return {
        items: data['hydra:member'].map(item => serializeComment(item)),
        totalItems: data['hydra:totalItems']
    }
}
