import {useState} from 'react'

import SideBar from 'components/elements/sidebar/SideBar'
import TopBar from 'components/elements/topbar/TopBar'
import AudioPlayer from 'components/elements/AudioPlayer'
import WhatsAppCode from '../elements/WhatsAppCode'

export default function AppLayout(props:any) {
	const [sidebarOpen, setSidebarOpen] = useState(false)

	return (
		<div className="flex bg-isabelline-500 lg:h-screen lg:overflow-hidden">
			
			<SideBar open={sidebarOpen} onClose={setSidebarOpen} />

			<div className="flex flex-col flex-1 w-0 overflow-hidden transition-all duration-300">
				
				<TopBar openSideBar={setSidebarOpen} />	

				<main className="relative flex-1 overflow-y-auto focus:outline-none">
					<div className="main-content">
					{props.children}
					</div>
					<AudioPlayer />
				</main>
			</div>
			<WhatsAppCode/>
		</div>
	)
}