import React from 'react'
import { Transition } from "@headlessui/react";
import { Fragment } from "react";
import StackHeader from "./StackHeader";

type StackProps = {
	size?: 'quarter' | 'third' | 'half' | 'full';
	isShow?: boolean
	title?: string
	onClose?: () => void
};

const sizeStyle = {
	quarter: 'w-full md:w-1/2 xl:w-1/4',
	third: 'w-full md:w-1/2 xl:w-1/3',
	half: 'w-full md:w-1/2',
	full: 'w-full',
};

const Stack: React.FC<StackProps> = ({
	size = 'third',
	isShow = false,
	title = "",
	onClose,
	children

}) => {

	

	return (

		<Transition.Root show={isShow} as={Fragment}>
			<div className="fixed inset-0 z-30 ">
			<Transition.Child
				as={Fragment}
				enter="transition-opacity ease-linear duration-300"
				enterFrom="opacity-0"
				enterTo="opacity-100"
				leave="transition-opacity ease-linear duration-300 delay-400"
				leaveFrom="opacity-100"
				leaveTo="opacity-0"
			>
					<div className={`absolute inset-0 overflow-hidden bg-black  bg-opacity-50
						
					`} >
						<div className="absolute inset-0 bg-transparent" onClick={onClose}></div>
					<Transition.Child
						as={Fragment}
						enter="transition ease-out duration-300 delay-200 transform"
						enterFrom="translate-x-full"
						enterTo="translate-x-0"
						leave="transition ease-in duration-200 transform"
						leaveFrom="translate-x-0"
						leaveTo="translate-x-full"
					>
						

						<div className={`absolute top-0 bottom-0 right-0 h-full overflow-auto bg-isabelline-500 shadow-xl
						${sizeStyle[size]} `} onClick={(e)=> e.preventDefault }>
							<StackHeader title={title} onClose={onClose} />
							<div className="">
								{children}
							</div>
						</div>
					</Transition.Child>
				</div>
			</Transition.Child>
			</div>
		</Transition.Root>
	)
}

export default Stack