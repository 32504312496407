import React, { useState, useEffect } from 'react'
import Button from 'components/elements/Button'
import Panel from 'components/elements/Panel'
import PanelTitle from 'components/elements/PanelTitle'
import PanelFooter from 'components/elements/PanelFooter'
import ProjectEdit from './ProjectEdit'
import VoicesGrid from 'components/elements/VoicesGrid'
import ProductAdd from './ProductAdd'
import SelectedProducts from 'components/views/app/order/SelectedProducts'
import ProjectFeedback from './ProjectFeedback'
import LoaderSpinning from "components/elements/LoaderSpinning";
import { useParams, useHistory } from 'react-router-dom'
import { useGetProject, usePutProjectVoices, usePutProjectState } from 'hooks/api/projects'
import { useAddProduct } from 'hooks/api/order-items'
import { useGetProjectComments } from 'hooks/api/comments'
import { useGetOrder } from 'hooks/api/orders'
import { dateFromString, dateToString, dateToStringWithTime } from 'common/DateUtils'
import { toast } from 'react-toastify';
import { useGetVoicesByUrlArray } from "hooks/api/voices"
import { Voice as VoiceElement } from "types/Voice"
import {useCurrentUserRole} from "../../../../hooks/useCurrentUserRole";


export default function ProjectOverview() {
	const { projectId } = useParams()
	const history = useHistory()
	const role = useCurrentUserRole()
	const { data, isLoading } = useGetProject(projectId);

	const [orderId, setOrderId] = useState('')

	const { data: dataOrder, isLoading: loadingOrder } = useGetOrder(orderId)
	const { data: dataComments } = useGetProjectComments(projectId, {})
	const { data: dataVoices, isLoading: loadingVoices, isFetching: fetchingVoices } = useGetVoicesByUrlArray(data?.voices)

	const [order, setOrder] = useState<any>({items:[]})

	const {mutate: putProjectVoices} = usePutProjectVoices(projectId);
	const {mutate: putProjectState, isLoading: loadingProjectState} = usePutProjectState(projectId);
	const {mutateAsync: addProduct} = useAddProduct(order, projectId, setOrderId)

	const [clientId, setClientId] = useState<number>(0)
	const [showEditProject, setShowEditProject] = useState<boolean>( false )
	const [showSelectVoices, setShowSelectVoices] = useState<boolean>( false )
	const [showProductAdd, setShowProductAdd] = useState<boolean>( false )
	const [showRequestFeedback, setShowRequestFeedback] = useState<boolean>( false )
	const [selectedVoices, setSelectedVoices] = useState<VoiceElement[]>([])

	useEffect(() =>{
		if(data && data.client){
			setClientId( parseInt(data.client.idPath.replace('/api/v2/shop/customers/','')))
		}
		if(data && data.cart){
			setOrderId( data.cart.token )
		} else {
			setOrderId( '' )
		}

	}, [data])

	useEffect( () => {
		if ( dataOrder ) {
			setOrder(dataOrder)
		}
	}, [dataOrder] )

	useEffect( () => {
		if(dataVoices !== null){
			setSelectedVoices(dataVoices ? dataVoices : [])
		}
	}, [dataVoices] )

	const closeEditProject = () => {
		setShowEditProject( false )
	}

	const closeProjectFeedback = () => {
		setShowRequestFeedback( false )
	}

	const voicesUpdateHandler = (items) =>{
		putProjectVoices({ voices:items })
	}

	const archiveProject = () => {
		const newState = data.state === 'open' ? 'archived' : 'open'
		putProjectState( { state: newState } )
	}

	const closeAddProduct = async (product) => {
		if (!product) return setShowProductAdd(false)
		return addProduct(product).then(() => {
			setShowProductAdd(false)
			toast.success( 'Product succesfully added' )
		})
	}

	const openClient = () => {
		history.push(`/clients/${clientId}/details`)
	}

	return (
		<div className="mt-8">
			<div className="flex flex-wrap -m-4">
				<div className="p-4 w-full xl:w-1/2">
					<Panel>
						<PanelTitle>Project details</PanelTitle>
						<div className="-mx-8 mt-8 border-t border-b border-gray-200">
							{isLoading ? <LoaderSpinning className="py-9">Loading project details...</LoaderSpinning> :
								<dl className="sm:divide-y sm:divide-gray-200">
									<div className="px-8 py-5 sm:grid sm:grid-cols-3 sm:gap-4">
										<dt className="text-sm font-medium text-gray-500">Project name</dt>
										<dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{data?.name ? data?.name : '-'}</dd>
									</div>
									{ role === "reseller" && <div className="px-8 py-5 sm:grid sm:grid-cols-3 sm:gap-4">
										<dt className="text-sm font-medium text-gray-500">Client</dt>
										<dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{data?.client ? data.client.idPath ? <button className="border-b border-gray-900 transition-colors duration-300 ease-in-out hover:text-cobalt-400 hover:border-cobalt-400" onClick={openClient}>{data.client.name}</button> : '-' : ''}</dd>
									</div> }
									<div className="px-8 py-5 sm:grid sm:grid-cols-3 sm:gap-4">
										<dt className="text-sm font-medium text-gray-500">Status</dt>
										<dd className="mt-1 text-sm text-gray-900 capitalize sm:mt-0 sm:col-span-2">{data?.state ? data?.state : '-'}</dd>
									</div>
									<div className="px-8 py-5 sm:grid sm:grid-cols-3 sm:gap-4">
										<dt className="text-sm font-medium text-gray-500">Created at</dt>
										<dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{data?.createdAt ? dateToStringWithTime(dateFromString(data?.createdAt)) : '-'}</dd>
									</div>
									<div className="px-8 py-5 sm:grid sm:grid-cols-3 sm:gap-4">
										<dt className="text-sm font-medium text-gray-500">Updated at</dt>
										<dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{data?.updatedAt ? dateToStringWithTime(dateFromString(data?.updatedAt)) : '-'}</dd>
									</div>
									<div className="px-8 py-5 sm:grid sm:grid-cols-3 sm:gap-4">
										<dt className="text-sm font-medium text-gray-500">Description</dt>
										<dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{data?.description ? data?.description : '-'}</dd>
									</div>
								</dl>
							}
						</div>
						{ role === "reseller" && <PanelFooter className="mt-0">
							<div className="flex justify-end space-x-4 w-full">
								<Button type='outline' onClick={archiveProject} loading={loadingProjectState}>{data && data.state === 'open' ? 'Archive project' : 'Unarchive project' }</Button>
								<Button type='outline' onClick={()=>setShowEditProject(true)}>Edit project</Button>
							</div>
						</PanelFooter> }
					</Panel>
				</div>

				<div className="p-4 w-full xl:w-1/2">
					<Panel>
						<div className="flex justify-between items-center">
							<PanelTitle>Selected voices</PanelTitle>
							<button onClick={() => setShowSelectVoices(true)} className="mb-4 text-sm text-cobalt-500 underline hover:no-underline hover:cursor-pointer">Select voices</button>
						</div>

						<div className="px-8 pt-8 pb-0 -mx-8 mt-4 border-t border-gray-200">
							{selectedVoices.length > 0 ? <p className="mb-6 text-sm text-gray-900">Here are the selected voices for this project. You can ask your client for feedback and add these voices later to your order.</p> : null}

							{isLoading || loadingVoices || fetchingVoices ?
								<LoaderSpinning className="mt-2">Loading selected voices...</LoaderSpinning> : <div className="mt-0">
									{data && <VoicesGrid voices={data?.voices} openSelect={showSelectVoices} onCloseSelect={()=>setShowSelectVoices(false)} onUpdate={voicesUpdateHandler}/>}
								</div>
							}
						</div>

						{ role === "reseller" && <PanelFooter className="mt-8">
							<div className="flex justify-end items-center space-x-4 w-full">
								{dataComments && dataComments.items.slice(-1).map((item, index) => (
									<p className="text-left grow" key={index}>Last feedback: {item.createdAt ? dateToString(dateFromString(item.createdAt)) : '-'}</p>
								))}
								<Button type='primary' onClick={() => setShowRequestFeedback( true )}>Request client feedback</Button>
							</div>
						</PanelFooter> }
					</Panel>
				</div>

				<div className="p-4 w-full">
					{data && data.client.idPath && <SelectedProducts order={order} voices={data.voices} setShowProductAdd={setShowProductAdd} isLoading={loadingOrder} />}
                    {data && !data.client.idPath && <Panel>
                        <div className="flex justify-between items-center">
                            <PanelTitle>Add products to your project</PanelTitle>
                        </div>
                        <div className="px-8 pt-16 pb-0 -mx-8 mt-4 border-t border-gray-200">
                            <p className="mb-4">Your project cannot have products without a client. Please add a client to this project.</p>
                            <Button onClick={() => setShowEditProject(true)}>Add client</Button>
                        </div>
                    </Panel>}
				</div>
			</div>

			{data && <ProjectEdit show={showEditProject} project={data} onClose={() => closeEditProject()} />}
			{data && <ProductAdd show={showProductAdd} onClose={(item) => closeAddProduct(item)} />}
			{data && <ProjectFeedback show={showRequestFeedback} voices={data.voices} client={clientId} onClose={() => closeProjectFeedback()} />}
		</div>
	)
}
