import React from 'react'

export interface PanelFooterProps extends React.HTMLAttributes<HTMLElement> {
}

const PanelFooter: React.FC<PanelFooterProps> = (props) => (
	<div className={`px-8 py-4 mt-8 -mx-8 -mb-8 text-right bg-black bg-opacity-5 ${props.className}`}>
		{props.children}
	</div>
)

export default PanelFooter
