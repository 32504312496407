import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { dateFromString, dateToString, dateToStringWithTime } from 'common/DateUtils'
import MainPage from 'components/elements/page/MainPage'
import LoaderSpinning from 'components/elements/LoaderSpinning'
import Empty from 'components/elements/Empty'
import PanelTitle from 'components/elements/PanelTitle'
import Panel from 'components/elements/Panel'
import { ClockIcon } from '@heroicons/react/outline'
import useToken from 'hooks/useToken'
import { useGetStats } from 'hooks/api/customers'
import { useNotifications } from 'hooks/useNotifications'
import {useCurrentUserRole} from "../../../hooks/useCurrentUserRole";


export default function Dashboard() {
    const history = useHistory()
    const {getLocalCustomerId} = useToken();

    const [totalProjectItems, setTotalProjectItems] = useState(0)
    const [totalOpenOrderItems, setTotalOrderItems] = useState(0)
    const [totalClientItems, setTotalClientItems] = useState(0)

    const {data: dataStats, isLoading, error} = useGetStats(getLocalCustomerId())
    const {data: notifications, isFetching} = useNotifications({currentPage: 1, pageSize: 100})

    const role = useCurrentUserRole();

    useEffect(() => {
        if (dataStats) {
            setTotalProjectItems(dataStats.openProjects)
            setTotalClientItems(dataStats.clients)
            setTotalOrderItems(dataStats.openOrders)
        }
    }, [dataStats])

    const openProject = (id) => {
        history.push(`/projects/${id}/overview`)
    }

    return (
        <MainPage title={
            dataStats ? 'Hello ' + dataStats.fullName :
            error ? 'Your reseller account is under review.' : ''
        }>
            {!dataStats && !error ? <LoaderSpinning className="p-8">Loading dashboard...</LoaderSpinning> :
             !dataStats ? 'Your dashboard will be available after our team evaluates your request. In the meantime you can explore voices.' : <>
            <span className="block relative -top-10 -mb-10 h-6">{'Welcome back!'}</span>
            <div className="mt-8">
                <div className="flex flex-wrap -m-4">

                    <div className={"flex p-4 w-full" + (role === 'reseller' ? " xl:w-1/3" : " xl:w-1/2")}>
                        <Panel className="text-center">
                            <span className="block mb-6 text-5xl md:text-6xl font-bold">{totalProjectItems}</span>
                            <PanelTitle>Open projects</PanelTitle>
                            <Link className="inline-flex items-center px-4 py-2 mt-2 w-max font-sans text-sm md:text-base antialiased font-bold leading-none text-white rounded-md border transition-colors duration-300 ease-in-out bg-cobalt-500 border-cobalt-500 hover:bg-cobalt-400 hover:border-cobalt-400" title="See more" to='/projects'>See more</Link>
                        </Panel>
                    </div>

                    <div className={"flex p-4 w-full" + (role === 'reseller' ? " xl:w-1/3" : " xl:w-1/2")}>
                        <Panel className="text-center">
                            <span className="block mb-6 text-5xl md:text-6xl font-bold">{totalOpenOrderItems}</span>
                            <PanelTitle>Orders in progress</PanelTitle>
                            <Link className="inline-flex items-center px-4 py-2 mt-2 w-max font-sans text-sm md:text-base antialiased font-bold leading-none text-white rounded-md border transition-colors duration-300 ease-in-out bg-cobalt-500 border-cobalt-500 hover:bg-cobalt-400 hover:border-cobalt-400" title="See more" to='/projects'>See more</Link>
                        </Panel>
                    </div>

                    {role === 'reseller' && <div className="flex p-4 w-full xl:w-1/3">
                        <Panel className="text-center">
                            <span className="block mb-6 text-5xl md:text-6xl font-bold">{totalClientItems}</span>
                            <PanelTitle>Clients</PanelTitle>
                            <Link className="inline-flex items-center px-4 py-2 mt-2 w-max font-sans text-sm md:text-base antialiased font-bold leading-none text-white rounded-md border transition-colors duration-300 ease-in-out bg-cobalt-500 border-cobalt-500 hover:bg-cobalt-400 hover:border-cobalt-400" title="See more" to='/clients'>See more</Link>
                        </Panel>
                    </div>}

                    <div className="p-4 w-full xl:w-1/2">
                        <Panel className="pb-0">
                            <div className="flex justify-between items-center">
                                <PanelTitle>Latest notifications</PanelTitle>
                                <span className="float-right font-normal">
                                    <Link className="inline-block mb-4 text-sm underline text-cobalt-500 hover:no-underline hover:cursor-pointer" title="See more" to='/notifications'>See more</Link>
                                </span>
                            </div>

                            <div className="-mx-8 mt-4 border-t border-b border-gray-200">
                                <dl className="sm:divide-y sm:divide-gray-200 last:bottom-0">
                                    {notifications.slice(0, 4).map((item, index) =>
                                        <div className="px-8 py-5" key={index}>
                                            <div className="flex justify-between items-center">
                                                <div className="text-sm font-bold text-gray-900">{item.title}</div>
                                                <div className="inline relative -top-px text-xs text-gray-400">
                                                    <ClockIcon className="inline relative top-px mr-1 w-4 h-4 text-xs" aria-hidden="true"/>
                                                    <span className="relative top-0.5">{dateToStringWithTime(dateFromString(item.createdAt ? item.createdAt : ''))}</span>
                                                </div>
                                            </div>
                                            <div className="mt-2 text-sm font-medium text-gray-900">{item.message}</div>
                                        </div>
                                    )}

                                    {isFetching && notifications.length === 0 && <LoaderSpinning className="p-8">Loading notifications...</LoaderSpinning>}
                                    {!isFetching && notifications.length === 0 && <Empty className="p-8">Your most recent notifications will appear here</Empty>}
                                </dl>
                            </div>
                        </Panel>
                    </div>

                    <div className="p-4 w-full xl:w-1/2">
                        <Panel className="pb-0">
                            <div className="flex justify-between items-center">
                                <PanelTitle>Recent projects</PanelTitle>
                                <span className="float-right font-normal">
                                    <Link className="inline-block mb-4 text-sm underline text-cobalt-500 hover:no-underline hover:cursor-pointer" title="See more" to='/projects'>See more</Link>
                                </span>
                            </div>

                            <div className="-mx-8 mt-4 border-t border-b border-gray-200">
                                {isLoading ? <LoaderSpinning className="p-8">Loading projects...</LoaderSpinning>
                                    : (dataStats && dataStats.projects.length > 0
                                        ? <dl>
                                            {dataStats && dataStats.projects.map((project) => (
                                                <div key={project.id}
                                                     className='grid grid-cols-2 px-8 py-5 bg-transparent cursor-pointer hover:bg-blacks-50 hover:text-cobalt-500 hover:bg-opacity-5 md:grid-cols-4 sm:gap-4'
                                                     onClick={() => openProject(project.id)}>
                                                    <dd className="overflow-hidden mb-2 text-sm overflow-ellipsis whitespace-nowrap text-cobalt-500 md:mb-0">{project.name}</dd>
                                                    <dd className="mb-2 text-sm text-right md:mb-0 md:text-left">{project.client.name}</dd>
                                                    <dd className="hidden text-sm capitalize md:table-cell">{project.state}</dd>
                                                    <dd className="hidden text-sm md:table-cell">{project.createdAt && dateToString(dateFromString(project.createdAt))}</dd>
                                                </div>
                                            ))}
                                        </dl>
                                        : <Empty className="p-8">Your most recent projects will appear here</Empty>
                                    )
                                }
                            </div>
                        </Panel>
                    </div>
                </div>
            </div></>}
        </MainPage>
    )
}
