import request from "services/Request"
import { serializeClients, serializeClient } from "types/Client"

export function getClients(params) {
	return request({
		url: `/shop/clients`,
		method: 'GET',
		params
	}, serializeClients)
}

export function getClient(id: number) {
	return request({
		url: `/shop/clients/${id}`,
		method: 'GET'
	}, serializeClient)
}

export function postClient(data) {
	return request({
		url: `/shop/clients`,
		method: 'POST',
		data
	}, serializeClient)
}

export function putClient(id: number, data) {
	return request({
		url: `/shop/clients/${id}`,
		method: 'PUT',
		data
	}, serializeClient)
}

// export function patchClient(id: number, data) {
// 	return request({
// 		url: `/shop/clients/${id}`,
// 		method: 'PATCH',
// 		headers: {
// 			"Content-Type": "application/merge-patch+json"
// 		},
// 		data
// 	}, serializeClient)
// }

export function deleteClient(id: number) {
	return request({
		url: `/shop/clients/${id}`,
		method: 'DELETE'
	}, serializeClient)
}
