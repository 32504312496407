import React from 'react'
import { Link } from 'react-router-dom'

export default function NotFoundError(){
	return (
		<div className="px-4 py-16 min-h-screen bg-isabelline-500 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
			<div className="mx-auto max-w-max">
				<main className="sm:flex">
					<p className="text-4xl font-extrabold text-gray-900 sm:text-5xl">404</p>
					<div className="sm:ml-6">
						<div className="sm:border-l sm:border-gray-200 sm:pl-6">
							<h1 className="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl">Page not found</h1>
							<p className="mt-2 text-base text-gray-500">Please check the URL in the address bar and try again.</p>
						</div>
						<div className="flex mt-10 space-x-3 sm:border-l sm:border-transparent sm:pl-6">
							<Link
								to="/dashboard"
								className="inline-flex items-center px-4 py-2 text-sm font-bold font-sans text-white rounded-md border border-transparent shadow-sm transition-colors duration-300 ease-in-out bg-cobalt-500 hover:bg-cobalt-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cobalt-500"
							>
								Go back home
							</Link>
						</div>
					</div>
				</main>
			</div>
		</div>
	)
}