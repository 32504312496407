export interface OrderItem {
    id?: number
    idPath?: string
    productName: string
    variant: string
    quantity: number
    instructions: OrderItemInstruction[]
    reference: string
    script: string
    remarks: string
    voice: string
    postProcessingOptions: string[]
    postProcessingVariants: string[]
    total: number
    price: number
    tax: number
}

export const orderItemInit: OrderItem = {
    id: 0,
    idPath: '',
    productName: '',
    variant: '',
    quantity: 0,
    instructions: [],
    reference: '',
    script: '',
    remarks: '',
    voice: '',
    postProcessingOptions: [],
    postProcessingVariants: [],
    total: 0,
    price: 0,
    tax: 0
}

export interface OrderItemDTO {
    id?: number
    idPath?: string
    productName: string
    productVariant: string
    quantity: number
    instructions: OrderItemInstruction[]
    total: number
}

export const orderItemDTOInit: OrderItemDTO = {
    id: 0,
    idPath: '',
    productName: '',
    productVariant: '',
    quantity: 0,
    instructions: [],
    total: 0
}

export const instructionTypes = ['script', 'comment', 'reference', 'instruction']

export interface OrderItemInstruction {
    id?: number
    idPath?: string
    type: string
    instruction: string
    payload?: any
}

// const orderItemInstructionInit: OrderItemInstruction = {
//     id: 0,
//     idPath: '',
//     type: '',
//     instruction: ''
// }

export function serializeOrderItem(data: any): OrderItem {
    const postProcessingOptions = getInstructions(data,'post_processing')
    return {
        id: data.id ? data.id : 0,
        idPath: data['@id'],
        productName: data.productName,
        variant: data.variant,
        quantity: data.quantity,
        instructions: data.instructions,
        reference: getInstructions(data,'reference'),
        script: getInstructions(data,'script'),
        remarks: getInstructions(data,'comment'),
        voice: getInstructions(data,'voice'),
        postProcessingOptions: postProcessingOptions,
        postProcessingVariants: postProcessingOptions.length ? postProcessingOptions.map(
            item => item.replace('products', 'product-variants')
        ) : [],
        total: data.total,
        price: data.unitPrice,
        tax: data.total - data.unitPrice
    }
}

const getInstructions = (product, type) => {
    const instructionType = (type === 'voice' || type === 'post_processing') ? 'instruction' : type
    let results = product.instructions.filter(item => item.type === instructionType)
    if (results.length === 0) return ''
    if (instructionType !== 'instruction') return results[0].instruction
    results = results.filter(item => item.payload.type === type)
    if (results.length > 0 && type === 'voice') return results[0].instruction
    return results.map(item => item.instruction)
}
