import request from "services/Request"
import { serializeTheme } from "types/Theme"

export function getTheme(id: number) {
    return request({
        url: `/shop/themes/${id}`,
        method: 'GET'
    }, serializeTheme)
}

export function postTheme(data) {
    return request({
        url: `/shop/themes`,
        method: 'POST',
        data
    }, serializeTheme)
}

export function putTheme(id: number, data) {
    return request({
        url: `/shop/themes/${id}`,
        method: 'PUT',
        data
    }, serializeTheme)
}