import { useEffect, useState } from 'react'
import { useQuery, useMutation } from 'react-query'
import { getClients, getClient, postClient, putClient, deleteClient } from 'services/requests/clients'
import useQueryClient from 'hooks/useQueryClient'
import { Client, ClientDTO, clientDTOInit } from 'types/Client'

export const useGetClients = (params) => {
	const defaultParams = {
		page: 1,
		itemsPerPage: 30
	}

	return useQuery(
		['clients', {...defaultParams, ...params}],
		() => getClients({...defaultParams, ...params})
	)
}

export const useGetClient = (id: number) => {
	return useQuery(
		['clients', id.toString()],
		() => getClient(id),
		{enabled: !!id}
	)
}

export const usePostClient = () => {
	const queryClient = useQueryClient()
	return useMutation<any, unknown, ClientDTO>(client => postClient(client), {
		onSuccess: data => {
			queryClient.invalidateQueries(['clients'])
			queryClient.invalidateQueries(['stats'])
		}
	})
}

export const usePutClient = (clientId: number) => {
	const queryClient = useQueryClient()
	return useMutation<any, any, ClientDTO>(client => putClient(clientId, client), {
		onSuccess: data => queryClient.invalidateQueries(['clients', clientId.toString()])
	})
}

export const useDeleteClient = (id: number) => {
	const queryClient = useQueryClient()
	return useMutation(() => deleteClient(id), {
		onSuccess: data => {
			queryClient.invalidateQueries(['clients', id.toString()])
			queryClient.invalidateQueries(['clients'])
			queryClient.invalidateQueries(['stats'])
		}
	})
}

export const useClientDTO = ( data: Client ) => {
	const [clientDTO, setClientDTO] = useState<ClientDTO>(clientDTOInit)

	useEffect(()=>{
		const generateDTO = (data: Client) =>{
			setClientDTO({
				email: data.email,
				company: data.company,
				firstName: data.firstName,
				lastName: data.lastName,
				kvkNumber: data.kvkNumber,
				vatNumber: data.vatNumber
			})
		}

		if ( data ) {
			generateDTO(data);
		}
	},[data])

	return {clientDTO}

}
