import request from "services/Request"
import { serializeOrders, serializeOrder } from "types/Order"

export function getOrders(params) {
	return request({
		url: `/shop/orders`,
		method: 'GET',
		params
	}, serializeOrders)
}

export function getOrder(id: string) {
	return request({
		url: `/shop/orders/${id}`,
		method: 'GET'
	}, serializeOrder)
}

export function postOrder(data) {
	return request({
		url: `/shop/orders`,
		method: 'POST',
		data
	}, serializeOrder)
}

export function putOrder(id: string, data) {
	return request({
		url: `/shop/orders/${id}`,
		method: 'PUT',
		data
	}, serializeOrder)
}

// export function patchOrder(id: string, data) {
// 	return request({
// 		url: `/shop/orders/${id}`,
// 		method: 'PATCH',
// 		headers: {
// 			"Content-Type": "application/merge-patch+json"
// 		},
// 		data
// 	}, serializeOrder)
// }

export function patchOrderPark(id: string, data) {
	return request({
		url: `/shop/orders/${id}/park`,
		method: 'PATCH',
		headers: {
			"Content-Type": "application/merge-patch+json"
		},
		data
	}, serializeOrder)
}

export function patchOrderUnpark(id: string, data) {
	return request({
		url: `/shop/orders/${id}/unpark`,
		method: 'PATCH',
		headers: {
			"Content-Type": "application/merge-patch+json"
		},
		data
	}, serializeOrder)
}

export function patchOrderPayment(id: string, paymentId: number, data) {
	return request({
		url: `/shop/orders/${id}/payments/${paymentId}`,
		method: 'PATCH',
		headers: {
			"Content-Type": "application/merge-patch+json"
		},
		data
	}, serializeOrder)
}

export function patchOrderComplete(id: string, data) {
	return request({
		url: `/shop/orders/${id}/complete`,
		method: 'PATCH',
		headers: {
			"Content-Type": "application/merge-patch+json"
		},
		data
	}, serializeOrder)
}

export function deleteOrder(id: string) {
	return request({
		url: `/shop/orders/${id}`,
		method: 'DELETE'
	}, serializeOrder)
}
