import { useMutation } from 'react-query'
import { useQueryClient } from 'react-query'
import { apiAxios } from 'services/Api'
import { postOrderItem } from 'services/requests/order-items'
import { usePatchOrderPark, usePatchOrderUnpark } from './base'
import { usePostAndAttachOrder } from './orders'

export function usePostOrderItem(orderId) {
	return useMutation<any, unknown, {productVariant: string, quantity: number}>(item => postOrderItem(orderId, item))
}

export const useAddProduct = (order, projectId, setOrderId) => {
	const { mutateAsync: postAndAttachOrder } = usePostAndAttachOrder(projectId);
	const { mutateAsync: createOrderItem } = useCreateOrderItem();
	return useMutation((product) => addProduct(product))

	function addProduct(product) {
		const index = getIndexForNewOrderItem(product)
		if (order && (order.state === 'parked' || order.state === 'cart')) {
			return addOrderItem(product, index, order.code)
		} else {
			return createOrderAndAddOrderItem(product, index)
		}
	}

	function createOrderAndAddOrderItem(product, index = 0,) {
		return postAndAttachOrder().then(order => {
			setOrderId(order.code)
			return addOrderItem(product, index, order.code)
		})
	}

	function addOrderItem(product, index = 0, orderId = '') {
		return createOrderItem({product, orderId, index}, {
			onError: () => createOrderAndAddOrderItem(product, index)
		})
	}

	function getIndexForNewOrderItem(product) {
		let index = 0
		if (!order) return index
		for (let item of order.items) {
			if (item.variant === product.productVariant) {
				const lastInstruction = item.instructions[item.instructions.length - 1]
				index = lastInstruction.payload ? lastInstruction.payload.index + 1 : 0
			}
		}
		return index
	}
}

export const useCreateOrderItem = () => {
	const queryClient = useQueryClient()
	const { mutateAsync: park } = usePatchOrderPark()
	const { mutateAsync: unpark } = usePatchOrderUnpark()
	return useMutation<any, unknown, any>(({product, orderId, index}) => createOrderItem(product, orderId, index), {
		onMutate: async variables => await unpark(variables.orderId),
		onSettled: async data => {
			queryClient.invalidateQueries(['orders', data.orderId])
			return await park(data.orderId)
		}
	})

	function createOrderItem(product, orderId, index) {
		return apiAxios.post(`/shop/orders/${orderId}/items`, product).then(async result => {
			if (!result.data.items) return
			const orderItem = result.data.items.filter(item => item.variant === product.productVariant)[0]['@id']
			// instruction for voice
			apiAxios.post(`/shop/order-item-instructions`, {
				orderItem,
				instruction: '',
				type: 'instruction',
				payload: {type: 'voice', index}
			})
			return Promise.all(product.instructions.map(async instruction => {
				instruction.payload = {...instruction.payload, index}
				if (instruction.payload.type === 'post_processing') {
					//const variant = instruction.instruction.replace('products', 'product-variants')
					await apiAxios.post(`/shop/orders/${orderId}/items`, {
						productVariant: instruction.instruction, quantity: 1
					})
				}
				return apiAxios.post(`/shop/order-item-instructions`, {...instruction, orderItem })
			})).then(result => {
				return Promise.resolve({orderId})
			})
		})
	}
}

const deleteOrderItem = (orderId, item) => {
	if (item.quantity === 1) return apiAxios.delete(`/shop/orders/${orderId}/items/${item.id}`)
	return apiAxios.patch(
		`/shop/orders/${orderId}/items/${item.id}`,
		{ quantity: item.quantity - 1 },
		{ headers: {'Content-Type': 'application/merge-patch+json'} }
	)
}

export const useUpdateOrderItemVoice = (order) => {
	const queryClient = useQueryClient()
	const { mutateAsync: park } = usePatchOrderPark()
	const { mutateAsync: unpark } = usePatchOrderUnpark()
	const {mutateAsync: postOrderItem} = usePostOrderItem(order.code)
	return useMutation<any, unknown, any>(({voice, previousVoice, instructionId}) => updateVoice(voice, previousVoice, instructionId), {
		onMutate: async () => await unpark(order.code),
		onSettled: async () => {
			queryClient.invalidateQueries(['orders', order.code])
			return await park(order.code)
		}
	})

	function updateVoice(voice, previousVoice, instructionId) {
		const voiceVariant: string = voice ? voice.replace('products', 'product-variants') : ''
		const previousVoiceVariant: string = previousVoice ? previousVoice.replace('products', 'product-variants') : ''
		return apiAxios.put(`/shop/order-item-instructions/${instructionId}`, { instruction: voice }).then(() => {
			return postOrderItem({ productVariant: voiceVariant, quantity: 1 })
		}).then(result => {
			if (!previousVoiceVariant) return
			const filterResult = result.linkedItems.filter(item => item.variant === previousVoiceVariant)
			if (filterResult.length === 0) return
			return deleteOrderItem(order.code, filterResult[0])
		}).then(() => {
			queryClient.invalidateQueries(['orders', order.code])
			return {orderId: order.code, voices: [voiceVariant, previousVoiceVariant]}
		})
	}
}

export const useRemoveOrderItem = (product, order) => {
	const queryClient = useQueryClient()
	const { mutateAsync: park } = usePatchOrderPark()
	const { mutateAsync: unpark } = usePatchOrderUnpark()
	return useMutation<any, unknown, void>(() => deleteOrderItemInstructions(), {
		onMutate: async () => await unpark(order.code),
		onSettled: async () => {
			queryClient.invalidateQueries(['orders', order.code])
			return await park(order.code)
		}
	})

	function deleteOrderItemInstructions() {
		return Promise.all(product.instructions.map(instruction => {
			if (instruction.payload.type === 'voice' || instruction.payload.type === 'post_processing') {
				const variant = instruction.instruction.replace('products', 'product-variants')
				const item = order.linkedItems.filter(item => item.variant === variant)[0]
				if (item) deleteOrderItem(order.code, item)
			}
			return apiAxios.delete(`/shop/order-item-instructions/${instruction.id}`)
		}))
		.then(() => deleteOrderItem(order.code, product))
		.then(() => {
			queryClient.invalidateQueries(['orders', order.code])
			return Promise.resolve({orderId: order.code})
		})
	}
}
