import request from "services/Request"
import { serializeCustomer } from "types/Customer"
import { serializeStats } from "types/Stats"

// export function getCustomers() {
// 	return request({
// 		url: `/shop/customers`,
// 		method: 'GET',
// 	}, null)
// }

export function getCustomer(id: number) {
	return request({
		url: `/shop/customers/${id}`,
		method: 'GET'
	}, serializeCustomer)
}

export function getCustomerStats(id: number) {
	return request({
		url: `/shop/customers/${id}/stats`,
		method: 'GET'
	}, serializeStats)
}

export function postCustomer(data) {
	return request({
		url: `/shop/customers`,
		method: 'POST',
		data
	}, serializeCustomer)
}

export function putCustomer(id: number, data) {
	return request({
		url: `/shop/customers/${id}`,
		method: 'PUT',
		data
	}, serializeCustomer)
}

export function putCustomerPassword(id: number, data) {
	return request({
		url: `/shop/customers/${id}/password`,
		method: 'PUT',
		data
	}, serializeCustomer)
}

// export function patchCustomer(id: number, data) {
// 	return request({
// 		url: `/shop/customers/${id}`,
// 		method: 'PATCH',
// 		headers: {
// 			"Content-Type": "application/merge-patch+json"
// 		},
// 		data
// 	}, serializeCustomer)
// }

// export function deleteCustomer(id: number) {
// 	return request({
// 		url: `/shop/customers/${id}`,
// 		method: 'DELETE'
// 	}, serializeCustomer)
// }
