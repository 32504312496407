export interface Country {
	id: number
	idPath: string
	code: string
	name: string
}

export function serializeCountry(data): Country {
	return {
		id: data['@id'].replace('/api/v2/countries/'),
		idPath: data['@id'],
		code: data.code,
		name: data.name,
	}
}

export function serializeCountries(data: any): {items: Country[], totalItems: number } {
	return {
		items: data['hydra:member'].map(item => serializeCountry(item)),
		totalItems: data['hydra:totalItems']
	}
}
