import React, { InputHTMLAttributes } from 'react';
import { useState, useEffect } from 'react';
import { getEnv } from "common/EnvUtil";

export interface UploadProps extends InputHTMLAttributes<HTMLInputElement> {
	label?: string;
	description?: string;
	name?: string;
	optional?: boolean
	initialImgFileUrl: string
    initialMediaIsVideo?: boolean
}

const Upload: React.FC<UploadProps> = ({
	children,
	className,
	label,
	description,
	name,
	optional = false,
	initialImgFileUrl = '',
    initialMediaIsVideo = false,
	...restProps
}) => {
	const { REACT_APP_BASE_IMAGE_URL } = getEnv()
	const [mediaFileUrl, setMediaFileUrl] = useState<string | null>()
    const [mediaIsVideo, setMediaIsVideo] = useState<boolean | null>()

	useEffect(() => setMediaFileUrl(REACT_APP_BASE_IMAGE_URL + initialImgFileUrl), [initialImgFileUrl])
    useEffect(() => setMediaIsVideo(initialMediaIsVideo), [initialMediaIsVideo])

	const handleChange = (event) => {
		if (event.target.files[0]) {
			const media = event.target.files[0]
            setMediaIsVideo(media.type.split('/')[0] === "video")
			setMediaFileUrl(URL.createObjectURL(media))
		} else{
			setMediaFileUrl(null)
		}
	}

	return (
		<div>
			{label && (
				<label
					htmlFor={name}
					className="flex justify-between items-end text-sm font-medium text-gray-700"
				>
					<span>{label}</span>
					{optional && <span className="text-xs text-gray-400">(optional)</span>}
				</label>
			)}
			<div className="text-xs text-gray-500">
				{description}
			</div>
			<div className="flex gap-4 items-center px-3 py-2 mt-1 rounded-md border border-gray-300 shadow-sm appearance-none focus:outline-none focus:ring-cobalt-500 focus:border-cobalt-500">
				<div className="p-2 w-60 h-16 bg-gray-100 rounded-md border border-gray-500 border-dashed">
					{mediaFileUrl && mediaIsVideo && <video src={mediaFileUrl} autoPlay={true} muted={true} loop={true} className="object-contain w-full h-full"/>}
                    {mediaFileUrl && !mediaIsVideo && <img src={mediaFileUrl} alt='upload-preview' className="object-contain w-full h-full" />}
				</div>
				<div>
					<input
						id={name}
						name={name}
						type='file'
						onChange={handleChange}
						className={`${className} block w-full sm:text-sm appearance-none hidden`}
						{...restProps}
					/>
					<label htmlFor={name} className="cursor-pointer relative font-sans transition-colors duration-300 ease-in-out rounded-md leading-none bg-transparent border border-cobalt-500 text-cobalt-500 hover:bg-cobalt-500 hover:text-white px-8 py-2 text-sm font-bold antialiased min-w-fit whitespace-nowrap">
						Choose file
					</label>
				</div>
			</div>
		</div>
	)
}

export default Upload
