import React, {useEffect, useState} from "react";
import { useParams } from "react-router-dom"
import useNavigation from "hooks/useNavigation";
import MainPage from "components/elements/page/MainPage";
import Panel from "components/elements/Panel";
import PanelTitle from "components/elements/PanelTitle";
import SelectedProducts from "components/views/app/order/SelectedProducts";
import LoaderSpinning from "components/elements/LoaderSpinning";
import { useGetOrder } from "hooks/api/orders";
import { useGetProject } from "hooks/api/projects";
import { dateFromString, dateToStringWithTime} from "common/DateUtils";
import { Order as OrderType } from 'types/Order';

export default function Order(props: any) {
	const { current } = useNavigation()
	const { projectId, orderId } = useParams()
	const [order, setOrder] = useState<OrderType | null>(null)
	const { data, isLoading: loading} = useGetOrder(orderId);
	const { data: dataProject } = useGetProject(projectId ? projectId : data && data.projectId);

	useEffect(() => {
		if ( data ) setOrder(data)
	}, [data])

	const orderDetail = () => (data ? (
		<>
			<Panel>
				<PanelTitle>Order details</PanelTitle>
				<div className="-mx-8 mt-8 -mb-8 border-t border-gray-200">
					<dl className="sm:divide-y sm:divide-gray-200">
						<div className="px-8 py-5 sm:grid sm:grid-cols-3 sm:gap-4">
							<dt className="text-sm font-medium text-gray-500">Order id:</dt>
							<dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{order?.number ? order?.number : '-'}</dd>
						</div>
						<div className="px-8 py-5 sm:grid sm:grid-cols-3 sm:gap-4">
							<dt className="text-sm font-medium text-gray-500">Status</dt>
							<dd className="mt-1 text-sm text-gray-900 capitalize sm:mt-0 sm:col-span-2">{order?.state ? order?.state : '-'}</dd>
						</div>
						<div className="px-8 py-5 sm:grid sm:grid-cols-3 sm:gap-4">
							<dt className="text-sm font-medium text-gray-500">Numbers of products</dt>
							<dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{order?.items?.length ? order?.items?.length : '0'}</dd>
						</div>
						<div className="px-8 py-5 sm:grid sm:grid-cols-3 sm:gap-4">
							<dt className="text-sm font-medium text-gray-500">Created at</dt>
							<dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{order?.createdAt? dateToStringWithTime(dateFromString(order?.createdAt)) : '-'}</dd>
						</div>
						<div className="px-8 py-5 sm:grid sm:grid-cols-3 sm:gap-4">
							<dt className="text-sm font-medium text-gray-500">Updated at</dt>
							<dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{order?.updatedAt? dateToStringWithTime(dateFromString(order?.updatedAt)) :'-'}</dd>
						</div>
					</dl>
				</div>
			</Panel>

			<SelectedProducts order={order} voices={dataProject?.voices ? dataProject?.voices : []} setShowProductAdd={null} contextIsCart={false} isLoading={loading}></SelectedProducts>

			{order && order.remarks && (
				<Panel className="px-0">
					<div className="px-8 pb-4 border-b border-gray-200">
						<PanelTitle>Remarks</PanelTitle>
					</div>
					<div className="px-8 pt-5">
						<p className="mb-0">{order.remarks}</p>
					</div>
				</Panel>
			)}
		</>
	) : <></>)

	if(current?.path === '/orders/:orderId'){
		return (
			<MainPage isLoading={loading}>
				{loading && <LoaderSpinning className="p-8">Loading order...</LoaderSpinning> }
				{orderDetail()}
			</MainPage>
		)
	} else {
		return orderDetail()
	}
}