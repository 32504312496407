import { useState } from "react";
import { Link } from 'react-router-dom'
import useNavigation from "hooks/useNavigation";
import Alert from "components/elements/Alert";
import Button from "components/elements/Button";
import Input from "components/elements/form/Input";
import Panel from "components/elements/Panel";
import { useRequestPasswordReset } from "hooks/api/auth";


export default function ForgotPassword(props:any) {
	const {current} = useNavigation()

	const [credentials, setCredentials] = useState({ email: '', locale:'/api/v2/shop/locales/nl_NL' });

	const [showAlert, setShowAlert] = useState<boolean>(false)
	const { mutateAsync: postPasswordReset, isLoading } = useRequestPasswordReset();


	const onInputChangeCredentials = (e) => {
		setCredentials({ ...credentials, [e.target.name]: e.target.value })
	}

	const requestPasswordResetLink = (e) => {
		e.preventDefault()

		postPasswordReset(credentials)
		.then( resp => {
			setShowAlert(true)
		})
		.catch( error => {
			setShowAlert(true)
		})

		
	}

	return (
		<div className="mt-2">

			<div className="mb-4 text-center">
				<h1 className="text-2xl font-bold">{current ? current?.name: '' } </h1>
			</div>


			<div className="mb-6 text-center">	
				<p>
					Request a password recover link through your registered e-mail address.
				</p>
			</div>

			<Panel className="p-8" >

				<Alert show={showAlert} type="success">An password recover link will be send if your email address was in our systems.</Alert>
				
				{!showAlert && 
					<form onSubmit={requestPasswordResetLink} className="space-y-6">

						<Input label="Email address" name="email" type="email" placeholder="Type your email" autoComplete="email" onChange={onInputChangeCredentials} required />
									
						<div>
							<Button block={true} submit={true} loading={isLoading}>
								Send recover link
							</Button>
						</div>
						
					</form>
				}
				
				<div className="flex items-center justify-center mt-6">
					{/* <div></div> */}
					<div className="text-sm">
						<Link to="/login" className="link">Back to sign in</Link>
					</div>
				</div>
			</Panel>
		</div>
	)


}