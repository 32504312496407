import RouteBuilder from "components/elements/RouteBuilder";
import MainPage from "components/elements/page/MainPage";
import TabBar from "components/elements/TabBar";
import { Menu } from "Routes";
import {useCurrentUserRole} from "hooks/useCurrentUserRole";


export default function Settings(props:any) {

	const role = useCurrentUserRole();

	return (

			<MainPage>
				<TabBar routes={props.routes.filter((item) => (item.menu === Menu.MENU_SETTINGS && item.client) || (item.menu === Menu.MENU_SETTINGS && !item.client && role === 'reseller'))} />
				<RouteBuilder routes={props.routes} />
			</MainPage>

	)

}
