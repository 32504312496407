declare global {
    interface Window {
        env:any
    }
}

export const getEnv = () => {
    if (window.env) return window.env
    else throw Error("missing environment file 'env.js'")
}
