import request from "services/Request"

export function postThemeMedia(data) {
    return request({
        url: `/shop/theme-media`,
        method: 'POST',
        headers: {
            'content-type': 'multipart/form-data'
        },
        data
    }, () => {})
}

export function deleteThemeMedia(id: number) {
    return request({
        url: `/shop/theme-media/${id}`,
        method: 'DELETE'
    }, () => {})
}
