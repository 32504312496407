export interface Address {
	id?: number
	customer?: string
	firstName: string
	lastName: string
	phoneNumber?: string
	company?: string
	countryCode: string
	provinceCode?: string
	provinceName?: string
	street: string
	city: string
	postcode: string
	email?: string
	type: string

}

export interface AddressDTO {
	customer?: string
	countryCode: string
	street: string
	city: string
	postcode: string
	firstName: string
	lastName: string
	type: string
}

export const addressInit: Address =  {
	id: 0,
	firstName: '--',
	lastName: '--',
	countryCode: 'NL',
	street: '',
	city: '',
	postcode: '',
	phoneNumber:'',
	//provinceCode:'',
	//provinceName:'',
	email: '',
	type: 'company'
}

export const addressDTOInit: AddressDTO =  {
	countryCode: 'NL',
	street: '',
	city: '',
	postcode: '',
	firstName: '--',
	lastName: '--',
	type: 'company'
}

export function serializeAddress( data ): Address {
	if ( !data ) return addressInit;

	return {
		...addressInit,
		id: data.id ? data.id : data['@id'] ? data['@id'].replace( '/api/v2/shop/addresses/', '' ) : '',
		customer: data.customer,
		firstName: data.firstName,
		lastName: data.lastName,
		phoneNumber: data.phoneNumber,
		countryCode: data.countryCode,
		street: data.street ? data.street : '',
		city: data.city,
		postcode: data.postcode,
		email: data.email,
		type: data.type
	}
}