import request from "services/Request"
import { serializeProjects, serializeProject } from "types/Project"
import { serializeOrders } from "types/Order"

export function getProjects(params) {
	return request({
		url: `/shop/projects`,
		method: 'GET',
		params
	}, serializeProjects)
}

export function getProject(id: number) {
	return request({
		url: `/shop/projects/${id}`,
		method: 'GET'
	}, serializeProject)
}

export function getProjectByUrl(url: string) {
	return request({
		url: url.replace('/api/v2', ''),
		method: 'GET'
	}, serializeProject)
}

export function getProjectOrders(id: number, params) {
	return request({
		url: `/shop/projects/${id}/orders`,
		method: 'GET',
		params
	}, serializeOrders)
}

export function postProject(data) {
	return request({
		url: `/shop/projects`,
		method: 'POST',
		data
	}, serializeProject)
}

export function putProject(id: number, data) {
	return request({
		url: `/shop/projects/${id}`,
		method: 'PUT',
		data
	}, serializeProject)
}

// export function patchProject(id: string, data) {
// 	return request({
// 		url: `/shop/projects/${id}`,
// 		method: 'PATCH',
// 		headers: {
// 			"Content-Type": "application/merge-patch+json"
// 		},
// 		data
// 	}, serializeProject)
// }

// export function deleteProject(id: string) {
// 	return request({
// 		url: `/shop/projects/${id}`,
// 		method: 'DELETE'
// 	}, serializeProject)
// }
