const getLocalRefreshToken = () => {
	const user = getUser();
	return user?.refreshToken;
};

const getLocalAccessToken = () => {
	const user = getUser();
	return user?.token;
};

const getLocalCustomerId: () => number = () => {
	const user = getUser();

	const userPath = user?.customer

	return userPath ? parseInt(userPath.replace('/api/v2/shop/customers/', '')) : 0;
};

const getLocalCustomerIdPath = () => {
	const user = getUser();

	const userPath = user?.customer

	return userPath;
};

const updateLocalAccessToken = (user) => {
	setUser(user)
};

const getUser = () => {
	const user = localStorage.getItem("ftv-user");
	if (user){
		return JSON.parse(user)
	}

	return null
};

const setUser = (user) => {
	localStorage.setItem("ftv-user", JSON.stringify(user));
	//setRefreshTimeout()
};

const removeUser = () => {
	localStorage.removeItem("ftv-user");
};


const getRefreshTimeout = () => {
	const accessToken = getLocalAccessToken();
	if(!accessToken) return null

	const nowTimeStamp = Math.floor(new Date().getTime() / 1000);
	const jwt = parseJWT(getLocalAccessToken())
	const expiresIn = jwt.exp - nowTimeStamp;
	const refreshIn = (expiresIn * 1000) - 60000;

	console.log('expires in', refreshIn)
	return refreshIn
}



const parseJWT = (token) => {

	const base64Url = token.split('.')[1];
	const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
	const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
		return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
	}).join(''));

	
	return JSON.parse(jsonPayload);

}

const TokenUtil = {
	getLocalRefreshToken,
	getLocalAccessToken,
	getLocalCustomerId,
	getLocalCustomerIdPath,
	updateLocalAccessToken,
	getUser,
	getRefreshTimeout,
	setUser,
	removeUser,
};

export default TokenUtil;
