import { useMutation, useQuery } from 'react-query'
import { getTheme, postTheme, putTheme } from 'services/requests/themes'
import useQueryClient from '../useQueryClient'
import useToken from "../useToken";
import {useEffect, useState} from "react";
import {useGetCustomer} from "./customers";
import {themeInit} from "../../types/Theme";

export const useTheme = () => {
    const { getLocalCustomerId } = useToken()
    const [themeId, setThemeId] = useState<number>(0)

    const {data: dataCustomer, isLoading: loadingCustomer } = useGetCustomer(getLocalCustomerId())
    const {data: dataTheme, isLoading: loadingTheme } = useGetTheme(themeId)

    useEffect(() => {
        if (!dataCustomer) return
        setThemeId(dataCustomer.reseller ? dataCustomer.reseller.themeId : dataCustomer.theme ? dataCustomer.themeId : 0)
    }, [dataCustomer])

    if (!dataTheme) return {
        isLoading: loadingCustomer || loadingTheme,
        data: themeInit
    }
    return {
        isLoading: loadingCustomer || loadingTheme,
        data: dataTheme ? dataTheme : themeInit
    }
}

export const useGetTheme = (id: number) => {
    return useQuery(
        ['themes', id],
        () => getTheme(id),
        {enabled: !!id}
    )
}

export const usePostTheme = (customerId) => {
    const queryClient = useQueryClient()
    return useMutation<any, unknown, {feedbackIntroduction: string}>(theme => postTheme({ ...theme, customer: `/api/v2/shop/customers/${customerId}` }), {
        onSuccess: () => queryClient.invalidateQueries(['customers', customerId])
    })
}

export const usePutTheme = (themeId) => {
    const queryClient = useQueryClient()
    return useMutation<any, unknown, {feedbackIntroduction: string}>((theme, customThemeId = themeId) =>
        customThemeId ? putTheme(customThemeId, theme) : postTheme(theme), {
        onSuccess: () => {
            queryClient.invalidateQueries(['customers'])
            queryClient.invalidateQueries(['themes', themeId])
        },
    })
}
