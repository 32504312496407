import { apiAxios } from "services/Api"

const request = async function (options, serialize) {
	const onSuccess = function (response) {
		return serialize(response.data);
	};

	const onError = function (error) {
		console.error(error)
		return Promise.reject(error.response);
	};

	return apiAxios(options).then(onSuccess).catch(onError);
};
export default request;