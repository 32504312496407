import { useEffect, useState } from 'react';
import { Switch, useHistory } from 'react-router-dom';
import { ReactQueryDevtools } from 'react-query/devtools';
import { routes } from './Routes';
import EventBus from 'common/EventBus';
import useQueryClient from "hooks/useQueryClient";
import useToken from 'hooks/useToken';
import useNavigation from 'hooks/useNavigation';
import useTimeout from 'hooks/useTimeout';
import { useTokenRefresh } from 'hooks/api/auth';
import RouteBuilder from 'components/elements/RouteBuilder';
import Layout from 'components/layouts/Layout';
import { ToastContainer } from 'react-toastify';



export default function App() {
	const [refreshDelay, setRefreshDelay] = useState<number | null>()
	const history = useHistory()
	const {current} = useNavigation();
	const queryClient = useQueryClient()
	const {getUser,removeUser,updateLocalAccessToken, getLocalRefreshToken, getRefreshTimeout} = useToken()

	const { mutateAsync: postTokenRefresh } = useTokenRefresh();

	const refreshToken = () => {
		//do token refresh
		if(refreshDelay !== null && refreshDelay !== undefined) {
			setRefreshDelay(null)
			postTokenRefresh({refreshToken: getLocalRefreshToken()})
				.then(resp => {
					updateLocalAccessToken(resp);
					setTokenRefresh()
				})
				.catch(e => {
					handleLogout()
				})
		}
	}

	useTimeout(refreshToken, refreshDelay)

	useEffect(() => {
		//checks if user is still logged in, otherwise redirect to login page
		const user = getUser();
		if (!user && current && !current?.guest) {
			history.replace('/login')
		}
	}, [current])// eslint-disable-line react-hooks/exhaustive-deps


	useEffect(() => {
		setTokenRefresh()
		
		EventBus.on('logout', () => {
			handleLogout()
		})

		EventBus.on('login', () => {
			handleLogin()
		})

		return () => {
			EventBus.remove('logout', () => {
				handleLogout()
			})
			EventBus.remove('login', () => {
				handleLogin()
			})
		}
	}, [])// eslint-disable-line react-hooks/exhaustive-deps

	const handleLogout = () => {
		removeUser()
		queryClient.clear()
		setRefreshDelay(null)
		history.replace('/login')
	}

	const handleLogin = () => {
		setTokenRefresh()
	}

	const setTokenRefresh = () => {
		const refreshTimeout = getRefreshTimeout()
		setRefreshDelay(refreshTimeout)
	}

	return (
		<>
			<Layout>
				<Switch>
					<RouteBuilder routes={routes} data={null}/>
				</Switch>
			</Layout>

			{/*<ReactQueryDevtools initialIsOpen={false} />*/}

			<ToastContainer
				theme="colored"
				autoClose={5000}
				closeButton={false}
				bodyClassName={() => "text-sm flex p-3"}
				position="bottom-right"
			/>
		</>
	)
}