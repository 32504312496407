import React, { useState, useEffect } from 'react'
import Button from 'components/elements/Button'
import Panel from 'components/elements/Panel'
import PanelTitle from 'components/elements/PanelTitle'
import PanelFooter from 'components/elements/PanelFooter'
import LoaderSpinning from 'components/elements/LoaderSpinning'
import Empty from 'components/elements/Empty'
import ClientEdit from './ClientEdit'
import ContactCreate from '../contact/ContactCreate'
import ContactCard from '../contact/ContactCard'
import { Contact, contactInit } from 'types/Contact'
import { Client } from 'types/Client'

export default function ClientDetails(props: any) {
	const [clientData, setClientData] = useState<Client>()
	const [showEdit, setShowEdit] = useState(false)
	const [showAddContact, setShowAddContact] = useState(false)
	const [isLoading, setIsLoading] = useState(true)

	useEffect(() => {
		setClientData(props.data.clientData)

		if(props.data.clientData) {
			setIsLoading(false)
		}
	}, [props.data])

	const closeEdit = () => {
		setShowEdit( false )
	}

	return (
		<>
			<div className="flex flex-wrap -m-4">
				<div className="p-4 w-full xl:w-1/2">
					<Panel>
						<PanelTitle>Client details</PanelTitle>

						<div className="-mx-8 mt-8 border-t border-b border-gray-200">
							{isLoading ? <LoaderSpinning className="py-9">Loading client details...</LoaderSpinning> :
								<dl className="sm:divide-y sm:divide-gray-200">
									<div className="px-8 py-5 sm:grid sm:grid-cols-3 sm:gap-4">
										<dt className="text-sm text-gray-500">Name</dt>
										<dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">{clientData?.company ? clientData?.company : '-'}</dd>
									</div>
									<div className="px-8 py-5 sm:grid sm:grid-cols-3 sm:gap-4">
										<dt className="text-sm text-gray-500">Address</dt>
										<dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">
											{clientData?.defaultAddress?.street ? clientData?.defaultAddress?.street : '-'}<br/>
											{clientData?.defaultAddress?.postcode ? clientData?.defaultAddress?.postcode : '-'} {clientData?.defaultAddress?.city ? clientData?.defaultAddress?.city : '-'}<br/>
										</dd>
									</div>
									<div className="px-8 py-5 sm:grid sm:grid-cols-3 sm:gap-4">
										<dt className="text-sm text-gray-500">Chamber of Commerce number</dt>
										<dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">{clientData?.kvkNumber ? clientData?.kvkNumber : '-'}</dd>
									</div>
									<div className="px-8 py-5 sm:grid sm:grid-cols-3 sm:gap-4">
										<dt className="text-sm text-gray-500">Vat number</dt>
										<dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">{clientData?.vatNumber ? clientData?.vatNumber : '-'}</dd>
									</div>
								</dl>
							}
						</div>

						<PanelFooter className="mt-0">
							<div className="flex justify-end space-x-4 w-full">
								<Button type='outline' onClick={() => setShowEdit( true )}>Edit client</Button>
							</div>
						</PanelFooter>
					</Panel>
				</div>

				<div className="p-4 w-full xl:w-1/2">
					<Panel>
						<div className="flex justify-between items-center">
							<PanelTitle>Contacts</PanelTitle>
							<button onClick={() => setShowAddContact( true )} className="mb-4 text-sm text-cobalt-500 underline hover:no-underline hover:cursor-pointer">Add contact</button>
						</div>

						<div className="px-8 pt-8 pb-0 -mx-8 mt-4 border-t border-gray-200">
							{isLoading ? <LoaderSpinning className="p-8">Loading contacts...</LoaderSpinning>
								: (clientData && clientData.addresses && clientData.addresses.filter(item => item.type ==='contact').length > 0
									? <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
										{clientData && clientData.addresses.map((item, index) => {
											if(item.type === 'contact') {
												const contact: Contact = {...contactInit, ...item}
												return (
													<ContactCard key={item.email} contact={contact} onEdit={() => {}}/>
												)
											}
										})}
									</div>
									: <Empty className="p-8">Add contacts to this client</Empty>
								)
							}
						</div>
					</Panel>
				</div>
			</div>

			{clientData && <ClientEdit show={showEdit} client={clientData} onClose={() => closeEdit()} />}
			{clientData && <ContactCreate show={showAddContact} client={clientData} onUpdate={() => {}} onClose={() => setShowAddContact(false)} />}
		</>
	)
}
