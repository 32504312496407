import React, { Fragment, useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import { routes, Menu as MenuRoutes } from 'Routes'
import EventBus from 'common/EventBus'
import useStyling from 'hooks/useStyling'
import { useGetComments } from "hooks/api/comments";
import useMercureSubscriber from "hooks/useMercureSubscriber";
import useQueryClient from "hooks/useQueryClient";
import { Menu, Transition } from '@headlessui/react'
import { BellIcon, MenuAlt2Icon, UserCircleIcon } from '@heroicons/react/outline'
import {useCurrentUserRole} from "../../../hooks/useCurrentUserRole";

type props = {
	openSideBar: (open: boolean) => void;
}

export default function TopBar({openSideBar}: props) {
	const queryClient = useQueryClient()
	const mercureSubscriber = useMercureSubscriber()
	const [notificationCount, setNotificationCount] = useState<number>(0)
	const eventSourceRef = useRef<EventSource>();
	const notificationCountRef = useRef(0);
	const { data } = useGetComments({page: 1, itemsPerPage: 100})
	const role = useCurrentUserRole();


	useEffect(() => {
		eventSourceRef.current = mercureSubscriber()
		const eventSource = eventSourceRef.current
		if (eventSource) {
			// eventSource.onopen = event => console.log(event)
			eventSource.onerror = event => console.error(event)
			eventSource.onmessage = event => {
				setNotificationCount(notificationCountRef.current + 1)
			}
		}
	}, [])

	useEffect(() => {
		if (!data) return
		const count = data.items.reduce((cnt, item) => {
			return cnt + item.children.reduce((childCnt, childItem) =>
				childItem.payload && childItem.payload.read ? childCnt : childCnt + 1, 0)
		}, 0)
		setNotificationCount(count)
	}, [data])

	useEffect(() => {
		notificationCountRef.current = notificationCount
		queryClient.invalidateQueries({
			predicate: query =>  {
				return query.queryKey[0] === 'comments' && typeof query.queryKey[1] === 'object'
			}
		})
	}, [notificationCount])

	const settingsNavigation = routes.find( (item) =>{
		return item.path === '/settings'
	})

	const logOut = () =>{
		EventBus.dispatch("logout", null)
	}

	const { switchClassNames } = useStyling()

	return (
		<div className="flex relative z-10 flex-shrink-0 h-16 bg-white shadow">
			<button type="button" className="px-4 text-gray-500 border-r border-gray-200 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-cobalt-500 md:hidden" onClick={() => openSideBar(true)}>
				<span className="sr-only">Open sidebar</span>
				<MenuAlt2Icon className="w-6 h-6" aria-hidden="true" />
			</button>
			<div className="flex flex-1 justify-between px-8">
				<div className="flex flex-1" />
				<div className="flex items-center ml-4 md:ml-6">
					<Link to="/notifications" className="p-1 text-gray-400 bg-white rounded-full transition-colors duration-300 ease-in-out hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cobalt-500">
						<span className="sr-only">View notifications</span>
						<div className="relative">
							{!!notificationCount &&
								<span className="absolute px-1 min-w-5.5 bold rounded-full bg-red-600 -top-3 left-2.5 text-white text-xs inline-block text-center border-3 border-white">
									<span className="block scale-90 relative">{notificationCount > 99 ? '99' : notificationCount}</span>
								</span>}
							<BellIcon className="w-6 h-6" aria-hidden="true" />
						</div>
					</Link>

					{/* Profile dropdown */}
					<Menu as="div" className="relative ml-3">
						{({ open }) => (
							<>
								<div>
									<Menu.Button className="flex items-center max-w-xs text-sm text-gray-400 bg-white rounded-full transition-colors duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cobalt-500 hover:text-gray-500">
										<span className="sr-only">Open user menu</span>
										<UserCircleIcon className="w-6 h-6" aria-hidden="true" />
									</Menu.Button>
								</div>
								<Transition
									show={open}
									as={Fragment}
									enter="transition ease-out duration-100"
									enterFrom="transform opacity-0 scale-95"
									enterTo="transform opacity-100 scale-100"
									leave="transition ease-in duration-75"
									leaveFrom="transform opacity-100 scale-100"
									leaveTo="transform opacity-0 scale-95"
								>
									<Menu.Items static className="absolute right-0 py-1 mt-2 w-48 bg-white rounded-md divide-y divide-gray-100 ring-1 ring-black ring-opacity-5 shadow-lg origin-top-right div focus:outline-none">
										<div className="p-1">
											{settingsNavigation?.routes.filter((item) => (item.menu === MenuRoutes.MENU_SETTINGS && item.client) || (item.menu === MenuRoutes.MENU_SETTINGS && !item.client && role === 'reseller')).map((item) => (
												<Menu.Item key={item.name}>
													{({ active }) => (
														<Link to={item.path} className={switchClassNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}>
															{item.name}
														</Link>
													)}
												</Menu.Item>
											))}
										</div>
										<div className="p-1">
											<Menu.Item>
												{({ active }) => (
													<button
														onClick={logOut}
														className={switchClassNames(
															active ? 'bg-gray-100' : '',
															'block text-left w-full px-4 py-2 text-sm text-gray-700'
														)}
													>
														Sign out
													</button>
													)}
											</Menu.Item>
										</div>
									</Menu.Items>
								</Transition>
							</>
						)}
					</Menu>
				</div>
			</div>
		</div>
	)
}
