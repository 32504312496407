import React, { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import Button from "components/elements/Button";
import Input from "components/elements/form/Input";
import Panel from "components/elements/Panel";
import PanelFooter from "components/elements/PanelFooter";
import PanelTitle from "components/elements/PanelTitle";
import { useGetCustomer, usePutCustomer, usePutCustomerPassword } from "hooks/api/customers";
import useToken from "hooks/useToken";
import { Customer, customerInit } from "types/Customer";

export default function SettingsAccount(props: any) {
	const { t, i18n } = useTranslation();
	const initCredentials = { newPassword: '', confirmNewPassword: '', currentPassword: '' }
	const [credentials, setCredentials] = useState(initCredentials);
	const [customer, setCustomer] = useState<Customer>(customerInit)

	const {getLocalCustomerId} = useToken();
	
	const { data: dataCustomer } = useGetCustomer(getLocalCustomerId());
	const { mutateAsync: putCustomer, data: putCustomerData, isLoading: putCustomerLoading, error: putCustomerError} = usePutCustomer(getLocalCustomerId());
	const { mutateAsync: putCustomerPassword, data, isLoading: loading, error } = usePutCustomerPassword(getLocalCustomerId());

	const changePassword = (e) => {
		e.preventDefault()
		
		putCustomerPassword(credentials)
		.then(resp => {
			toast.success(t('settings.account.password.success'));
			setCredentials(initCredentials)
		})
		.catch(error => {
			toast.error(t('settings.account.password.error'));
		})
	}

	const changeCustomer = (e) => {
		e.preventDefault()

		putCustomer(customer)
			.then(resp => {
				toast.success(t('settings.account.profile.success'));
				//refetchCustomer()
				//setCredentials(initCredentials)
			})
			.catch(error => {
				toast.error(t('settings.account.profile.error'));
			})
	}

	const onInputChangeCustomer = (e) => {
		setCustomer({ ...customer, [e.target.name]: e.target.value })
	}

	const onInputChangeCredentials = (e) => {
		setCredentials({ ...credentials, [e.target.name]: e.target.value })
	}

	useEffect(()=> {
		if(dataCustomer) setCustomer(dataCustomer)
		
	}, [dataCustomer])
	
	return (
		<div className="flex flex-wrap -m-4">
			<div className="w-full p-4 md:w-1/2">
				<Panel>
					<PanelTitle>Profile</PanelTitle>

					<p className="mt-1 mb-4 text-sm text-gray-500">This is your profile and you can update it accordingly.</p>

					{dataCustomer && <form onSubmit={changeCustomer}>
						<div className="space-y-6">
							<Input label="First name" name="firstName" type="text" placeholder="Type your firstname" value={customer.firstName} onChange={onInputChangeCustomer} errors={putCustomerError?.response?.data?.violations} required />
							<Input label="Last name" name="lastName" type="text" placeholder="Type your lastname" value={customer.lastName} onChange={onInputChangeCustomer} errors={putCustomerError?.response?.data?.violations} required />
							<Input label="Email" name="email" type="email" disabled placeholder="Type your email" value={customer.email} onChange={onInputChangeCustomer} errors={putCustomerError?.response?.data?.violations} required />
						</div>
						<PanelFooter>
							<Button block={false} submit={true} loading={putCustomerLoading}>
								Save profile
							</Button>
						</PanelFooter>
					</form>
					}
				</Panel>
			</div>
			<div className="w-full p-4 md:w-1/2">
				<Panel>
					<PanelTitle>Change password</PanelTitle>
					<p className="mt-1 mb-4 text-sm text-gray-500">Change your password here by filling in your current password and a new one.</p>
					<form onSubmit={changePassword}>
						<div className="space-y-6">
							<Input className="hidden" name="username" type="text" autoComplete="off" />
							<Input label="Your current password" name="currentPassword" type="password" autoComplete="off" placeholder="Type your current password" value={credentials.currentPassword} onChange={onInputChangeCredentials} errors={error?.response?.data?.violations} required />
							<Input label="Your new password" name="newPassword" type="password" autoComplete="new-password" placeholder="Type your new password" value={credentials.newPassword} onChange={onInputChangeCredentials} errors={error?.response?.data?.violations} required />
							<Input label="Repeat your new password" name="confirmNewPassword" type="password" autoComplete="new-password" placeholder="Repeat your new password" value={credentials.confirmNewPassword} onChange={onInputChangeCredentials} errors={error?.response?.data?.violations} required />
						</div>

						<PanelFooter>
							<Button block={false} submit={true} loading={loading}>
								Save new password
							</Button>
						</PanelFooter>
					</form>
				</Panel>
			</div>
		</div>
	)

}