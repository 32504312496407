import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useGetProjects } from 'hooks/api/projects'
import Panel from 'components/elements/Panel'
import ProjectRow from '../project/ProjectRow'
import Empty from 'components/elements/Empty'
import Pagination from 'components/elements/Pagination'
import LoaderSpinning from 'components/elements/LoaderSpinning'
import { Client } from 'types/Client'

export default function ClientProjects(props: any) {
	const history = useHistory()
	const location = useLocation()
	const pageSize = 30;
	const [currentPage, setCurrentPage] = useState( 1 )
	const [totalItems, setTotalItems] = useState( 0 )
	const [clientData, setClientData] = useState<Client>()
	const { data: projectData, isLoading, isFetching } = useGetProjects({
		itemsPerPage: pageSize,
		page: currentPage,
		client: props.data.clientData ? props.data.clientData.id : 0
	})

	useEffect( () => {
		setClientData( props.data.clientData )
		if (props.data.clientData)
			setTotalItems( props.data.clientData.clientProjects.length )
	}, [props.data] )

	useEffect( () => {
		setCurrentPage(1)
	}, [location] )

	const openProject = ( id ) => {
		history.push( `/projects/${id}/overview` )
	}

	return (
		<>
			<Panel className="px-0 py-0">
				{!clientData || isLoading || isFetching  ? <LoaderSpinning className="p-8">Loading projects...</LoaderSpinning> :
				projectData && projectData.items.length > 0 ?
					<div className="flex flex-col">
						<div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
							<div className="inline-block min-w-full align-middle sm:px-6 lg:px-8">
								<div className="overflow-hidden">
									<table className="min-w-full divide-y divide-gray-200">
										<thead className="border-b border-gray-200">
										<tr>
											<th scope="col" className="px-6 pt-5 pb-4 text-xs font-normal tracking-wider text-left text-gray-500 uppercase">Project name</th>
											<th scope="col" className="px-6 pt-5 pb-4 text-xs font-normal tracking-wider text-left text-gray-500 uppercase">Client name</th>
											<th	scope="col" className="px-6 pt-5 pb-4 text-xs font-normal tracking-wider text-left text-gray-500 uppercase">Status</th>
											<th	scope="col" className="hidden px-6 pt-5 pb-4 text-xs font-normal tracking-wider text-left text-gray-500 uppercase md:table-cell">Created at</th>
											<th	scope="col" className="hidden px-6 pt-5 pb-4 text-xs font-normal tracking-wider text-left text-gray-500 uppercase md:table-cell">Updated at</th>
										</tr>
										</thead>
										<tbody className="space-y-4">
										{projectData.items.map((project, index) => {
											return (
												<ProjectRow key={project.id} index={index} project={project} onClick={openProject} />
											)
										})}
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
					: <Empty className="p-8">Client projects will appear here</Empty>
				}
			</Panel>
			<Pagination
				className="pagination-bar"
				currentPage={currentPage}
				totalCount={totalItems}
				pageSize={pageSize}
				onPageChange={page => setCurrentPage( page )}
			/>
		</>
	)
}