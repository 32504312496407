import { useMutation, useQuery } from 'react-query'
import { getProjectComments, getComments, getComment, postComment, putComment } from 'services/requests/comments'
import useQueryClient from 'hooks/useQueryClient'
import { CommentDTO } from 'types/Comment'


export function useGetProjectComments(projectId, params) {
    const defaultParams = {
        page: 1,
        itemsPerPage: 30
    }

    return useQuery(
        ['comments', projectId, {...defaultParams, ...params}],
        () => getProjectComments(projectId, {...defaultParams, ...params})
    )
}

export function useGetComments(params) {
    const defaultParams = {
        page: 1,
        itemsPerPage: 30
    }

    return useQuery(
        ['comments', {...defaultParams, ...params}],
        () => getComments({...defaultParams, ...params})
    )
}

export const useGetComment = (id: number, enabled: boolean = true) => {
    return useQuery(
        ['comment', id],
        () => getComment(id),
        { enabled: !!id && enabled }
    )
}

export function usePostComment() {
    return useMutation<any, unknown, CommentDTO>(comment => postComment(comment))
}

export function usePutComment() {
    const queryClient = useQueryClient()
    return useMutation<any, unknown, {id: number, comment: any}>(comment => putComment(comment.id, comment.comment), {
        onSuccess: data => queryClient.invalidateQueries({
            predicate: query =>  {
                return query.queryKey[0] === 'comments' && typeof query.queryKey[1] === 'object'
            }
        })
    })
}
