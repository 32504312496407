import useStyling from 'hooks/useStyling';
import React, { InputHTMLAttributes, forwardRef } from 'react';
import { useState, useEffect } from 'react';

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
	label?: string;
	name?: string;
	optional?: boolean;
	errors?: any[];
}

const Input = forwardRef<HTMLInputElement, InputProps>(
	(
		{
			type,
			className,
			label,
			name,
			optional = false,
			disabled = false,
			errors,
			...restProps
		},
		ref // Receive the ref from the parent component
	) => {
		const [error, setError] = useState<any>();
		const { switchClassNames } = useStyling();

		useEffect(() => {
			if (errors) setError(errors.find((x) => x?.propertyPath === name));
			else setError(null);
		}, [errors, name]);

		return (
			<div>
				{label && (
					<label
						htmlFor={name}
						className={switchClassNames(
							error ? 'text-gray-700' : 'text-gray-700',
							'flex items-end justify-between text-sm font-medium'
						)}
					>
						<span>{label}</span>
						{optional && <span className="text-xs text-gray-400">(optional)</span>}
					</label>
				)}
				<div className="relative mt-1">
					<input
						id={name}
						name={name}
						type={type}
						disabled={disabled}
						className={switchClassNames(
							error ? 'border-red-700' : 'border-gray-300',
							disabled ? 'bg-gray-100 text-gray-500' : 'bg-white',
							`${className} block w-full px-3 py-2 placeholder-gray-400 border rounded-md shadow-sm appearance-none focus:outline-none focus:ring-cobalt-500 focus:border-cobalt-500 sm:text-sm`
						)}
						ref={ref} // Forward the ref to the input element
						{...restProps}
					/>
					{error && <span className="pt-1 text-xs text-red-700">{error?.message}</span>}
				</div>
			</div>
		);
	}
);

export default Input;
