import { useMemo } from "react";
import { useQueries, useMutation } from 'react-query'
import { patchOrderPark, patchOrderUnpark } from 'services/requests/orders'

export function useGetResourcesByUrlArray<Type>( getByUrl, urls: string[] ) : {data: Type[], isLoading: boolean, isFetching: boolean}{
	if (!urls) urls = []

	const queries = useQueries(urls.map(url => {
		return {
			queryKey: ["byUrl", url],
			queryFn: () => getByUrl(url)
		}
	}))

	// create useMemo dependencyKey based on data from queries to prevent infinite rerendering
	const dependencyKey = queries.reduce((start, query) => start + JSON.stringify(query.data), '')

	return useMemo(() => {
		if (queries.find(query => query.data === undefined)) return { data: [], isLoading: true, isFetching: true }
		const data = queries.map(query => query.data)
		const isLoading = queries.reduce((loading, query) => query.isLoading ? true : loading, false)
		const isFetching = queries.reduce((fetching, query) => query.isFetching ? true : fetching, false)
		return { data, isLoading, isFetching }
	}, [dependencyKey])
}

export function usePatchOrderPark() {
	 return useMutation((id: string) => patchOrderPark(id,{ notes: '' }));
}

export function usePatchOrderUnpark() {
	return useMutation((id: string) => patchOrderUnpark(id, { notes: '' }))
}

