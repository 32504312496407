import { EventSourcePolyfill } from "event-source-polyfill"
import useToken from "./useToken"
import { getEnv } from "common/EnvUtil";

const EventSource =  EventSourcePolyfill;
const { REACT_APP_BASE_API_MERCURE_URL: MERCURE_URL } = getEnv()

const useMercureSubscriber = () => {
    const { getLocalAccessToken } = useToken()

    return () => {
        if (!MERCURE_URL) return false
        const url = new URL(MERCURE_URL);
        url.searchParams.append('topic', '*');
        return new EventSource(url, {
            headers: {
                'Authorization': `Bearer ${getLocalAccessToken()}`
            }
        });
    }
}

export default useMercureSubscriber
