import {CheckIcon} from "@heroicons/react/outline"

type StepProps = {
    steps: { name: string, href: string, id: string }[]
    currentIndex?: number,
    onChange?: (value: number) => void
};

export default function Steps({
                                  steps,
                                  currentIndex = 0,
                                  onChange = undefined
                              }: StepProps) {

    const handleLinkClick = (e) => {
        e.preventDefault()
        if (onChange) {
            onChange(+e.currentTarget.dataset.index)
        }
    }

    return (
        <nav className="progress-list" aria-label="Progress">
            <ol className="space-y-6 md:flex lg:space-y-0 md:space-x-4">
                {steps.map((step, index) => (
                    <li key={step.name} className="md:flex-1">
                        {index < currentIndex ? (
                            <button
                                onClick={handleLinkClick}
                                data-index={index}
                                className="flex flex-col items-center lg:items-start w-full group"
                            >
                                <div className="relative flex flex-col lg:flex-row w-full justify-start items-center">
                                    <div
                                        className="w-10 h-10 flex rounded-full border-2 border-green-500 bg-green-500 text-white text-sm font-bold leading-[36px] text-center">
                                        <CheckIcon className="w-6 m-auto"/>
                                    </div>
                                    <span
                                        className="h-10 w-px lg:w-full lg:h-px mt-2 lg:mt-0 lg:ml-4 lg:flex-1 bg-green-500"/>
                                </div>
                                <span
                                    className="text-xs font-semibold tracking-wide text-gray-600 uppercase mt-2">{step.name}</span>
                            </button>
                        ) : index === currentIndex ? (
                            <button
                                onClick={handleLinkClick}
                                data-index={index}
                                aria-current="step"
                                className="flex flex-col items-center lg:items-start w-full group"
                            >
                                <div className="relative flex flex-col lg:flex-row w-full justify-start items-center">
                                    <div
                                        className="w-10 h-10 rounded-full border-2 border-cobalt-500 text-cobalt-500 text-sm font-bold leading-[36px]">{index + 1}</div>
                                    <span
                                        className="h-10 w-px lg:w-full lg:h-px mt-2 lg:mt-0 lg:ml-4 lg:flex-1 bg-cobalt-500/10"/>
                                </div>
                                <span
                                    className="text-xs font-semibold tracking-wide text-cobalt-500 uppercase mt-2">{step.name}</span>
                            </button>
                        ) : (
                            <button
                                onClick={handleLinkClick}
                                data-index={index}
                                aria-current="step"
                                className="flex flex-col items-center lg:items-start w-full group"
                            >
                                <div className="relative flex flex-col lg:flex-row w-full justify-start items-center">
                                    <div
                                        className="w-10 h-10 rounded-full border-2 border-gray-400 text-gray-400 text-sm font-bold leading-[36px]">{index + 1}</div>
                                    <span
                                        className="h-10 w-px lg:w-full lg:h-px mt-2 lg:mt-0 lg:ml-4 lg:flex-1 bg-cobalt-500/10"/>
                                </div>
                                <span
                                    className="text-xs font-semibold tracking-wide text-gray-400 uppercase mt-2">{step.name}</span>
                            </button>
                        )}
                    </li>
                ))}
            </ol>
        </nav>
    )
}