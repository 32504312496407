import React, { InputHTMLAttributes } from 'react';

export interface TextareaProps extends InputHTMLAttributes<HTMLTextAreaElement> {
	label?: string;
	name?: string;
	rows?: number;
	optional?: boolean
}

const Textarea: React.FC<TextareaProps> = ({
	children,
	type,
	className,
	label,
	name,
	rows = 2,
	optional = false,
	...restProps
}) => {
	return (
		<div>
			{label && (
				<label
					htmlFor={name}
					className="flex items-end justify-between text-sm font-medium text-gray-700"
				>
					<span>{label}</span>
					{optional && <span className="text-xs text-gray-400">(optional)</span>}
				</label>
			)}
			<div className="mt-1">
			<textarea
				id={name}
				name={name}
				rows={rows}
				className={`${className} block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-cobalt-500 focus:border-cobalt-500 sm:text-sm`}
				{...restProps}
			/>
			</div>
		</div>
	)
}

export default Textarea
