import React, { useState, useEffect } from 'react'
import { useGetVoicesByUrlArray } from 'hooks/api/voices'
import { setActiveVoice } from 'hooks/useActiveVoice'
import Voice from './Voice'
import { CollectionIcon } from '@heroicons/react/outline'
import VoicesSelect from 'components/views/app/project/VoicesSelect'
import { Voice as VoiceElement } from 'types/Voice'

const VoicesGrid: React.FC<
{
	grid?: 'md' | 'default'
	voices: string[]
	openSelect?: boolean
	onCloseSelect?: ( ) => void
	onUpdate?: ( items: string[] ) => void
}
> = ({ grid, voices, openSelect, onCloseSelect, onUpdate}) => {
	const [allowUpdates, setAllowUpdates] = useState<boolean>( false )
	const [showSelectVoices, setShowSelectVoices] = useState<boolean>( false )
	const [selectedVoices, setSelectedVoices] = useState<VoiceElement[]>([])
	const { data } = useGetVoicesByUrlArray(voices)

	const gridStyle = {
		md: 'grid-cols-3',
		default: 'grid-cols-3 lg:grid-cols-4'
	};

	useEffect( () => {
		if(data !== null){
			setSelectedVoices(data ? data : [])
			setAllowUpdates(false)
		}
	}, [data] )

	useEffect( () => setShowSelectVoices(!!openSelect), [openSelect])

	useEffect( () => {
		const items: string[] = selectedVoices.map(item =>{
			return item.idPath
		})
		if(allowUpdates){
			if(onUpdate) onUpdate(items)
		}
	}, [selectedVoices])

	const closeSelectVoices = ( voices ) => {
		setAllowUpdates( true )
		if ( voices ) {
			setSelectedVoices( voices )
		}
		setShowSelectVoices( false )
		if(onCloseSelect) onCloseSelect()
	}

	return (
		<div>
			<div className="text-gray-600 body-font">
				{selectedVoices.length > 0 ?
				<div className={`grid gap-4 ${gridStyle[grid? grid : 'default']} `}>
					{selectedVoices.map( ( item, index ) => (
						<div className="h-auto aspect-w-10 aspect-h-10" key={item.id}>
							<Voice voice={item} onClick={() => setActiveVoice( item )} />
						</div>
					) )}
				</div>

				:
				<div>
					<div className="mb-6">
						<CollectionIcon className="w-16 h-16 mx-auto text-gray-200" />
					</div>
					<p className="max-w-lg mx-auto text-center">
						Select voices for this project
					</p>
				</div>
				}
			</div>


			<VoicesSelect show={showSelectVoices} voices={selectedVoices} onClose={closeSelectVoices} />
		</div>
	)
}

export default VoicesGrid
