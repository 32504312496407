import { useMemo, useState } from "react"
import { useParams, useHistory } from "react-router-dom"
import { Menu } from "Routes"
import { useDeleteClient, useGetClient } from "hooks/api/clients"
import MainPage from "components/elements/page/MainPage"
import TabBar from "components/elements/TabBar"
import ConfirmationDialog from "components/elements/ConfirmationDialog"
import RouteBuilder from "components/elements/RouteBuilder"
import Button from "components/elements/Button";
import { toast } from "react-toastify";

export default function Client(props: any) {

	const history = useHistory()
	const {clientId} = useParams()
	const { data } = useGetClient(clientId);
	const { mutateAsync: deleteClient } = useDeleteClient(clientId);

	const [showConfirmDialog, setShowConfirmDialog] = useState(false)

	const deleteClientHandler = () => {
		setShowConfirmDialog( false )
		if (data.numberOfContacts > 0 || data.clientProjects.length > 0) {
			toast.error("Client cannot be deleted because the client has still projects and/or contacts attached.")
		}
		deleteClient().then(resp => {
			toast.success("Client successfully deleted.")
			history.push('/clients')
		}).catch(err => {
			toast.error("Something went wrong while deleting client.")
		})
		history.push('/clients')
	}

	const actions = useMemo( () => {
		return (
			<>
				 <Button type="danger" onClick={() => setShowConfirmDialog(true)}>Delete client</Button>
			</>
		)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [] )


	return (
		<MainPage title={data?data.company:''} headerActions={actions} >
			<TabBar routes={props.routes.filter((item) => item.menu === Menu.MENU_CLIENT)} data={data}/>

			<RouteBuilder routes={props.routes} data={{clientData:data}}  />

			<ConfirmationDialog isShow={showConfirmDialog} title="Are you sure?" confirmText="Yes, delete" type="danger" onClose={() => { setShowConfirmDialog( false ) }} onConfirm={() => { deleteClientHandler()}}>
				<p className="text-sm text-gray-500">
					Are you sure you want to delete this client?
				</p>
			</ConfirmationDialog>
		</MainPage>
	)

}
