import TokenUtil from "common/TokenUtil"

const useToken = (): {
	
	getLocalRefreshToken: () => string,
	getLocalAccessToken: () => string,
	getLocalCustomerId: () => number,
	getLocalCustomerIdPath: () => string,
	updateLocalAccessToken: (user: any) => void,
	getUser: () => any | null,
	setUser: (user: any) => void,
	getRefreshTimeout: () => number | null,
	removeUser: () => void,
	
} => {
	const getLocalRefreshToken = () => {
		return TokenUtil.getLocalRefreshToken()
	};

	const getLocalAccessToken = () => {
		return TokenUtil.getLocalAccessToken()
	};

	const getLocalCustomerId = () => {
		return TokenUtil.getLocalCustomerId()
	};
	const getLocalCustomerIdPath = () => {
		return TokenUtil.getLocalCustomerIdPath()
	};

	const updateLocalAccessToken = (user) => {
		TokenUtil.setUser(user)
	};

	const getUser = () => {
		return TokenUtil.getUser()
	};

	
	const setUser = (user:any) => {
		TokenUtil.setUser(user)
	};

	const getRefreshTimeout = () => {
		return TokenUtil.getRefreshTimeout()
	};


	const removeUser = () => {
		TokenUtil.removeUser()
	};

	return { 
		getLocalRefreshToken,
		getLocalAccessToken,
		getLocalCustomerId,
		getLocalCustomerIdPath,
		updateLocalAccessToken,
		getUser,
		setUser,
		getRefreshTimeout,
		removeUser 
	}
}

export default useToken
