import React, { useEffect, useState } from "react";
import Button from "components/elements/Button";
import Input from "components/elements/form/Input";
import Textarea from "components/elements/form/Textarea";
import Stack from "components/elements/stack/Stack";
import Select from "components/elements/form/Select";
import { XIcon } from "@heroicons/react/outline";
import { useGetProducts, useGetProductVariantsByUrlArray } from "hooks/api/products";
import { Product } from "types/Product";
import { ProductVariant } from "types/ProductVariant";
import { OrderItemDTO, orderItemDTOInit, instructionTypes } from "types/OrderItem"
import {getEnv} from "common/EnvUtil";


export interface ProductType {
	id: boolean
	type: string,
	price: number
}

export interface ProductAddProps {
	show: boolean
	onClose: (item:any) => void
}

const ProductAdd: React.FC<ProductAddProps> = ({
	show,
	onClose
}) => {
	const {REACT_APP_POST_PROCESSING_HELP_URL} = getEnv()
	const [showAdd, setShowAdd] = useState<boolean>(false)
	const [saving, setSaving] = useState<boolean>(false)

	const {data} = useGetProducts({})
	const [products, setProducts] = useState<Product[]>([])
	const [selectedProduct, setSelectedProduct] = useState<string>('')

	const [variantsSelectedProduct, setVariantsSelectedProduct] = useState<string[]>([])
	const {data: dataVariantsSelectedProduct} = useGetProductVariantsByUrlArray(variantsSelectedProduct)
	const [selectedVariant, setSelectedVariant] = useState<string>('')

	const [postProcessingOptions, setPostProcessingOptions] = useState<Product[]>([])
	const [selectedPostProcessingOptions, setSelectedPostProcessingOptions] = useState<string[]>([])
	const {data: dataPostProcessingOptionVariants} = useGetProductVariantsByUrlArray(postProcessingOptions.map(item => item.variants[0]))

	const [newProduct, setNewProduct] = useState<OrderItemDTO>(orderItemDTOInit)
	const [price, setPrice] = useState<number>(0)

	useEffect(() => {
		setShowAdd(show)
	}, [show])

	useEffect( () => {
		if(!data) return
		setProducts( data.items.filter( item => item.type === 'product'))
		setPostProcessingOptions(data.items.filter( item => item.type === 'option_post_processing'))
	}, [data] )

	useEffect(() => {
		if (!selectedProduct) return
		const productName: string = JSON.parse(selectedProduct).name
		const productVariants: string[] = JSON.parse(selectedProduct).variants
		if (productVariants) setVariantsSelectedProduct(productVariants)
		setNewProduct({ ...newProduct, productName})
		setSelectedVariant(productVariants[0])
	}, [selectedProduct])

	const updatePrice = () => {
		let price = 0
		const variant: ProductVariant | undefined = dataVariantsSelectedProduct?.filter(item => item.idPath === selectedVariant)[0]
		if (variant) price += variant.price
		price = selectedPostProcessingOptions.reduce((total, item) => {
			if (!dataPostProcessingOptionVariants) return price
			const variant: ProductVariant | undefined = dataPostProcessingOptionVariants
				.filter(variant => JSON.parse(item).variants[0] === variant.idPath)[0]
			if (!variant) return price
			return total + variant.price
		}, price)
		setPrice(price)
	}

	useEffect(() => {
		const variant: ProductVariant | undefined = dataVariantsSelectedProduct?.filter(item => item.idPath === selectedVariant)[0]
		if (variant) {
			setNewProduct({ ...newProduct, productVariant: selectedVariant, quantity: 1 , total: variant ? variant.price : 0 })
			updatePrice()
		}
	}, [selectedVariant, dataVariantsSelectedProduct])

	useEffect(updatePrice, [selectedPostProcessingOptions])

	const onInputChangeProduct = (e) => {
		if (instructionTypes.includes(e.target.name)) {
			newProduct.instructions = newProduct.instructions.filter(item => {
				return item.type !== e.target.name
			})
			setNewProduct({ ...newProduct, instructions: [
					...newProduct.instructions,
					{ type: e.target.name, instruction: e.target.value }
				]})
		} else {
			setNewProduct({ ...newProduct, [e.target.name]: e.target.value })
		}

	}

	const onInputChangeVariant = (e) => {
		setSelectedVariant(e.target.value)
	}

	const onInputChangePostProcessing = (e) => {
		const result = selectedPostProcessingOptions.filter(item => e.target.value === item)
		if (result.length !== 0) return
		setSelectedPostProcessingOptions([...selectedPostProcessingOptions, e.target.value])
	}

	const onDeletePostProcessing = (id) => {
		const options = [...selectedPostProcessingOptions]
		const index = selectedPostProcessingOptions.findIndex(item => JSON.parse(item).id === id)
		options.splice(index, 1)
		setSelectedPostProcessingOptions(options)
	}

	const mapPostProcessingOptionsToInstructions = () => {
		return selectedPostProcessingOptions.map(item => {
			return {type: 'instruction', payload: {type: 'post_processing'}, instruction: JSON.parse(item).idPath}
		})
	}

	const closeProductAdd = () => {
		setShowAdd(false)
		resetData()
		onClose(null)
	}


	const saveProduct = async (e) => {
		e.preventDefault()
		setSaving(true)
		const instructions = mapPostProcessingOptionsToInstructions()
		await onClose({...newProduct, instructions: [...newProduct.instructions, ...instructions] })
		setSaving(false)
		setShowAdd(false)
		resetData()
	}

	const resetData = () =>{
		setNewProduct(orderItemDTOInit)
		setSelectedVariant('')
		setSelectedPostProcessingOptions([])
		setSelectedProduct('')
		setSaving(false)
	}

	return (
		<>
			<Stack isShow={showAdd} onClose={() => closeProductAdd()} title="Add a product">
				<div className="px-8 mt-12">
					<h3 className="text-xl font-medium">Add a product to your project</h3>
					<p className="text-sm">Configure the details of the selected product. Details can be changed at a later time if necessary</p>
				</div>
				<form onSubmit={saveProduct}>
					<div className="px-8 mt-4 space-y-6 divide-y divide-gray-200">
						<div className="space-y-6">
							<Select label="Select product type" name="type" className="w-full" value={selectedProduct?selectedProduct:''}  placeholder="Type the project name" onChange={(e)=>setSelectedProduct(e.target.value)} required>
								<option value="" disabled={true}>Select a product type</option>
								{products && products.map((item) => {
									return ( <option key={item?.id} value={JSON.stringify(item)} >{item.name}</option>)
								})}
							</Select>
						</div>

						{ selectedProduct && JSON.parse(selectedProduct).name !== "" && <div className="py-6 space-y-6" >

							<h4>{JSON.parse(selectedProduct).name }</h4>

							<Textarea label="Script" name="script" placeholder="Type the script" onChange={onInputChangeProduct} optional={true}></Textarea>

							<Select label="Length" name="variant" className="w-full divide-y divide-gray-200" value={selectedVariant? selectedVariant:''}  placeholder="Select length" onChange={onInputChangeVariant} required>
								<option value="" disabled={true}>Select length</option>
								{dataVariantsSelectedProduct && dataVariantsSelectedProduct.map((item) => {
									return ( <option key={item?.id} value={item?.idPath} >{item?.name}</option>)
								})}
							</Select>

                            <hr className=""></hr>

							<div>
								<Select label="Post-processing" name="post-processing" className="w-full mb-2" value={""} placeholder="Select option" helpLink={REACT_APP_POST_PROCESSING_HELP_URL} helpLinkText="help" onChange={onInputChangePostProcessing}>
									<option value="" disabled={true}>Select option(s)</option>
									{postProcessingOptions && postProcessingOptions.map((item) => {
										return ( <option key={item?.id} value={JSON.stringify(item)} >{item?.name}</option>)
									})}
								</Select>


								{selectedPostProcessingOptions && selectedPostProcessingOptions.map(item => JSON.parse(item)).map(item =>
									(<div key={item.name} className="block w-full my-1 px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm appearance-none sm:text-sm">
										{item.name}
										<button className="float-right" onClick={e => { e.preventDefault(); onDeletePostProcessing(item.id) }} >
											<XIcon className='w-5 h-5 transition-colors duration-200 text-gray hover:text-gray-400'/>
										</button>
									</div>)
								)}
							</div>

							<Textarea
								label="Remarks, due date and briefing"
								name="comment"
								placeholder="Please enter your remarks, due date, and project brief in this field."
								rows={4}
								onChange={onInputChangeProduct}
								optional={true}>
							</Textarea>

							<Input label="Reference 'tone of voice' (URL YouTube / Vimeo)" name="reference" type="text" placeholder="Reference URL" onChange={onInputChangeProduct} optional={true} />

							<div className="p-4 rounded-lg bg-gray-200">
								Based on the filled in settings the amount for this project would result in the following:
								<h3 className="mb-4 mt-4 text-lg">{new Intl.NumberFormat( 'nl-NL', { style: 'currency', currency: 'EUR' } ).format( price / 100)}</h3>
								<ul className="pl-4 list-disc text-xs">
									<li>The price does not include tax</li>
									<li>There could be additional charges when choosing a voice</li>
								</ul>
							</div>

						</div>
						}
					</div>

					<div className="flex px-8 my-12 space-x-4">
						<Button type="primary" submit={true} loading={saving}>Add Product</Button>
						<Button type="grayoutline" onClick={() => closeProductAdd()}>Cancel</Button>
					</div>
				</form>
			</Stack>
		</>
	)
}

export default ProductAdd
