import { useState, useEffect } from 'react'
import { useMutation } from 'react-query'
import useQueryClient from '../useQueryClient'
import useToken from '../useToken'
import { postThemeColor, deleteThemeColor } from 'services/requests/themes-color'
import { useGetCustomer } from './customers'
import { useGetTheme } from './themes'
import { themeColorInit } from "types/ThemesColor";

export const useThemeColor = (themeIdOverride = 0) => {
    const { getLocalCustomerId } = useToken()
    const [themeId, setThemeId] = useState<number>(themeIdOverride ?? 0)

    const {data: dataCustomer, isLoading: loadingCustomer } = useGetCustomer(getLocalCustomerId())
    const {data: dataTheme, isLoading: loadingTheme } = useGetTheme(themeId)

    useEffect(() => {
        if (themeIdOverride || !dataCustomer) return
        setThemeId(dataCustomer.reseller ? dataCustomer.reseller.themeId : dataCustomer.theme ? dataCustomer.themeId : 0)
    }, [dataCustomer])

    return (types) => {
        if (!dataTheme) return {
            isLoading: loadingCustomer || loadingTheme,
            data: themeColorInit
        }
        const img = dataTheme.colors.sort((a, b) => b.id - a.id).find(color => types.includes(color.type))
        return {
            isLoading: loadingCustomer || loadingTheme,
            data: img ? img : themeColorInit
        }
    }
}

export const usePostThemeColor = (themeId) => {
    const queryClient = useQueryClient()
    return useMutation<any, unknown, any>(themeColor => postThemeColor(themeColor), {
        onSuccess: () => queryClient.invalidateQueries(['themes', themeId])
    })
}

export const useDeleteThemeColor = () => {
    return useMutation<any, unknown, number>(imageId => deleteThemeColor(imageId))
}
