export interface ProductVariant {
	id?: number
	idPath?: string
	name: string
	price: number
}

export const productVariantInit = {
	name: '',
	price: 0
}

export function serializeProductVariant(data: any): ProductVariant {
	if ( !data ) return productVariantInit

	return {
		id: data.id ? data.id : 0,
		idPath: data['@id'],
		name: data.name,
		price: data.price
	}
}

export function serializeProductVariants(data: any): {items: ProductVariant[], totalItems: number } {
	return {
		items: data['hydra:member'].map(item => serializeProductVariant(item)),
		totalItems: data['hydra:totalItems']
	}
}