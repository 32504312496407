export interface Taxon {
    id: number
    idPath: string
    name: string
    code: string
    children: Taxon[]
}

export function serializeTaxon(data): Taxon {
    return {
        id: data.id,
        idPath: data['@id'],
        name: data.name,
        code: data.code,
        children: data.children.map(item => serializeTaxon(item))
    }
}
