import { useEffect, useState } from "react";
import { useQuery, useMutation } from 'react-query'
import { getCustomer, getCustomerStats, postCustomer, putCustomer, putCustomerPassword } from 'services/requests/customers'
import useQueryClient from 'hooks/useQueryClient'
import {Customer, CustomerDTO, customerDTOInit} from 'types/Customer'


export const useGetCustomer = (id: number) => {
	return useQuery(
		['customers', id],
		() => getCustomer(id),
		{enabled: !!id}
	)
}

export const useGetCustomerByUrl = (url: string) => {
    const id = parseInt(url.replace('/api/v2/shop/customers/', ''))
    return useQuery(
        ['customers', id],
        () => getCustomer(id),
        {enabled: !!id}
    )
}

export const useGetStats = (id: number) => {
	return useQuery(
		['stats', id],
		() => getCustomerStats(id),
		{enabled: !!id}
	)
}

export const usePostCustomer = () => {
	return useMutation<any, any, Customer>((customer) => postCustomer(customer))
}

export const usePutCustomer = (id: number) => {
	const queryClient = useQueryClient()
	return useMutation<any, any, CustomerDTO>((customer) => putCustomer(id, customer), {
		onSuccess: data => queryClient.invalidateQueries(['customers', id])
	})
}

export const usePutCustomerPassword = (id: number) => {
	interface Credentials {
		newPassword: string
		confirmNewPassword: string
		currentPassword: string
	}

	return useMutation<any, any, Credentials>((credentials) => putCustomerPassword(id, credentials))
}

export const useCustomerDTO = ( data: Customer ) => {
	const [customerDTO, setCustomerDTO] = useState<CustomerDTO>(customerDTOInit)

	useEffect(()=>{
		const generateDTO = (data: Customer) =>{
			setCustomerDTO({
				company: data.company,
				kvkNumber: data.kvkNumber,
				vatNumber: data.vatNumber
			})
		}

		if ( data ) {
			generateDTO(data);
		}
	},[data])

	return {customerDTO}
}
