import { QueryClient } from "react-query"

export const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			retry: false,
			refetchOnWindowFocus: false,
			refetchOnMount: true,
			keepPreviousData: true,
			staleTime: 15 * 60 * 1000,
			cacheTime: 30 * 60 * 1000
		}
	}
})