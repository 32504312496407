import { OrderItem, orderItemInit, serializeOrderItem } from 'types/OrderItem'

export interface Order {
	id?: number
	idPath: string
	code: string
	number: string
	state: string
	items?: OrderItem[]
	linkedItems: {
		quantity: number
		id: number
		variant: string
	}[]
	projectId: number
	payment?: {id: number, idPath: string, method: string}
	clientName: string
	projectName: string
	remarks?: string
	subtotal: number
	total: number
	tax: number
	createdAt?: string
	updatedAt?: string
}

export const orderInit: Order =  {
	id: 0,
	idPath: '',
	code: '',
	state: 'cart',
	number: 'XXXXXXXXX',
	items: [],
	linkedItems: [],
	projectId: 0,
	payment: {id: 0, idPath: '', method: ''},
	clientName: '',
	projectName: '',
	remarks: '',
	subtotal: 0,
	total: 0,
	tax: 0,
	createdAt: '',
	updatedAt: ''
}

export function serializeOrder(data: any): Order {
	return {
		id: data.id ? data.id : 0,
		idPath: data['@id'] ? data['@id'] : '',
		code: data.tokenValue,
		number: data.number,
		state: data.state ? data.state : data.checkoutState,
		items: !data.items ? [] : data.items
			.filter(item => !item.variant.includes('voice') && !item.variant.includes('post_processing'))
			.map(item => splitOrderItem(item))
			.flat().map(item => serializeOrderItem(item)),
		linkedItems: !data.items ? [] : data.items
			.filter(item => item.variant.includes('voice') || item.variant.includes('post_processing'))
			.map(item => { return { id: item.id, quantity: item.quantity, variant: item.variant } }),
		projectId: +(data.project && data.project['@id'] ? data.project['@id'] : data.project ? data.project : '').replace('/api/v2/shop/projects/', ''),
		payment: data.payments ? data.payments.map(payment => {
			return { id: payment.id, idPath: payment['@id'], method: payment.method }
		})[0] : null,
		clientName: data.project?.client?.company ? data.project.client.company : "",
		projectName:  data.project?.name ? data.project.name : "",
		remarks: data.notes,
		subtotal: data.total - data.taxTotal,
		total: data.total,
		tax: data.taxTotal,
		createdAt: data.createdAt,
		updatedAt: data.updatedAt
	}
}

export function serializeOrders(data: any): {items: Order[], totalItems: number } {
	return {
		items: data['hydra:member'].map(item => serializeOrder(item)),
		totalItems: data['hydra:totalItems']
	}
}

function splitOrderItem(item) {
	if (!item.instructions) return [orderItemInit]
	let indexes = item.instructions.map(instruction => {
		return instruction.payload.index
	}).filter((value, index, self) => {
		return self.indexOf(value) === index
	})
	return indexes.map(index => {
		let instructions = item.instructions.filter(instruction => {
			return instruction.payload.index === index}
		)
		return {...item, instructions, total: item.total / indexes.length}
	})
}
