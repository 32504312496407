import React, { useEffect, useState } from "react";
import Button from "components/elements/Button";
import Input from "components/elements/form/Input";
import Stack from "components/elements/stack/Stack";
import Textarea from "components/elements/form/Textarea";
import PaginatedSelectBox from "components/elements/PaginatedSelectBox";
import { toast } from "react-toastify";
import { usePutProject } from "hooks/api/projects";
import { useGetClients } from "hooks/api/clients";
import { Project, ProjectDTO } from "types/Project";

export interface ProjectEditProps {
	show: boolean
	project: Project
	onClose: () => void
}

const ProjectEdit: React.FC<ProjectEditProps> = ({
	show,
	project,
	onClose
}) => {
	const [showEditProject, setShowEditProject] = useState<boolean>(false)
	const [editProject, setEditProject] = useState<Project>(project)

	const {mutateAsync: putProject, isLoading} = usePutProject(project.id ? project.id : 0)

	useEffect(() => {
		setShowEditProject(show)
	}, [show])

	const onInputChange = (e) => {
		setEditProject( { ...editProject, [e.target.name]: e.target.value })
	}

	const onInputChangeClient = ( client ) => {
		setEditProject( { ...editProject, client: {idPath: client.value, name: client.name} } )
	}

	const closeClientCreate = () => {
		setShowEditProject(false)
		onClose()
	}


	const saveProject = (e) => {
		e.preventDefault()
		const data: ProjectDTO = {
			...editProject,
			comments: [],
			client: editProject.client.idPath,
		}
		putProject(data).then(resp => {
			setShowEditProject(false)
			onClose()
		}).catch(() =>{
			toast.error("Cannot edit project")
		})
	}

	return (
		<>
			{ editProject && <Stack isShow={showEditProject} onClose={() => closeClientCreate()} title="Edit project">
				<div className="px-8 mt-12">
					<h3 className="text-xl font-medium">Project</h3>
					<p className="text-sm">Edit the project:</p>
				</div>
				<form onSubmit={saveProject}>
					<div className="px-8 mt-4 space-y-4">
						<Input label="Name of the project" name="name" type="text" value={editProject.name} placeholder="Type the company name" onChange={onInputChange} required />
						<PaginatedSelectBox
							label="Select a client"
							name="client"
							hook={useGetClients}
							parse={item => { return { name: item.company, value: item.idPath } } }
							value={{value: editProject.client.idPath, name: editProject.client.name ? editProject.client.name : ''}}
							onChange={onInputChangeClient}
                            optional={true}
						/>
						<Textarea label="Description of the project" name="description" value={editProject.description} placeholder="Description" onChange={onInputChange} required />
					</div>


					<div className="flex px-8 my-12 space-x-4">
						<Button type="primary" submit={true} loading={isLoading}>Save project</Button>
						<Button type="grayoutline" onClick={() => closeClientCreate()}>Cancel</Button>
					</div>
				</form>
			</Stack> }
		</>
	)
}

export default ProjectEdit
