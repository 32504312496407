import request from "services/Request"
import { serializeTaxon } from "types/Taxon"

// export function getTaxons(parent: string) {
//     return request({
//         url: `/shop/taxons`,
//         method: 'GET',
//         params: {
//             "parent.code": parent
//         }
//     }, data => data)
// }

export function getTaxon(code: string) {
    return request({
        url: `/shop/taxons/${code}`,
        method: 'GET'
    }, serializeTaxon)
}
