import React from 'react'
import {
	XIcon
} from '@heroicons/react/outline'

type StackHeaderProps = {
	title?: string
	onClose?: () => void
};

const StackHeader: React.FC<StackHeaderProps> = ({
	title = "",
	onClose

}) => (
	<div className="flex items-start justify-between px-8 py-4 bg-cobalt-800">
		<h2 className="mr-2 text-2xl font-medium text-white">{title}</h2>
		<button className="mt-1" onClick={onClose}><XIcon className='w-6 h-6 text-white transition-colors duration-200 hover:text-gray-400'/></button>
	</div>

)

export default StackHeader