import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import useNavigation from "hooks/useNavigation";
import Alert from "components/elements/Alert";
import Button from "components/elements/Button";
import Input from "components/elements/form/Input";
import Panel from "components/elements/Panel";
import { usePasswordReset } from "hooks/api/auth";


export default function ResetPassword(props:any) {
	const {current} = useNavigation()
	const history = useHistory();
	const { token } = useParams<string | undefined>()

	const [showAlert, setShowAlert] = useState<boolean>(false)
	const [credentials, setCredentials] = useState({ newPassword: '', confirmNewPassword: '' });

	const { mutateAsync: patchPasswordReset, isLoading, error } = usePasswordReset(token);

	useEffect( () =>{
		if(!token){
			history.replace('/login')
		}
		
	}, [token])// eslint-disable-line react-hooks/exhaustive-deps

	const onInputChangeCredentials = (e) => {
		setCredentials({ ...credentials, [e.target.name]: e.target.value })
	}


	const resetPassword = (e) => {
		e.preventDefault()

		patchPasswordReset(credentials)
		.then( resp => {
			setShowAlert(true)
		})
		.catch( error => {

		})

	}

	const openDashboard = (e:React.MouseEvent) => {
		history.push('/login')
	}
	
	const openTrouble = (e: React.MouseEvent) => {
		history.push('/forgot-password')
	}

	return (
		<div className="mt-2">

			<div className="mb-4 text-center">
				<h1 className="text-2xl font-bold">{current ? current?.name: '' } </h1>
			</div>


			<div className="mb-6 text-center">	
				<p>
					Fill in a new password to reset your password.
				</p>
			</div>

			<Panel className="p-8" >

				<Alert show={showAlert} type="success" link={true} linkText='Sign in' onLink={openDashboard}>The password was successfully saved.</Alert>
				<Alert show={!!error} type="danger" link={true} linkText="Trouble?" onLink={openTrouble}>There was a problem saving your new password. It could be that your reset token is expired.</Alert>
				
				{!showAlert && <form onSubmit={resetPassword} className="space-y-6">

					<Input label="Your new password" name="newPassword" type="password" placeholder="Type your new password" onChange={onInputChangeCredentials} required />
					<Input label="Repeat your new password" name="confirmNewPassword" type="password" placeholder="Repeat your new password" onChange={onInputChangeCredentials} required />
								
					<div>
						<Button block={true} submit={true} loading={isLoading}>
							Save new password
						</Button>
					</div>
					

				</form>
				}
			</Panel>
		</div>
	)


}