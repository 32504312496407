import React, { InputHTMLAttributes } from 'react';

export interface ColorPickerProps extends InputHTMLAttributes<HTMLInputElement> {
	label?: string;
	name?: string;
	optional?: boolean
}

const ColorPicker: React.FC<ColorPickerProps> = ({
	children,
	type,
	className,
	label,
	name,
	optional = false,
	...restProps
}) => {
	return (
		<div>
			{label && (
				<label
					htmlFor={name}
					className="inline-block text-sm font-medium text-gray-700"
				>
					<span>{label}</span>
					{optional && <span className="text-xs text-gray-400">(optional)</span>}
				</label>
			)}
			<input
				id={name}
				name={name}
				type="color"
				className={`${className} inline-block h-8 mx-2 p-1 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-cobalt-500 focus:border-cobalt-500 sm:text-sm`}
				{...restProps}
			/>
		</div>
	)
}

export default ColorPicker
