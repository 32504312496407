import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import MainPage from 'components/elements/page/MainPage'
import Panel from 'components/elements/Panel'
import OrderRow from './order/OrderRow'
import Pagination from 'components/elements/Pagination'
import LoaderSpinning from "components/elements/LoaderSpinning"
import Empty from "components/elements/Empty"
import { useGetOrders } from 'hooks/api/orders'
import { Order } from 'types/Order'

export default function Orders(props: any) {
	const location = useLocation()
	const history = useHistory()
	const pageSize = 30;
	const [currentPage, setCurrentPage] = useState( 1 )
	const [totalItems, setTotalItems] = useState( 0 )

	const { data, isLoading, isFetching } = useGetOrders({ state: ['new', 'fulfilled'], itemsPerPage: pageSize, page: currentPage });
	const [orders, setOrders] = useState<Order[]>([])

	useEffect(() => {
		if (data) {
			setTotalItems(data.totalItems)
			setOrders(data.items)
		}
	}, [data])

	const openOrder = (id) => {
		history.push(`/orders/${id}`)
	}

	useEffect( () => {
		setCurrentPage(1)
	}, [location] )

	return (
		<MainPage isLoading={isLoading}>
			<Panel className="px-0 py-0">
				<div className="flex flex-col">
					<div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
						<div className="inline-block min-w-full align-middle sm:px-6 lg:px-8">
							<div className="overflow-hidden">
								{isLoading || isFetching ? <LoaderSpinning className="p-8">Loading orders...</LoaderSpinning> :
									(data && data.items.length > 0 ?
										<table className="min-w-full divide-y divide-gray-200">
											<thead className="border-b border-gray-200">
												<tr>
													<th scope="col" className="px-6 pt-5 pb-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">Order ID</th>
													<th	scope="col" className="px-6 pt-5 pb-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">Client Name</th>
													<th	scope="col" className="hidden px-6 pt-5 pb-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase md:table-cell">Project name</th>
													<th	scope="col" className="px-6 pt-5 pb-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">Order state</th>
													<th	scope="col" className="hidden px-6 pt-5 pb-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase md:table-cell">Created at</th>
													<th	scope="col" className="hidden px-6 pt-5 pb-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase md:table-cell">Updated at</th>
												</tr>
											</thead>
											<tbody className="space-y-4">
												{orders && orders.map((order, index) => (
													<OrderRow key={order.id} index={index} order={order} isProject={false} onClick={openOrder}/>
												))}
											</tbody>
										</table>
										: <Empty className="p-8">Your orders will appear here</Empty>
									)
								}
							</div>
						</div>
					</div>
				</div>
			</Panel>

			<Pagination
				className="pagination-bar"
				currentPage={currentPage}
				totalCount={totalItems}
				pageSize={pageSize}
				onPageChange={page => setCurrentPage( page )}
			/>
		</MainPage>
	)
}
