import React, { useState } from 'react'
import { TrashIcon } from '@heroicons/react/outline'
import OrderItemVoiceSelect from 'components/views/app/order/OrderItemVoiceSelect'
import ConfirmationDialog from 'components/elements/ConfirmationDialog'
import LoaderSpinning from "components/elements/LoaderSpinning";
import { useGetProductVariantByUrl, useGetProductVariantsByUrlArray } from 'hooks/api/products'
import { useRemoveOrderItem } from 'hooks/api/order-items'

export default function OrderItem({
    order,
    product,
    voices,
    editable,
    onUpdate
}) {

    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<boolean>(false)
    const {data: dataVariant, isLoading: loadingVariant} = useGetProductVariantByUrl(product.variant ? product.variant : product.productVariant)
    const {data: dataPostProcessingVariants, isLoading: loadingPostProcessingVariants} = useGetProductVariantsByUrlArray(product.postProcessingVariants)
    const { mutateAsync: removeProduct, isLoading: removeProductLoading } = useRemoveOrderItem(product, order)

    const deleteProductHandler = () => {
        return removeProduct().then(() => {
            setShowDeleteConfirmation(false)
        })
    }

    const wrapURLs = function (text, new_window) {
        const url_pattern = /(?:(?:https?|ftp):\/\/)?(?:\S+(?::\S*)?@)?(?:(?!10(?:\.\d{1,3}){3})(?!127(?:\.\d{1,3}){3})(?!169\.254(?:\.\d{1,3}){2})(?!192\.168(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\x{00a1}\-\x{ffff}0-9]+-?)*[a-z\x{00a1}\-\x{ffff}0-9]+)(?:\.(?:[a-z\x{00a1}\-\x{ffff}0-9]+-?)*[a-z\x{00a1}\-\x{ffff}0-9]+)*(?:\.(?:[a-z\x{00a1}\-\x{ffff}]{2,})))(?::\d{2,5})?(?:\/[^\s]*)?/ig
        const target = (new_window === true || new_window == null) ? '_blank' : ''

        return text.replace(url_pattern, function (url) {
            const protocol_pattern = /^(?:(?:https?|ftp):\/\/)/i
            const href = protocol_pattern.test(url) ? url : 'http://' + url
            return '<a class="underline text-cobalt-500 hover:no-underline hover:cursor-pointer" href="' + href + '" target="' + target + '">' + url + '</a>'
        })
    }

    return (
        <>
            {(loadingVariant || loadingPostProcessingVariants) ? <LoaderSpinning className="mt-6"/> : <>
                <div className="grid relative grid-cols-6 gap-4 lg:grid-cols-12">
                    <div className="relative col-span-5 px-5 mb-4 leading-8 bg-black bg-opacity-5 rounded-lg shadow lg:col-span-9">
                        <div className="text-sm sm:divide-y sm:divide-gray-200">
                            { product.reference && <div className="py-5 sm:grid sm:grid-cols-12 sm:gap-4">
                                <dt className="col-span-3 text-sm font-medium text-gray-500">Reference:</dt>
                                <dd className="col-span-9 text-sm text-gray-900 sm:mt-0" dangerouslySetInnerHTML={{__html: wrapURLs(product.reference, true)}} />
                            </div> }
                            <div className="py-5 sm:grid sm:grid-cols-12 sm:gap-4">
                                <dt className="col-span-3 text-sm font-medium text-gray-500">Product:</dt>
                                <dd className="col-span-9 text-sm text-gray-900 sm:mt-0">{product.productName}</dd>
                            </div>
                            <div className="py-5 sm:grid sm:grid-cols-12 sm:gap-4">
                                <dt className="col-span-3 text-sm font-medium text-gray-500">Length:</dt>
                                <dd className="col-span-9 text-sm text-gray-900 sm:mt-0">{dataVariant && dataVariant.name}</dd>
                            </div>

                            {dataPostProcessingVariants && !!dataPostProcessingVariants.length &&
                                <div className="py-5 sm:grid sm:grid-cols-12 sm:gap-4">
                                    <dt className="col-span-3 text-sm font-medium text-gray-500">Post-processing:</dt>
                                    <dd className="col-span-9 text-sm text-gray-900 sm:mt-0">
                                        <ul>
                                            {dataPostProcessingVariants.map(item => (
                                                <li className="inline-block group" key={item.id}>
                                                    <span>{item.name}</span>
                                                    <span className="mr-1 group-last:hidden">,</span>
                                                </li>
                                            ))}
                                        </ul>
                                    </dd>
                                </div>
                            }
                        </div>

                        {editable && (<div className="absolute right-0 -top-9">
                            <button className="inline-block group" onClick={(e) => {e.preventDefault(); setShowDeleteConfirmation(true)}} >
                                <TrashIcon className='inline-block w-4 font-medium text-gray-500 transition-colors duration-300 ease-in-out group-hover:text-red-700'/>
                                <span className="inline-block ml-1 text-sm text-gray-500 transition-colors duration-300 ease-in-out group-hover:text-red-700">Delete</span>
                            </button>
                            <ConfirmationDialog
                                type="danger"
                                title="Are you sure you want to delete this product from your order?"
                                confirmText="Delete"
                                isShow={showDeleteConfirmation}
                                onConfirm={deleteProductHandler}
                                onClose={() => setShowDeleteConfirmation(false)}
                                loading={removeProductLoading}
                            />
                        </div>)}
                    </div>
                    <div className="my-auto lg:col-start-10 xl:col-start-12">
                        <h3 className="mt-4 mb-4 text-xs text-right lg:text-lg">
                            {new Intl.NumberFormat( 'nl-NL', { style: 'currency', currency: 'EUR' } ).format((dataPostProcessingVariants?.reduce((sum, item) => sum + item.price, product.price)) / 100)}
                        </h3>
                    </div>

                    {order && (
                        <OrderItemVoiceSelect order={order} product={product} voices={voices} editable={editable} />
                    )}

                    {(!editable || (!editable)) && (
                        <div className="col-span-6 lg:col-span-9">
                            {product.script && (
                                <div className="py-5 sm:grid sm:grid-cols-12 sm:gap-4 lg:p-5">
                                    <dt className="col-span-3 text-sm font-medium text-gray-500">Script:</dt>
                                    <dd className="col-span-9 mt-2 text-sm text-gray-900 lg:mt-0">{product.script}</dd>
                                </div>
                            )}
                            {product.remarks && (
                                <div className="py-5 sm:grid sm:grid-cols-12 sm:gap-4 lg:p-5">
                                    <dt className="col-span-3 text-sm font-medium text-gray-500">Remarks:</dt>
                                    <dd className="col-span-9 mt-2 text-sm text-gray-900 lg:mt-0">{product.remarks}</dd>
                                </div>
                            )}
                        </div>
                    )}
                </div>
                <hr className="mt-16 mb-16"/>
            </>}
        </>
    )
}
