import { useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";


// if url has a valid dynamic link redirect to page corresponding with link action
// else redirect to dashboard
export default function DynamicLinkWrapper() {
    const history = useHistory()
    const location = useLocation()
    const link = new URLSearchParams(location.search).get('link')

    const redirectToDashboard = () => {
        history.replace('/dashboard')
    }

    useEffect(() => {
        let decoded: any = { action: '' }
        try {
            if (link) decoded = JSON.parse(atob(link))
            if (!decoded)  redirectToDashboard()
        } catch {
            decoded = { action: '' }
        }

        switch (decoded.action) {
            case "reset-password":
                if (decoded.data && decoded.data.token) {
                    history.replace(`/forgotten-password/${decoded.data.token}`)
                }
                break;
            case "project-comment":
                if (decoded.data) {
                    history.replace(`/feedback${location.search}`)
                }
                break;
            default:
                redirectToDashboard()
        }
    }, [link])

    return <></>
}
