import Orders from "components/views/app/Orders"
import Dashboard from "./components/views/app/Dashboard"
import Projects from "./components/views/app/Projects"
import Project from "./components/views/app/project/Project"
import ProjectOverview from "components/views/app/project/ProjectOverview"
import ProjectOrders from "components/views/app/project/ProjectOrders"
import Voices from "components/views/app/Voices"
import Clients from "components/views/app/Clients"
import Notifications from "components/views/app/Notifications"
import Login from "components/views/auth/Login"
import Register from "components/views/auth/Register"
import ProjectCommunication from "components/views/app/project/ProjectCommunication"
import ProjectCreate from "components/views/app/project/ProjectCreate"
import Client from "components/views/app/client/Client"
import ClientDetails from "components/views/app/client/ClientDetails"
import ClientProjects from "components/views/app/client/ClientProjects"
import Settings from "components/views/app/settings/Settings"
import SettingsAccount from "components/views/app/settings/SettingsAccount"
import SettingsCompanyProfile from "components/views/app/settings/SettingsCompanyProfile"
import ForgotPassword from "components/views/auth/ForgotPassword"
import ResetPassword from "components/views/auth/ResetPassword"
import Order from "components/views/app/order/Order"
import OrderOverview from "./components/views/app/order/OrderOverview";
import OrderCompleted from "./components/views/app/order/OrderCompleted";
import Feedback from "components/views/external/Feedback"
import DynamicLinkWrapper from "components/views/external/DynamicLinkWrapper";
import VoiceShare from "components/views/external/VoiceShare";
import {
	MusicNoteIcon,
	OfficeBuildingIcon,
	CubeIcon,
	TemplateIcon,
	CreditCardIcon
} from '@heroicons/react/solid'

export enum Menu {
	MENU_NONE,
	MENU_MAIN_SIDE,
	MENU_MAIN_TOP,
	MENU_PROJECT,
	MENU_CLIENT,
	MENU_SETTINGS
}

export interface RouteElement {
	path: string
	name: string
	layout: string
	exact: boolean
	menu: Menu
	main: React.ComponentType<any>
	routes: RouteElement[] | []
	parent?: RouteElement | null
	redirect?: string
	link?: string
	icon?:React.ComponentType < any >
	guest?:boolean
	client?:boolean
}


export const routes: RouteElement[] = [
	{
		path: '/',
		name: 'root',
		layout:'AuthLayout',
		main: DynamicLinkWrapper,
		menu: Menu.MENU_NONE,
		exact: true,
		guest: true,
		routes: []
	},
	{
		path: '/login',
		name: 'Sign in to your account',
		layout: 'AuthLayout',
		menu: Menu.MENU_NONE,
		exact: false,
		guest: true,
		main: Login,
		routes: []
	},
	{
		path: '/register',
		name: 'Register for reseller access',
		layout: 'AuthLayout',
		menu: Menu.MENU_NONE,
		exact: false,
		guest: true,
		main: Register,
		routes: []
	},
	{
		path: '/forgot-password',
		name: 'Trouble signing in?',
		layout: 'AuthLayout',
		menu: Menu.MENU_NONE,
		exact: false,
		guest: true,
		main: ForgotPassword,
		routes: []
	},
	{
		path: '/forgotten-password/',
		name: 'Reset Password',
		layout: 'AuthLayout',
		menu: Menu.MENU_NONE,
		exact: true,
		guest: true,
		main: ResetPassword,
		routes: []
	},
	{
		path: '/forgotten-password/:token',
		name: 'Reset Password',
		layout: 'AuthLayout',
		menu: Menu.MENU_NONE,
		exact: true,
		guest: true,
		main: ResetPassword,
		routes: []
	},
	{
		path: '/feedback',
		name: 'Feedback',
		layout: 'AuthLayout',
		menu: Menu.MENU_NONE,
		exact: false,
		guest: true,
		main: Feedback,
		routes: []
	},
	{
		path: '/dashboard',
		name: 'Dashboard',
		layout: 'AppLayout',
		menu: Menu.MENU_MAIN_SIDE,
		exact: false,
		client: true,
		main: Dashboard,
		icon: TemplateIcon,
		routes: []
	},
	{
		path: '/explore-voices',
		name: 'Explore Voices',
		layout: 'AppLayout',
		menu: Menu.MENU_MAIN_SIDE,
		exact: true,
		client: true,
		main: Voices,
		icon: MusicNoteIcon,
		routes: []
	},
	{
		path: '/explore-voices/:code',
		name: 'Voice',
		layout: 'AuthLayout',
		menu: Menu.MENU_NONE,
		exact: true,
		guest: true,
		client: true,
		main: VoiceShare,
		routes: []
	},
	{
		path: '/projects',
		name: 'Projects',
		layout: 'AppLayout',
		menu: Menu.MENU_MAIN_SIDE,
		exact: true,
		client: true,
		main: Projects,
		icon: CubeIcon,
		routes: [

		]
	},
	{
		path: '/projects/create',
		name: 'Create Project',
		layout: 'AppLayout',
		menu: Menu.MENU_NONE,
		exact: true,
		client: true,
		main: ProjectCreate,
		routes: []
	},
	{
		path: '/projects/:projectId/',
		name: 'Project',
		layout: 'AppLayout',
		menu: Menu.MENU_NONE,
		exact: false,
		client: true,
		main: Project,
		redirect: '/projects/:projectId/overview',
		routes: [
			{
				path: '/projects/:projectId/overview',
				name: 'Overview',
				layout: 'AppLayout',
				menu: Menu.MENU_PROJECT,
				exact: true,
				client: true,
				main: ProjectOverview,
				routes: [],
			},
			{
				path: '/projects/:projectId/orders',
				name: 'Orders',
				layout: 'AppLayout',
				menu: Menu.MENU_PROJECT,
				exact: true,
				client: true,
				main: ProjectOrders,
				routes: []
			},
			{
				path: '/projects/:projectId/order-overview',
				name: 'Orders',
				layout: 'AppLayout',
				menu: Menu.MENU_NONE,
				exact: true,
				client: true,
				main: OrderOverview,
				routes: []
			},
			{
				path: '/projects/:projectId/order-completed',
				name: 'Orders',
				layout: 'AppLayout',
				menu: Menu.MENU_NONE,
				exact: true,
				client: true,
				main: OrderCompleted,
				routes: []
			},
			{
				path: '/projects/:projectId/orders/:orderId',
				name: 'Order',
				layout: 'AppLayout',
				menu: Menu.MENU_NONE,
				exact: true,
				client: true,
				main: Order,
				routes: []
			},
			{
				path: '/projects/:projectId/communication',
				name: 'Communication',
				layout: 'AppLayout',
				menu: Menu.MENU_PROJECT,
				exact: true,
				client: true,
				main: ProjectCommunication,
				routes: []
			},
			// {
			// 	path: '/projects/:projectId/files',
			// 	name: 'Files',
			// 	layout: 'AppLayout',
			// 	menu: Menu.MENU_PROJECT,
			// 	exact: true,
			// 	main: ProjectOrders,
			// 	routes: []
			// },
		]
	},
	{
		path: '/clients',
		name: 'Clients',
		layout: 'AppLayout',
		menu: Menu.MENU_MAIN_SIDE,
		exact: true,
		main: Clients,
		icon: OfficeBuildingIcon,
		routes: [

		]

	},

	{
		path: '/clients/:clientId/',
		name: 'Client',
		layout: 'AppLayout',
		menu: Menu.MENU_NONE,
		exact: false,
		main: Client,
		redirect: '/clients/:clientId/details',
		routes: [
			{
				path: '/clients/:clientId/details',
				name: 'Details',
				layout: 'AppLayout',
				menu: Menu.MENU_CLIENT,
				exact: true,
				main: ClientDetails,
				routes: [],
			},
			{
				path: '/clients/:clientId/projects',
				name: 'Projects',
				layout: 'AppLayout',
				menu: Menu.MENU_CLIENT,
				exact: true,
				main: ClientProjects,
				routes: [],
			},
		]
	},
	{
		path: '/orders',
		name: 'Order history',
		layout: 'AppLayout',
		menu: Menu.MENU_MAIN_SIDE,
		exact: true,
		client: true,
		main: Orders,
		icon: CreditCardIcon,
		routes: []
	},
	{
		path: '/orders/:orderId',
		name: 'Order',
		layout: 'AppLayout',
		menu: Menu.MENU_NONE,
		exact: true,
		client: true,
		main: Order,
		routes: []
	},
	{
		path: '/notifications',
		name: 'Notifications',
		layout: 'AppLayout',
		menu: Menu.MENU_MAIN_TOP,
		exact: true,
		client: true,
		main: Notifications,
		routes: []
	},
	{
		path: '/settings',
		name: 'Settings',
		layout: 'AppLayout',
		menu: Menu.MENU_NONE,
		exact: false,
		client: true,
		main: Settings,
		redirect: '/settings/account',
		routes: [
			{
				path: '/settings/account',
				name: 'Account',
				layout: 'AppLayout',
				menu: Menu.MENU_SETTINGS,
				exact: true,
				client: true,
				main: SettingsAccount,
				routes: [],
			},
			{
				path: '/settings/companyprofile',
				name: 'Company profile',
				layout: 'AppLayout',
				menu: Menu.MENU_SETTINGS,
				exact: true,
				main: SettingsCompanyProfile,
				routes: [],
			},
		]
	},


]
