import React from 'react'
import { Link } from 'react-router-dom'
import { Menu, routes } from 'Routes'
import useNavigation from 'hooks/useNavigation'
import useStyling from 'hooks/useStyling'
import { useThemeMedia } from "hooks/api/themes-media";
import SideBarLogo from './SideBarLogo'
import Button from '../Button'
import {getEnv} from "../../../common/EnvUtil";
import {useCurrentUserRole} from "hooks/useCurrentUserRole";


type props = {
	onClose?: (open:boolean) => void;
}

export default function SideBarNavigation({ onClose }: props) {
	const { REACT_APP_BASE_IMAGE_URL } = getEnv()
	const { isInParentUrl } = useNavigation()
	const { switchClassNames } = useStyling()

	const themeMedia = useThemeMedia()
	const { data: logo } = themeMedia('logo')

	const role = useCurrentUserRole();

	return (
		<div className="flex flex-col flex-grow pt-5 pb-5 overflow-y-auto">
			<div className="flex items-center flex-shrink-0 px-4 h-10">
				<Link to='/dashboard'>
					{logo.id === 0 ? <SideBarLogo /> : <figure>
						<img src={`${REACT_APP_BASE_IMAGE_URL}/${logo.path}`} className="max-w-45 max-h-10 w-auto" alt="Logo" />
					</figure>}
				</Link>
			</div>
			<div className="flex flex-col flex-1 mt-5">
				<nav className="flex-1 px-2 space-y-1">
					<div className="my-4">
						<Link to='/projects/create'>
							<Button type="primary" block={true} onClick={() => onClose && onClose(false)}>Start new project</Button>
						</Link>
					</div>
					{routes.filter((item) => (item.menu === Menu.MENU_MAIN_SIDE && item.client) || (item.menu === Menu.MENU_MAIN_SIDE && !item.client && role === 'reseller')).map((item) => (
						<Link
							key={item.name}
							to={item.path}
							onClick={onClose ? onClose : () => {}}
							className={switchClassNames(
								isInParentUrl( item ) ? 'font-bold border-r-4 border-cobalt-100 -mr-2 rounded-none text-cobalt-100' : 'text-white hover:bg-cobalt-500 ',
								'group flex items-center px-2 py-2 text-sm font-medium rounded-md transition-colors duration-300 ease-in-out'
							)}
						>
							{item.icon && <item.icon className="flex-shrink-0 w-5 h-5 mr-4"/>}
							{item.name}
						</Link>
					))}

				</nav>
			</div>

			{logo.id !== 0 && <div className="flex items-center flex-shrink-0 px-4">
				<SideBarLogo />
			</div>}
		</div>
	)
}
