import React, {useEffect, useState} from 'react';
import { useParams, useHistory } from 'react-router-dom'
import { useGetProject } from 'hooks/api/projects'
import { useGetOrder, useOrderComplete } from 'hooks/api/orders'
import SelectedProducts from './SelectedProducts'
import PanelTitle from 'components/elements/PanelTitle'
import Textarea from 'components/elements/form/Textarea'
import Panel from 'components/elements/Panel'
import Button from 'components/elements/Button'
import { toast } from 'react-toastify'

export default function OrderOverview() {
    const { projectId } = useParams()
    const history = useHistory()

    const [orderId, setOrderId] = useState<string>('')
    const [clientId, setClientId] = useState<number>(0)
    const [remarks, setRemarks] = useState<string>('')

    const { data: dataProject } = useGetProject(projectId);
    const { data, isLoading } = useGetOrder(orderId);
    const { mutateAsync: patchOrderComplete, isLoading: loadingOrderCompletion } = useOrderComplete(data, remarks, clientId)

    useEffect(() => {
        if(dataProject && dataProject.cart){
            setOrderId( dataProject.cart.token )
        }
        if(dataProject && dataProject.client){
            setClientId( dataProject.client.idPath.replace('/api/v2/shop/customers/', '') )
        }
    }, [dataProject])

    useEffect(() => {
        if (!data) return
        if (data.state !== 'parked' && data.state !== 'cart') {
            backToProjectOverview()
        }
    }, [data])

    const backToProjectOverview = () => {
        history.push(`/projects/${projectId}/overview`)
    }

    const goToOrderCompleted = () => {
        history.push(`/projects/${projectId}/order-completed`)
    }

    const completeOrder = () => {
        const orderItemWithoutVoice = data.items.find(item => !item.voice)
        if (orderItemWithoutVoice) {
            toast.error( `Order item '${orderItemWithoutVoice.reference}' has no voice selected` )
            return
        }
        patchOrderComplete().then(() => {
            toast.success( 'Order succesfully completed' )
            goToOrderCompleted()
        })
    }

    return (
        <>
            {dataProject && <SelectedProducts order={data} voices={dataProject.voices} setShowProductAdd={null} isLoading={isLoading}></SelectedProducts>}
            <Panel className="px-0">
                <div className="px-8 pb-4 border-b border-gray-200">
                    <PanelTitle>Add remarks for the order</PanelTitle>
                </div>
                <div className="px-8 pt-5">
                    <Textarea label="Your order remarks" value={remarks} onChange={(e) => setRemarks(e.target.value)}></Textarea>
                </div>
            </Panel>
            <div className="float-right pb-4">
                <div className="float-right"><Button onClick={completeOrder} loading={loadingOrderCompletion}>Confirm order</Button></div>
                <div className="float-right mr-2"><Button type="outline" onClick={backToProjectOverview}>Back</Button></div>
            </div>
        </>
    )
}
