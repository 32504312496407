import request from "services/Request"
import { serializeAddress } from "types/Address"

// export function getAddresses(params) {
// 	return request({
// 		url: `/shop/addresses`,
// 		method: 'GET',
// 		params
// 	}, null)
// }

export function getAddress(id: number) {
	return request({
		url: `/shop/addresses/${id}`,
		method: 'GET'
	}, serializeAddress)
}

export function postAddress(data) {
	return request({
		url: `/shop/addresses`,
		method: 'POST',
		data
	}, serializeAddress)
}

export function putAddress(id: number, data) {
	return request({
		url: `/shop/addresses/${id}`,
		method: 'PUT',
		data
	}, serializeAddress)
}

// export function patchAddress(id: string, data) {
// 	return request({
// 		url: `/shop/addresses/${id}`,
// 		method: 'PATCH',
// 		headers: {
// 			"Content-Type": "application/merge-patch+json"
// 		},
// 		data
// 	}, serializeAddress)
// }

// export function deleteAddress(id: string) {
// 	return request({
// 		url: `/shop/addresses/${id}`,
// 		method: 'DELETE'
// 	}, serializeAddress)
// }
