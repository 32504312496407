import request from "services/Request"

export function postThemeColor(data) {
    return request({
        url: `/shop/theme-colors`,
        method: 'POST',
        data
    }, () => {})
}

export function deleteThemeColor(id: number) {
    return request({
        url: `/shop/theme-colors/${id}`,
        method: 'DELETE'
    }, () => {})
}
