import { usePagination, DOTS } from 'hooks/usePagination'
import { ArrowNarrowLeftIcon, ArrowNarrowRightIcon } from '@heroicons/react/solid'
import useStyling from 'hooks/useStyling'

const Pagination = props => {
	const {
		onPageChange,
		totalCount,
		siblingCount = 1,
		currentPage,
		pageSize
	} = props;

	const paginationRange = usePagination({currentPage, totalCount, siblingCount, pageSize});
	const { switchClassNames } = useStyling()

	if(paginationRange === undefined){
		return (<></>)
	}

	if (currentPage === 0 || paginationRange.length < 2) {
		return null;
	}

	const lastPage = paginationRange[paginationRange.length - 1]

	const onNext = () => {
		if (currentPage === paginationRange[paginationRange.length - 1]) return
		onPageChange(currentPage + 1);
	};

	const onPrevious = () => {
		if(currentPage === 1) return;
		onPageChange(currentPage - 1);
	};

	return (
		<nav className="flex justify-between items-center px-4 sm:px-0">
			<div className="flex flex-1 -mt-px w-0">
				<button className={switchClassNames( currentPage === 1 ? 'opacity-50' : 'opacity-100', 'inline-flex items-center pt-4 pl-1 text-sm font-medium text-gray-500 hover:text-gray-700 transition-all duration-300 ease-in-out group' )}  onClick={onPrevious}>
					<ArrowNarrowLeftIcon className="mr-3 w-5 h-5 text-gray-400 transition-all duration-300 ease-in-out group-hover:text-gray-600 group" aria-hidden="true" /> Previous
				</button >
			</div>
			<div className="hidden md:-mt-px md:flex">
			{paginationRange.map(pageNumber => {
				if (pageNumber === DOTS) {
					return <div key={pageNumber} className="inline-flex items-center p-4 text-sm font-medium text-gray-500">&#8230;</div>	
				}

				return (
					<button  key={pageNumber} className={switchClassNames(currentPage === pageNumber ? 'text-cobalt-500 border-cobalt-500' : 'text-gray-500 border-transparent','border-b-2 transition-all duration-300 inline-flex items-center p-4 text-sm font-medium hover:text-gray-700 group')} onClick={() => onPageChange(pageNumber)}>{pageNumber}</button>
				)
			})}
			</div>

			<div className="flex flex-1 justify-end -mt-px w-0">
				<button className={switchClassNames( currentPage === lastPage?'opacity-50':'opacity-100','inline-flex items-center pt-4 pl-1 text-sm font-medium text-gray-500 hover:text-gray-700 transition-all duration-300 ease-in-out group' )} onClick={onNext}>
					Next <ArrowNarrowRightIcon className="ml-3 w-5 h-5 text-gray-400 transition-all duration-300 ease-in-out group-hover:text-gray-600 group" aria-hidden="true" />
				</button>
			</div>
		</nav>
	)
}

export default Pagination