import { useState } from "react"
import { singletonHook } from "react-singleton-hook"
import { Voice } from "types/Voice"


const initVoice = null;
let globalSetActiveVoice = (voice: Voice) => { throw new Error('you must useActiveVoice before setting its state'); };

const useActiveVoice = singletonHook(initVoice, () => {
	const [activeVoice, setActiveVoice] = useState<Voice | null> (initVoice);
	//@ts-ignore
	globalSetActiveVoice = setActiveVoice;
	return activeVoice;
});

export default useActiveVoice

export const setActiveVoice = activeVoice => globalSetActiveVoice(activeVoice);
