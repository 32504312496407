import { useMutation } from 'react-query'
import { login, clientToken, refreshToken as refresh, requestPasswordReset, passwordReset } from 'services/requests/auth'

export const useLogin = () => {
	interface Credentials {
		email: string
		password: string
	}

	return useMutation<any, any, Credentials>((credentials) => login(credentials))
}

export const useClientToken = () => {
	return useMutation<any, unknown, number>((id) => clientToken(id))
}

export const useTokenRefresh = () => {
	return useMutation<any, unknown, {refreshToken: string}>((token) => refresh(token))
}

export const useRequestPasswordReset = () => {
	return useMutation<any, unknown, {email: string, locale: string}>((request) => requestPasswordReset(request))
}

export const usePasswordReset = (token) => {
	interface PasswordReset {
		newPassword: string
		confirmNewPassword: string
	}

	return useMutation<any, unknown, PasswordReset>((reset) => passwordReset(token, reset))

}
