import { useQuery } from 'react-query'
import { getVoices, getVoiceByUrl, getVoiceByCode } from 'services/requests/voices'
import { useGetResourcesByUrlArray } from './base'
import { Voice } from 'types/Voice'


export const useGetVoices = (params) => {
	const defaultParams = {
		page: 1,
		itemsPerPage: 30
	}

	return useQuery(
		['voices', {...defaultParams, ...params}],
		() => getVoices({...defaultParams, ...params})
	)
}

export const useGetVoicesByUrlArray = ( voices ) => {
	return useGetResourcesByUrlArray<Voice>(getVoiceByUrl, voices)
}

export const useGetVoiceByCode = ( code ) => {
	return useQuery(['voice', code],() => getVoiceByCode(code))
}
