import request from "services/Request"
import { serializeComment, serializeComments } from "types/Comment"


export function getProjectComments(id: number, params) {
    return request({
        url: `/shop/projects/${id}/comments`,
        method: 'GET',
        params
    }, serializeComments)
}

export function getComments(params) {
    return request({
        url: `/shop/project-comments`,
        method: 'GET',
        params
    }, serializeComments)
}

export function getComment(id: number) {
    return request({
        url: `/shop/project-comments/${id}`,
        method: 'GET'
    }, serializeComment)
}

export function postComment(data) {
    return request({
        url: `/shop/project-comments`,
        method: 'POST',
        data
    }, serializeComment)
}

export function putComment(id: number, data) {
    return request({
        url: `/shop/project-comments/${id}`,
        method: 'PUT',
        data
    }, serializeComment)
}