import { Audio } from "types/Audio";

interface VoiceFilter {
	hierarchy: any;
	idPath: string
	path: string
	code: string
	name: string
	type: string
}

export interface Voice {
	id: number
	idPath: string
	code: string
	name: string
	image: string
	description: string
	tags: string[]
	audio?: Audio[]
	filters: VoiceFilter[]
}

export function serializeVoice(data): Voice {
	return {
		id: data.id,
		idPath: data['@id'] ? data['@id'].toLowerCase() : '',
		code: data.code,
		name: data.name,
		image: data.images[0]?.path,
		description: data.description,
		tags: [],
		audio: data.audio,
		filters: serializeVoiceFilters(data)
	}
}

function serializeVoiceFilters(data): VoiceFilter[] {
	return data && data.productTaxons.map(item => {
		if (!item.taxon) return { idPath: item }
		return {
			idPath: item.taxon['@id'],
			path: item.path,
			code: item.taxon.code,
			name: item.taxon.name,
			type: item.path.split('/')[1],
			hierarchy: item.path.split('/').splice(1)
		}
	})
}

export function serializeVoices(data): {items: Voice[], aggregations: any, totalItems: number} {
	return {
		items: data['hydra:member'].map(item => serializeVoice(item)),
		aggregations: data.elasticsearch.aggregations,
		totalItems: data['hydra:totalItems']
	}
}
