import { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import useNavigation from "hooks/useNavigation";
import EventBus from "common/EventBus";
import Alert from "components/elements/Alert";
import Button from "components/elements/Button";
import Input from "components/elements/form/Input";
import Panel from "components/elements/Panel";
import useToken from "hooks/useToken";
import { useLogin } from "hooks/api/auth";


export default function Login(props:any) {
	const {current} = useNavigation()
	const history = useHistory()

	const [credentials, setCredentials] = useState({ email: 'groningen+reseller1@sigbar.dev', password:'reseller1'});

	const { mutateAsync: postLogin, isLoading, error } = useLogin();
	const {setUser} = useToken();

	const onInputChangeCredentials = (e) => {
		setCredentials({ ...credentials, [e.target.name]: e.target.value })
	}

	const login = (e) => {
		e.preventDefault()
		postLogin(credentials).then(user => {
			setUser(user)
			EventBus.dispatch( 'login', null )
			history.replace('/dashboard')
		})
	}

	return (
		<div className="mt-2">

			<div className="mb-4 text-center">
				<h1 className="text-2xl font-bold">{current ? current?.name: '' } </h1>
			</div>

			<div className="mb-6 text-center">
				<p>
					Don't have an account? <Link to="/register" className="link">Register here</Link>.
				</p>
			</div>

			<Panel className="p-8" >

				<Alert show={!!error} type="danger">{error && error.data.message}</Alert>

				<form onSubmit={login} className="space-y-6" autoComplete="off">
					<Input label="Email address" name="email" type="email" placeholder="Type your email" autoComplete="off" onChange={onInputChangeCredentials} required />
					<Input label="Password" name="password" type="password" placeholder="Type your password" autoComplete="new-password" onChange={onInputChangeCredentials}  required />
						<Button type='primary' block={true} submit={true} loading={isLoading}>
							Sign in
						</Button>
					<div className="flex items-center justify-center">
						<div className="text-sm">
							<Link to="/forgot-password" className="link">Trouble signing in?</Link>
						</div>
					</div>

				</form>
			</Panel>
		</div>
	)
}
