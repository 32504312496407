import React, { InputHTMLAttributes } from 'react';

export interface SelectProps extends InputHTMLAttributes<HTMLSelectElement> {
	label?: string;
	name?: string;
	optional?: boolean
	helpLinkText?: string
	helpLink?: string
}

const Select: React.FC<SelectProps> = ({
	children,
	type,
	className,
	label,
	name,
	helpLinkText,
	helpLink,
	optional = false,
	...restProps
}) => {
	return (
		<div>
			{label && (
				<label
					htmlFor={name}
					className="flex items-end justify-between text-sm font-medium text-gray-700"
				>
					<span>
						{label}
						{helpLinkText && helpLink && <a className="ml-1 text-xs text-gray-400" href={helpLink} target="_blank">{helpLinkText}</a>}
					</span>
					{optional && <span className="text-xs text-gray-400">(optional)</span>}
				</label>
			)}
			<div className="mt-1">
				<select
					id={name}
					name={name}
					className={`${className} block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-cobalt-500 focus:border-cobalt-500 text-xs md:text-sm`}
					{...restProps}
				>
					{children}
				</select>
			</div>
		</div>
	)
}

export default Select
