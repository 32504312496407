import React from 'react'

export interface LoaderSpinningProps extends React.HTMLAttributes < HTMLElement > {
	color?: string
}

const LoaderSpinning: React.FC<LoaderSpinningProps> = ( {
  children,
  className= '',
  color = '#969696'
} ) => (
    <div className={`${className} text-sm transition cursor-not-allowed`}>
        <svg className="mx-auto mb-4 w-6 h-6 animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle className="opacity-1" cx="12" cy="12" r="10"  strokeWidth="4" />
            <path className="opacity-1" fill={color ? color : '#969696'} d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
        </svg>
        <span className="block mx-auto max-w-lg text-center">{children}</span>
    </div>
)

export default LoaderSpinning
