import axios from "axios";
import EventBus from "common/EventBus";
import TokenUtil from "common/TokenUtil";
import {getEnv} from "common/EnvUtil";

const { REACT_APP_BASE_API_URL: API_URL } = getEnv();

console.log(API_URL)

export const apiAxios = axios.create({
	baseURL: API_URL,
	headers: {
		"Content-Type": "application/json",
	},
});

const noAuthUrls = [
	'/shop/authentication-token',
	'/shop/authentication-token/refresh'
];

const isNoAuthUrl = configUrl => {
	return !noAuthUrls.find(url => url === configUrl)
}

let refreshTokenPromise;

const requestOnFulfilledInterceptor = (config) => {
	const token = TokenUtil.getLocalAccessToken();

	if (token && isNoAuthUrl(config.url)) {
		config.headers["Authorization"] = 'Bearer ' + token;
	} else {
		delete config.headers["Authorization"];
	}
	return config;
}

const requestOnRejectedInterceptor = (error) => {
	return Promise.reject(error);
}

apiAxios.interceptors.request.use(
	requestOnFulfilledInterceptor,
	requestOnRejectedInterceptor
);

const responseOnFulfilledInterceptor = (res) => {
	return res;
};

const responseOnRejectedInterceptor = async (err) => {
	const originalConfig = err.config;
	if (originalConfig && originalConfig.url && isNoAuthUrl(originalConfig.url) && err.response) {
		// Access Token was expired
		if (err.response.status === 401 && !originalConfig.__isRetry) {

			originalConfig.__isRetry = true;
			try {
				if (!refreshTokenPromise) {
					refreshTokenPromise = apiAxios.post("/shop/authentication-token/refresh", {
						refreshToken: TokenUtil.getLocalRefreshToken(),
					});
				};

				const rs = await refreshTokenPromise;
				TokenUtil.updateLocalAccessToken(rs.data);

				refreshTokenPromise = undefined;
				return apiAxios(originalConfig);
			} catch (_error) {
				EventBus.dispatch('logout', null);
				refreshTokenPromise = undefined;
				return Promise.reject(_error);
			}
		} else if (err.response.status === 401 && originalConfig.__isRetry) {
			EventBus.dispatch('logout', null);
		}
	}

	return Promise.reject(err);
};

apiAxios.interceptors.response.use(
	responseOnFulfilledInterceptor,
	responseOnRejectedInterceptor
);
