import React from 'react'
import { useParams } from 'react-router-dom'
import { useGetProjectComments } from 'hooks/api/comments'
import { dateFromString, dateToStringWithTime } from 'common/DateUtils'
import LoaderSpinning from "components/elements/LoaderSpinning";
import Empty from "components/elements/Empty";
import { ClockIcon, UserIcon, ChatIcon } from '@heroicons/react/outline'

export default function ProjectCommunication() {
	const { projectId } = useParams()
	const { data, isLoading } = useGetProjectComments(projectId, {})

	return (
		<div className="mt-8">
			<div className="flex flex-wrap -m-4">
				<div className="p-4 m-auto w-full">

					{isLoading ? <LoaderSpinning>Loading comments...</LoaderSpinning> :
						(data && data.items.length > 0 ? data && data.items.slice(0).map((item, index) => (
							<div className="flex justify-center px-5 py-5" key={index}>
								<div className="relative max-w-3xl w-full">
									<div className={`absolute w-10 h-10 bg-white rounded-full shadow line ${item.payload && item.payload.voices ? 'left-0' : 'right-0'}`}>
										<ChatIcon className="relative top-1 m-auto w-6 h-8 opacity-75" aria-hidden="true"/>
									</div>
									<div className={`bg-white rounded-lg shadow ${item.payload && item.payload.voices ? 'ml-14' : 'mr-14'}`}>
										<div className="p-8">
											<span className="flex-auto font-bold text-gray-800 block mb-4">
												{item.payload && item.payload.voices ? 'Client Feedback response' : 'Requested feedback on voices'}
											</span>
											{item.comment && <span className="inline text-sm font-medium text-gray-900">{item.comment}</span>}

											<div className="flex justify-between shrink-0 mt-6">
												<div className="flex shrink-0">
												{item.payload && item.payload.voices && item.payload.voices[0] &&
													<span className="block text-sm text-gray-900">
														<div className="inline px-2 py-1 text-xs font-bold text-white uppercase bg-cobalt-500 rounded-full">
															<UserIcon className="inline relative -top-px m-auto mr-1 w-3 h-3" aria-hidden="true"/>
															<span>{item.payload.voices[0]}</span>
														</div>
													</span>
												}
												</div>
												<span className="inline text-xs text-gray-400">
													<ClockIcon className="inline relative top-px mr-1 w-4 h-4 text-xs" aria-hidden="true" />
													<span className="relative top-0.5">{item.createdAt ? dateToStringWithTime(dateFromString(item.createdAt)) : '-'}</span>
												</span>
											</div>
										</div>
									</div>
								</div>
							</div>
						)) : <Empty>Your project communication will appear here</Empty>
					)}
				</div>
			</div>
		</div>
	)
}