import { Address, addressInit, serializeAddress } from "types/Address";
import { Contact } from "types/Contact";

export interface Client {
	id: number
	idPath: string
	email:string
	company:string
	firstName: string
	lastName: string
	kvkNumber: string
	vatNumber: string
	contacts: Contact[]
	addresses?: Address[] //| string[]
	defaultAddress?: Address
	hasUser: boolean
	clientProjects: string[],
	numberOfContacts: number
}

export interface ClientDTO {
	email: string
	company: string
	firstName: string
	lastName: string
	kvkNumber: string
	vatNumber: string
}

export const clientInit: Client =  {
	id: 0,
	idPath: '',
	email: '',
	company: '',
	firstName: '--',
	lastName: '--',
	kvkNumber: '',
	vatNumber: '',
	contacts: [],
	addresses: [],
	hasUser: false,
	clientProjects:[],
	numberOfContacts: 0
}

export const clientDTOInit = {
	email: '',
	company: '',
	firstName: '--',
	lastName: '--',
	kvkNumber: '',
	vatNumber: '',
}

export function serializeClient(data:any): Client {
	if (!data) return clientInit;

	let addresses = []
	let defaultAddress: Address = addressInit
	if (typeof(data.defaultAddress) !== 'string') {
		addresses = data.addresses.map(item => serializeAddress(item))
		defaultAddress = serializeAddress(data.defaultAddress)
	}

	return {
		...clientInit,
		id: data.id,
		idPath: data['@id'],
		email: data.email,
		company: data.company,
		firstName: data.firstName,
		lastName: data.lastName,
		kvkNumber: data.kvkNumber,
		vatNumber: data.vatNumber,
		defaultAddress,
		addresses,
		hasUser: !!data.user,
		clientProjects: data.clientProjects,
		numberOfContacts: data.addresses.length ? data.addresses.length - 1 : 0
		//contacts: data.contacts.map(item => serializeContact(item))
	}
}

export function serializeClients(data: any): {items: Client[], totalItems: number } {
	return {
		items: data['hydra:member'].map(item => serializeClient(item)),
		totalItems: data['hydra:totalItems']
	}
}
