import React from "react"
import { dateFromString, dateToStringWithTime } from "common/DateUtils"
import useStyling from "hooks/useStyling"
import { Order } from "types/Order"

const OrderRow: React.FC<{order: Order, index: number, isProject: boolean, onClick: (id:any) => void}> = ( props => {
	const { switchClassNames } = useStyling()

	return (
		<tr key={props.order.id} className={switchClassNames( props.index % 2 === 0 ? 'bg-transparent' : 'bg-transparent', 'hover:bg-blacks-50 hover:text-cobalt-500 hover:bg-opacity-5 cursor-pointer ' )} onClick={() => props.onClick( props.order.code )}>
			<td className="px-6 py-6 text-sm text-cobalt-500 whitespace-nowrap">{props.order.number ? props.order.number : '-'}</td>
			{props.isProject && ( <>
				<td className="px-6 py-6 text-sm whitespace-nowrap">{props.order.items ? props.order.items.length : 0}</td>
				<td className="px-6 py-6 text-sm whitespace-nowrap capitalize">{props.order.state}</td>
			</> ) }
			{!props.isProject && ( <>
				<td className="px-6 py-6 text-sm whitespace-nowrap">{props.order.clientName}</td>
				<td className="px-6 py-6 text-sm whitespace-nowrap capitalize">{props.order.projectName}</td>
				<td className="px-6 py-6 text-sm whitespace-nowrap capitalize">{props.order.state}</td>
			</> ) }
			<td className="hidden px-6 py-6 text-sm md:table-cell whitespace-nowrap">{props.order.createdAt && dateToStringWithTime( dateFromString( props.order.createdAt ) )}</td>
			<td className="hidden px-6 py-6 text-sm md:table-cell whitespace-nowrap">{props.order.updatedAt && dateToStringWithTime( dateFromString( props.order.updatedAt ) )}</td>
		</tr>
	)
})

export default OrderRow
