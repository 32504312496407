import React from "react";
import { useParams } from 'react-router-dom'
import { useGetVoiceByCode } from "hooks/api/voices";
import Voice from "components/elements/Voice";
import PanelTitle from "components/elements/PanelTitle";
import LoaderSpinning from "components/elements/LoaderSpinning";
import Button from "components/elements/Button";
import Panel from "components/elements/Panel";
import Empty from "components/elements/Empty";
import { ClipboardCopyIcon } from "@heroicons/react/solid";
import { toast } from "react-toastify";
import { setActiveVoice } from 'hooks/useActiveVoice'
import {useThemeColor} from "hooks/api/themes-color";
import { useLocation } from 'react-router-dom'

export default function VoiceShare() {
    const themeId = parseInt(new URLSearchParams(useLocation().search).get('theme') ?? '0')
    const themeColor = useThemeColor(themeId)
    const { data: buttonColor } = themeColor("button-color")
    const { data: buttonTextColor } = themeColor("button-text-color")

    const { code } = useParams()
    const {data, isLoading, error} = useGetVoiceByCode(code)

    const getSubTaxons = (sub: string) => {
        return data.filters.filter(filter =>
            filter.hierarchy[1] && filter.hierarchy[1] === sub
        ).map(filter =>
            filter.name
        ).join("; ")
    }

    const copyLink = () => {
        navigator.clipboard.writeText(`${window.location.origin}/explore-voices/${data.code}`).then(() => {
            toast.success("Successfully copied voice link to your clipboard")
        }).catch(() => {
            toast.error("Something went wrong while trying to copy voice link to your clipboard")
        })
    }

    return <>
    {isLoading ? <LoaderSpinning className="py-9">Loading voice...</LoaderSpinning> :
        error ? <Empty>This voice does not exist</Empty> :
        <>
            <div className="h-auto aspect-w-10 aspect-h-10">
                <Voice voice={data} onClick={() => setActiveVoice( data )}></Voice>
            </div>
            <Panel className="mt-4">
                <PanelTitle>{data &&
                    <div className="flex justify-between">
                        <span className="self-center text-black">{data.name}</span>
                        <Button onClick={copyLink} style={{backgroundColor: buttonColor.color, borderColor: buttonColor.color, color: buttonTextColor.color}} >
                            Copy link <ClipboardCopyIcon className="float-right ml-2 h-6"/>
                        </Button>
                    </div>
                }</PanelTitle>
                <div className="-mx-8 mt-8 border-t border-b border-gray-200">
                    <dl className="sm:divide-y sm:divide-gray-200">
                        <div className="px-8 py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                            <dt className="text-sm font-medium text-gray-500">Language</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                {getSubTaxons('voice_language')}
                            </dd>
                        </div>
                        <div className="px-8 py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                            <dt className="text-sm font-medium text-gray-500">Gender</dt>
                            <dd className="mt-1 text-sm text-gray-900 capitalize sm:mt-0 sm:col-span-2">
                                {getSubTaxons('voice_gender')}
                            </dd>
                        </div>
                        <div className="px-8 py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                            <dt className="text-sm font-medium text-gray-500">Age</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                {getSubTaxons('voice_age')}
                            </dd>
                        </div>
                        <div className="px-8 py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                            <dt className="text-sm font-medium text-gray-500">Characters</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                {getSubTaxons('voice_character')}
                            </dd>
                        </div>
                        <div className="px-8 py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                            <dt className="text-sm font-medium text-gray-500">Description</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{data.description}</dd>
                        </div>
                    </dl>
                </div>
            </Panel>
        </>}
    </>
}
