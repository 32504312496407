import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import Panel from 'components/elements/Panel'
import OrderRow from '../order/OrderRow'
import Pagination from 'components/elements/Pagination'
import LoaderSpinning from 'components/elements/LoaderSpinning'
import Empty from 'components/elements/Empty'
import { useGetProjectOrders } from 'hooks/api/projects'

export default function ProjectOrders(props: any) {
	const {projectId} = useParams()
	const history = useHistory()
	const pageSize = 30;
	const [currentPage, setCurrentPage] = useState( 1 )
	const [totalItems, setTotalItems] = useState( 0 )

	const { data: orders, isLoading: loadingOrders, isFetching: fetchingOrders } = useGetProjectOrders(projectId, { itemsPerPage: pageSize, page: currentPage });

	useEffect(() => {
		if (orders) {
			setTotalItems(orders.totalItems)
		}
	}, [orders])

	const openOrder = (id) => {
		history.push(`/projects/${projectId}/orders/${id}`)
	}

	return (
		<>
			<Panel className="px-0 py-0">
				<div className="flex flex-col">
					<div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
						<div className="inline-block min-w-full align-middle sm:px-6 lg:px-8">
							<div className="overflow-hidden">
								<table className="min-w-full divide-y divide-gray-200">
									<thead className="border-b border-gray-200">
									<tr>
										<th scope="col" className="px-6 pt-5 pb-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">Order ID</th>
										<th scope="col" className="px-6 pt-5 pb-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">Number of products</th>
										<th scope="col" className="px-6 pt-5 pb-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">Status</th>
										<th scope="col" className="hidden px-6 pt-5 pb-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase md:table-cell">Created at</th>
										<th scope="col" className="hidden px-6 pt-5 pb-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase md:table-cell">Updated at</th>
									</tr>
									</thead>
									<tbody className="space-y-4">

									{loadingOrders || fetchingOrders ?
										<tr>
											<td colSpan={5}>
												<LoaderSpinning className="p-8">Loading orders...</LoaderSpinning>
											</td>
										</tr>
										: (orders && orders.items.length > 0
											? <>
												{orders && orders.items && orders.items.map((order, index) => (
													<OrderRow key={order.code} index={index} order={order} isProject={true} onClick={openOrder} />
												))}
											</>
											: <tr>
												<td colSpan={5}>
													<Empty className="p-8">Your project orders will appear here</Empty>
												</td>
											</tr>
										)
									}

									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</Panel>

			<div>
				<Pagination
					className="pagination-bar"
					currentPage={currentPage}
					totalCount={totalItems}
					pageSize={pageSize}
					onPageChange={page => setCurrentPage( page )}
				/>
			</div>
		</>
	)
}