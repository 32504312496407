import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Voice from 'components/elements/Voice'
import Stack from 'components/elements/stack/Stack'
import Button from 'components/elements/Button'
import Select from 'components/elements/form/Select'
import Textarea from 'components/elements/form/Textarea'
import { toast } from "react-toastify"
import { CollectionIcon } from '@heroicons/react/outline'
import { ClipboardCopyIcon } from "@heroicons/react/solid"
import { setActiveVoice } from 'hooks/useActiveVoice'
import { useClientToken } from "hooks/api/auth"
import { useGetVoicesByUrlArray } from 'hooks/api/voices'
import { useGetClient } from 'hooks/api/clients'
import { usePostComment } from "hooks/api/comments"
import { useTheme } from "hooks/api/themes";
import { Comment, commentInit } from "types/Comment"
import { Voice as VoiceElement } from 'types/Voice'


export interface ProjectFeedbackProps {
    client: number
    show: boolean
    voices: string[]
    onClose: () => void
    onUpdate?: ( items: string[] ) => void
}

const ProjectFeedback: React.FC<ProjectFeedbackProps> = ({show,onClose, voices, onUpdate, client}) => {
    const { projectId } = useParams()
    const [newComment, setNewComment] = useState<Comment>(commentInit)
    const [allowUpdates, setAllowUpdates] = useState<boolean>( false )
    const [showProjectFeedback, setShowProjectFeedback] = useState<boolean>( false )
    const [selectedVoices, setSelectedVoices] = useState<VoiceElement[]>([])
    const [link, setLink] = useState<string>('')

    const { data: theme } = useTheme()
    const { data: dataVoices } = useGetVoicesByUrlArray(voices)
    const { data: dataClient } = useGetClient(client)
    const { mutateAsync: postComment, isLoading: loadingComment} = usePostComment()
    const { mutateAsync: postClientToken, isLoading: loadingClientToken} = useClientToken()

    useEffect(() => {
        setShowProjectFeedback(show)
    }, [show])

    useEffect( () => {
        if(dataVoices !== null){
            setSelectedVoices(dataVoices ? dataVoices : [])
            setAllowUpdates(false)
        }
    }, [dataVoices] )

    useEffect( () => {
        const items:string[] = selectedVoices.map(item =>{
            return item.idPath
        })

        if(allowUpdates){
            if(onUpdate) onUpdate(items)
        }

    }, [selectedVoices])

    const resetData = () => {
        setShowProjectFeedback(false)
        setLink('')
    }

    const closeProjectFeedback = () => {
        resetData()
        onClose()
    }

    const onInputChange = e => {
        if (e.target.name === 'selected_contact')
            setNewComment({
                ...newComment, payload: {contactId: e.target.value} }
            )
        else
            setNewComment({
                ...newComment, [e.target.name]: e.target.value }
            )
    }

    const saveComment = () => {
        return postComment({
            ...newComment,
            customer: '', // customer is added automatically by API
            project: `/api/v2/shop/projects/${projectId}`,
            payload: {
                ...newComment.payload,
                clientId: client
            }
        })
    }

    const sendFeedbackRequest = () => {
        if (!dataClient || !dataClient.hasUser) {
            return toast.error( 'The client of this project does not have the permissions to provide feedback. Please contact us.' )
        }
        let clientToken
        postClientToken(client).then(data => {
            clientToken = data
            return saveComment()
        })
       .then(async data => {
           const unique = btoa(JSON.stringify({
               action: 'project-comment',
               data: {
                   id: '/api/v2/shop/project-comment/' + data.id,
                   refreshToken: clientToken.refreshToken
               },
           }));
           setLink(`/?link=${unique}`)
           toast.success( 'Feedback request successful' )
       })
    }

    const openPreview = () => {
        const feedbackLink = window.open(link, "_blank")

        if (feedbackLink != null) {
            feedbackLink.focus()
        }
    }

    const copyLink = () => {
        navigator.clipboard.writeText(`${window.location.origin}${link}${theme.id ? `&theme=${theme.id}` : ``}`).then(() => {
            toast.success("Successfully copied feedback link to your clipboard")
        }).catch(() => {
            toast.error("Something went wrong while trying to copy feedback link to your clipboard")
        })
    }

    return (
        <Stack isShow={showProjectFeedback} onClose={() => closeProjectFeedback()} title="Request client feedback">
            <div className="px-8 mt-12">
                {!link ?
                    <>
                        <h3 className="text-xl font-medium">Selected Voices</h3>
                        <div className="mt-4 body-font">
                        {selectedVoices.length > 0 ?
                            <>
                                <div className="grid grid-cols-3 gap-4 lg:grid-cols-4">
                                    {selectedVoices.map((item, index) => (
                                        <div className="h-auto aspect-w-10 aspect-h-10" key={index}>
                                            <Voice voice={item} onClick={() => setActiveVoice(item)}/>
                                        </div>
                                    ))}
                                </div>

                                <div className="mt-8">
                                    <h3 className="mb-4 text-xl font-medium">Instructions</h3>
                                    <Textarea name="comment" placeholder="Instructions for the client" onChange={onInputChange} />
                                </div>

                                {dataClient && dataClient.addresses && dataClient.addresses.filter(item => item.type ==='contact').length > 0 ?
                                    <div className="mt-8">
                                        <h3 className="mb-4 text-xl font-medium">Send to</h3>
                                        <Select name="selected_contact" required onChange={onInputChange}>
                                            <option value="Select your client contact" disabled>Select your client contact</option>

                                            {dataClient && dataClient.addresses.map((item, index) => {
                                                if(item.type === 'contact') {
                                                    return (
                                                        <option key={index} value={item.id}>{item.firstName} {item.lastName}</option>
                                                    )
                                                }
                                            })}
                                        </Select>
                                    </div>
                                    :
                                    <div className="mt-10 text-center">
                                        <div className="mb-6">
                                            <CollectionIcon className="mx-auto w-16 h-16 text-gray-200" />
                                        </div>
                                        <p>First select a contact for this client</p>
                                    </div>
                                }
                            </>
                            :
                            <div>
                                <div className="mb-6">
                                    <CollectionIcon className="mx-auto w-16 h-16 text-gray-200"/>
                                </div>
                                <p className="mx-auto max-w-lg text-center">Selected voices for this project</p>
                            </div>
                        }
                    </div>
                        <div className="flex px-0 my-8 space-x-4">
                            <Button className="mt-1" type="primary" loading={loadingComment || loadingClientToken} onClick={sendFeedbackRequest}>Send</Button>
                            <Button className="mt-1" type="grayoutline" onClick={() => closeProjectFeedback()}>Cancel</Button>
                        </div>
                    </>
                    : <div className="overflow-hidden space-x-2 p-6 w-full bg-white bg-opacity-80 rounded-lg shadow">
                        <h3 className="mb-4 text-lg font-bold leading-6">Preview</h3>
                        <div className="flex gap-1 grow lg:grow-0">
                            <Button onClick={openPreview}>Open preview</Button>
                            <Button onClick={copyLink}>Copy link <ClipboardCopyIcon className="inline h-4 ml-2 md:h-6"/></Button>
                        </div>
                    </div>
                }
            </div>
        </Stack>
    )
}

export default ProjectFeedback
