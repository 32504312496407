import {Project, serializeProject} from "types/Project";

export interface Stats {
    fullName: string
    openProjects: number
    openOrders: number
    clients: number
    projects: Project[]
}
const statsInit: Stats = {
    fullName: "",
    openProjects: 0,
    openOrders: 0,
    clients: 0,
    projects: []
}

export function serializeStats(data: any): Stats {
    let projects = data.latestOpenProjects ? data.latestOpenProjects : []

    return {
        fullName: data.fullName,
        openProjects: data.counters.openProjects,
        openOrders: data.counters.openOrders,
        clients: data.counters.clients,
        projects: Array.isArray(projects) ? projects.map(project => {
            return serializeProject(project)
        }) : Object.values(projects).map(project => {
            return serializeProject(project)
        })
    }
}
