import React, {useState} from "react";
import {Link} from "react-router-dom";
import useNavigation from "hooks/useNavigation";
import Alert from "components/elements/Alert";
import Button from "components/elements/Button";
import Input from "components/elements/form/Input";
import Panel from "components/elements/Panel";
import {usePostCustomer} from "hooks/api/customers";
import {Customer, customerInit} from "types/Customer";

export default function Register(props: any) {
    const {current} = useNavigation()
    const [showAlert, setShowAlert] = useState<boolean>(false)
    const [newCustomer, setNewCustomer] = useState<Customer>(customerInit)
    const {mutateAsync: postCustomer, isLoading, error} = usePostCustomer();

    const sendRegistration = (e) => {
        e.preventDefault()

        postCustomer(newCustomer)
            .then(resp => {
                setShowAlert(true)
            })
            .catch(error => {
            })
    }

    const onInputChangeCustomer = (e) => {
        setNewCustomer({...newCustomer, [e.target.name]: e.target.value})
    }

    return (
        <div className="mt-2">
            <div className="mb-4 text-center">
                <h1 className="text-2xl font-bold">{current ? current?.name : ''} </h1>
            </div>
            <div className="mb-6 text-center">
                <p>
                    If you're interested in becoming a reseller, please get in touch with us.
                    Provide your information and we'll get back to you promptly.<br/><br/>
                    Please note that our platform is designed specifically for professionals who frequently use
                    voice-overs in their work, such as filmmakers, creative agencies, game designers, and other
                    creative professionals.
                </p>
            </div>
            <Panel className="p-8">
                <Alert show={showAlert} type="success">Thank you for your registration. We will contact you as soon as posible.</Alert>
                <Alert show={!!error} type="danger">{error?.data['hydra:title']}: {error?.data['hydra:description']}</Alert>

                {!showAlert && <form onSubmit={sendRegistration} className="space-y-6">
                    <Input label="Company name" name="company" type="text"
                           placeholder="Type the name of your company" autoComplete="company" required
                           onChange={onInputChangeCustomer} errors={error?.response?.data?.violations}/>
                    <Input label="First name" name="firstName" type="text" placeholder="Type your first name"
                           autoComplete="firstName" required onChange={onInputChangeCustomer}
                           errors={error?.response?.data?.violations}/>
                    <Input label="Last name" name="lastName" type="text" placeholder="Type your last name"
                           autoComplete="lastName" required onChange={onInputChangeCustomer}
                           errors={error?.response?.data?.violations}/>
                    <Input label="Chamber of Commerce number" name="kvkNumber" type="text"
                           placeholder="Type the Chamber of Commerce number" optional={true}
                           autoComplete="kvkNumber" onChange={onInputChangeCustomer}
                           errors={error?.response?.data?.violations}/>
                    <Input label="VAT number" name="vatNumber" type="text" placeholder="Type the VAT number"
                           autoComplete="vatNumber" optional={true} onChange={onInputChangeCustomer}
                           errors={error?.response?.data?.violations}/>
                    <hr/>
                    <Input label="Email address" name="email" type="email" placeholder="Type your email"
                           autoComplete="email" required onChange={onInputChangeCustomer}
                           errors={error?.response?.data?.violations}/>
                    <Input label="Password" name="password" type="password" placeholder="Type a password" required
                           onChange={onInputChangeCustomer} errors={error?.response?.data?.violations}/>
                    <div className="flex items-center gap-2 text-sm">
                        <Input type="checkbox" required={true} className="w-[20px] h-[20px] !p-0 checked:bg-cobalt-500 hover:checked:hover:bg-cobalt-400 active:checked:bg-cobalt-500 focus:checked:bg-cobalt-500"/>
                        <span className="relative top-0.5">I agree to the <a className="link" target="_blank" href={'/assets/documents/algemene-reseller-voorwaarden.pdf'} rel="noreferrer">general reseller terms and conditions</a></span>
                    </div>
                    <Button block={true} submit={true} loading={isLoading}>
                        Send
                    </Button>
                </form>}
                <div className="flex items-center justify-center mt-6">
                    <div className="text-sm">
                        <Link to="/login" className="link">Back to sign in</Link>
                    </div>
                </div>
            </Panel>
        </div>
    )
}
