import request from "services/Request"
import { serializeContact } from "types/Contact"

// export function getContacts() {
// 	return request({
// 		url: `/shop/addresses`,
// 		method: 'GET',
// 	}, null)
// }

export function getContact(id: number) {
	return request({
		url: `/shop/addresses/${id}`,
		method: 'GET'
	}, serializeContact)
}

export function postContact(data) {
	return request({
		url: `/shop/addresses`,
		method: 'POST',
		data
	}, serializeContact)
}

export function putContact(id: number, data) {
	return request({
		url: `/shop/addresses/${id}`,
		method: 'PUT',
		data
	}, serializeContact)
}

// export function patchContact(id: number, data) {
// 	return request({
// 		url: `/shop/addresses/${id}`,
// 		method: 'PATCH',
// 		headers: {
// 			"Content-Type": "application/merge-patch+json"
// 		},
// 		data
// 	}, serializeContact)
// }

export function deleteContact(id: number) {
	return request({
		url: `/shop/addresses/${id}`,
		method: 'DELETE'
	}, serializeContact)
}
