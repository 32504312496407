import { useQuery, useMutation } from 'react-query'
import { useGetResourcesByUrlArray } from './base'
import useQueryClient from '../useQueryClient'
import { getProjects, getProject, getProjectByUrl, getProjectOrders, postProject, putProject } from 'services/requests/projects'
import { Project, ProjectDTO } from 'types/Project'


export function useGetProjects(params) {
	const defaultParams = {
		state: 'open',
		page: 1
	}

	return useQuery(
		['projects', params],
		() => getProjects({ ...defaultParams, ...params }),
		{enabled: params.client !== 0}
	)
}

export const useGetProject = (id: number) => {
	return useQuery(
		['projects', id],
		() => getProject(id),
		{enabled: !!id}
	)
}

export const useGetProjectOrders = (id: number, params) => {
	return useQuery(
		['project-orders', id, params],
		() => getProjectOrders(id, params)
	)
}

export function usePostProject() {
	const queryClient = useQueryClient()
	return useMutation<any, unknown, any>(project => {
		if (project.client === "")
			delete project.client
		return postProject(project)
		}, {
		onSuccess: data => {
			queryClient.invalidateQueries(['stats'])
			queryClient.invalidateQueries({
				predicate: query =>  {
					return query.queryKey[0] === 'projects' && typeof query.queryKey[1] === 'object'
				}
			})
		}
	})
}

export function usePutProject(projectId: number) {
	const queryClient = useQueryClient()
	return useMutation<any, unknown, ProjectDTO>(project => putProject(projectId, project), {
		onSuccess: data => queryClient.invalidateQueries(['projects', projectId.toString()])
	})
}

export function usePutProjectOrders(projectId: number) {
	const queryClient = useQueryClient()
	return useMutation<any, unknown, {orders: string[]}>(projectOrders => putProject(projectId, projectOrders), {
		onSuccess: data => queryClient.invalidateQueries(['projects', projectId.toString()])
	})
}

export function usePutProjectVoices(projectId: number) {
	const queryClient = useQueryClient()
	return useMutation<any, unknown, {voices: string[]}>(project => putProject(projectId, project), {
		onSuccess: data => queryClient.invalidateQueries(['projects', projectId.toString()])
	})
}

export function usePutProjectState(projectId: number) {
	const queryClient = useQueryClient()
	return useMutation<any, unknown, {state: string}>(project => putProject(projectId, project), {
		onSuccess: data => queryClient.invalidateQueries(['projects', projectId.toString()])
	})
}

export function useGetProjectsByUrlArray(projects: string[]) {
	return useGetResourcesByUrlArray<Project>(getProjectByUrl, projects)
}
