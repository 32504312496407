import useNavigation from "hooks/useNavigation"
import { RouteElement } from "Routes"
import ParamLink from "./ParamLink"

export default function Breadcrumb (props:any){

	const { breadcrumb } = useNavigation()
	
	if(breadcrumb.length <= 1) return null

	return (
		<nav className="flex mb-4" aria-label="Breadcrumb">
			<ol className="flex items-center">
				{breadcrumb.map((routeElement: RouteElement, index) => (
					<li key={routeElement.name}>
						<div className="flex items-center">
							{index > 0 &&<svg
								className="flex-shrink-0 w-5 h-5 text-gray-300"
								xmlns="http://www.w3.org/2000/svg"
								fill="currentColor"
								viewBox="0 0 20 20"
								aria-hidden="true"
							>
								<path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
							</svg>
							}
							
							<ParamLink route={routeElement} className="text-xs text-gray-500 transition-colors duration-300 ease-in-out hover:text-gray-700" aria-current={index === breadcrumb.length - 1 ? 'page' : undefined}/>
						</div>
				</li>
				))}
			</ol>
		</nav>
	)


}