import { useEffect, useState } from "react"
import { RouteElement, routes } from "Routes"
import { useLocation} from "react-router-dom"

const useNavigation = (): {
	current: RouteElement | null,
	breadcrumb: RouteElement[],
	isInPath: (element: RouteElement) => boolean,
	isInParentUrl: (element: RouteElement) => boolean,
} => {

	const location = useLocation()


	const [current, setCurrent] = useState<RouteElement | null>(null)
	const [breadcrumb, setBreadcrumb] = useState<RouteElement[]>([])

	

	useEffect(() => {
		const match = find(routes, null, location.pathname)
		setCurrent(match)
		if (match) {
			setBreadcrumb(createBreadcrumb(match))
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location])


	const isInPath = (element: RouteElement): boolean => {
		if (breadcrumb && current) {
			return breadcrumb.filter((e) => {
				return e === element
			}).length > 0
		}
		return false
	}

	const isInParentUrl = (element: RouteElement): boolean => {
		if (current) {
			return current.path.indexOf(element.path) === 0
		}
		return false
	}

	const find = (c: RouteElement[], p: RouteElement | null, path: string): RouteElement | null => {
		for (const route of c) {
			//loopes routes
			route.parent = p

			if (routeHasPath(route, path)) {
				return route
			}
			const match: RouteElement | null = find(route.routes, route, path)
			if (match !== null) {
				return match
			}
		}
		return null
	}

	const createBreadcrumb = (r: RouteElement): RouteElement[] => {
		if (r.parent) {
			return [...createBreadcrumb(r.parent), r]
		}
		return [r]
	}

	const routeHasPath = (r: RouteElement, path: string): boolean => {

		const srcParts = r.path.split('/')
		const cmpParts = path.split('/')

		let match = false

		if (srcParts.length === cmpParts.length) {
			for (let i = 0; i < srcParts.length; i++) {
				const src = srcParts[i]
				const cmp = cmpParts[i]

				if (src === cmp || src.includes(':')) {
					match = true
				} else {
					match = false
					break
				}
			}
		}
		return match
	}

return { current, breadcrumb, isInPath, isInParentUrl }
}

export default useNavigation
