import { useMutation } from 'react-query'
import { postContact, putContact, deleteContact } from 'services/requests/contacts'
import useQueryClient from 'hooks/useQueryClient'
import { ContactDTO } from 'types/Contact'

// export const useGetContact = (id: number ) => {
// 	return useQuery(
// 		['contact', id],
// 		() => getContact(id)
// 	)
// }

export const usePostContact = (clientId: number = 0) => {
	const queryClient = useQueryClient()
	return useMutation<any, unknown, ContactDTO>(contact => postContact(contact), {
		onSuccess: data => {
			if (clientId) return queryClient.invalidateQueries(['clients', clientId.toString()])
		}
	})
}

export const usePutContact = (contactId: number, clientId: number) => {
	const queryClient = useQueryClient()
	return useMutation<any, unknown, ContactDTO>(contact => putContact(contactId, contact), {
		onSuccess: data => queryClient.invalidateQueries(['clients', clientId.toString()])
	})
}

export const useDeleteContact = (contactId: number, clientId: number) => {
	const queryClient = useQueryClient()
	return useMutation(() => deleteContact(contactId), {
		onSuccess: data => queryClient.invalidateQueries(['clients', clientId.toString()])
	})
}
