import React, { useEffect, useState } from 'react'
import useToken from 'hooks/useToken'
import Button from 'components/elements/Button'
import Input from 'components/elements/form/Input'
import Upload from 'components/elements/form/Upload'
import Panel from 'components/elements/Panel'
import LoaderSpinning from 'components/elements/LoaderSpinning'
import Textarea from "components/elements/form/Textarea";
import ColorPicker from "components/elements/form/ColorPicker";
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import { useCustomerDTO, useGetCustomer, usePutCustomer } from 'hooks/api/customers'
import { useGetAddressByCustomerId, usePostAddress, usePutAddress } from 'hooks/api/addresses'
import { useDeleteThemeMedia, usePostThemeMedia, useThemeMedia } from 'hooks/api/themes-media'
import { useDeleteThemeColor, usePostThemeColor } from 'hooks/api/themes-color'
import { useGetTheme, usePutTheme } from 'hooks/api/themes'
import { Customer, customerInit } from 'types/Customer'
import { Address, addressInit } from 'types/Address'
import {getEnv} from "common/EnvUtil";


export default function SettingsCompanyProfile() {
	const {REACT_APP_MAX_UPLOAD_SIZE_THEME} = getEnv();
    const {getLocalCustomerId} = useToken()
    const {t} = useTranslation()
    const themeMedia = useThemeMedia()

    const [customer, setCustomer] = useState<Customer>(customerInit)
    const [editAddress, setEditAddress] = useState<Address>(addressInit)
    const [introduction, setIntroduction] = useState<string>("")
	const [colors, setColors] = useState<Map<string, string>>(new Map())

    const {data: dataCustomer, isLoading: loadingCustomer} = useGetCustomer(getLocalCustomerId())
    const {data: addressData, isLoading: loadingAddress} = useGetAddressByCustomerId(getLocalCustomerId())
    const {data: dataTheme} = useGetTheme(dataCustomer ? dataCustomer.themeId : 0)

    const {mutateAsync: postAddress} = usePostAddress()
    const {mutateAsync: putAddress} = usePutAddress(editAddress?.id as number)
    const {mutateAsync: putTheme, isLoading: loadingThemePut} = usePutTheme(customer.firstName ? customer.themeId : 0)
    const {mutateAsync: postThemeMedia, isLoading: loadingMediaPost} = usePostThemeMedia(dataCustomer ? dataCustomer.themeId : 0)
    const {mutateAsync: postThemeColor} = usePostThemeColor(dataCustomer ? dataCustomer.themeId : 0)
    const {mutateAsync: deleteThemeMedia} = useDeleteThemeMedia()
    const {mutateAsync: deleteThemeColor} = useDeleteThemeColor()
    const {
        mutateAsync: putCustomer,
        isLoading: putCustomerLoading,
        error: putCustomerError
    } = usePutCustomer(getLocalCustomerId())
    const {customerDTO} = useCustomerDTO(customer)

    const onInputChangeCustomer = (e) => {
        setCustomer({...customer, [e.target.name]: e.target.value})
    }

    const onInputChangeAddress = (e) => {
        setEditAddress({...editAddress, [e.target.name]: e.target.value})
    }

    useEffect(() => {
        if (dataCustomer) setCustomer(dataCustomer)
    }, [dataCustomer])

    useEffect(() => {
        if (addressData && addressData.id) setEditAddress(addressData)
    }, [addressData])

    useEffect(() => {
        if (!dataTheme) return
        setIntroduction(dataTheme.feedbackIntroduction)
        dataTheme.colors.forEach(color => colors.set(color.type, color.color) )
        setColors(new Map(colors))
    }, [dataTheme])

    const setColor = (event) => {
        colors.set(event.target.name, event.target.value)
        setColors(new Map(colors))
    }

    const saveCompanyProfile = (e) => {
        e.preventDefault()
        putCustomer(customerDTO)
            .then(resp => {
                if (!editAddress.id) {
                    postAddress({
                        city: editAddress.city,
                        countryCode: editAddress.countryCode,
                        postcode: editAddress.postcode,
                        street: editAddress.street,
                        firstName: editAddress.firstName,
                        lastName: editAddress.lastName,
                        type: editAddress.type,
                        customer: resp.idPath
                    }).then(resp => {
                        toast.success(t('settings.account.profile.success'))
                    })
                } else {
                    putAddress(editAddress).then(resp => {
                        toast.success(t('settings.account.profile.success'))
                    })
                }
            })
            .catch(() => {
                toast.error(t('settings.account.profile.error'))
            })
    }

    const submitFile = (file: string, type: string, theme: string) => {
        const formData = new FormData()
        formData.append('file', file)
        formData.append('type', type)
        formData.append('owner', theme ? theme : '')

        // Post new logo
        return postThemeMedia(formData)
    }

    const updateThemeColor = (data) => {
        let currentColor = dataTheme.colors.find(color => color.type === data.type)
        let deletePromise;
        if (currentColor) {
            deletePromise = deleteThemeColor(currentColor.id)
        }
        return Promise.all([postThemeColor({ ...data, color: colors.get(data.type) }), deletePromise])
    }

    const handleSubmitFile = async (e) => {
        e.preventDefault()
        const files = [...e.target].filter(item => item.files && item.files[0])
        return putTheme({ feedbackIntroduction: introduction }).then(theme => {
            return Promise.all([
                ...files.map(item => {
                    const file = item.files[0]
                    if (file.size > REACT_APP_MAX_UPLOAD_SIZE_THEME) {
                        toast.error(t('File size too large'))
                        return Promise.reject()
                    }
                    const type = file.type.split('/')[0] === 'video' ? 'video_' + item.name : item.name
                    const { data: img } = themeMedia(type)
                    // Delete existing image
                    if (img.id !== 0) {
                        deleteThemeMedia(img.id)
                    }
                    return submitFile(file, type, theme.idPath)
                }),
                ...Array.from(colors).map(([type]) =>
                    updateThemeColor({ type, theme: theme.idPath })
                )
            ])
        }).then(res => {
            toast.success(t('Your company appearance has been saved'))
        }).catch(err => {
            toast.error(t('Your company appearance has not been saved'))
        })
    }

    return (
        <div className="flex flex-wrap -m-4">
            <div className="w-full p-4 lg:w-1/2">
                <Panel>
                    <h3 className="text-lg font-bold leading-6 mb-4">Company profile</h3>
                    {(loadingCustomer || loadingAddress) &&
                        <LoaderSpinning className="pt-8">Loading company profile...</LoaderSpinning>}
                    {!loadingAddress && dataCustomer && <form onSubmit={saveCompanyProfile} className="space-y-6">
                        <Input label="Company name" name="company" type="text" placeholder="Type the name of your company" value={customer.company} onChange={onInputChangeCustomer} errors={putCustomerError?.response?.data?.violations}/>
                        <Input label="Address" name="street" type="text" placeholder="Type the address of the company" value={editAddress?.street} onChange={onInputChangeAddress} errors={putCustomerError?.response?.data?.violations}/>
                        <Input label="Zipcode" name="postcode" type="text" placeholder="Type the zipcode of the company" value={editAddress?.postcode} onChange={onInputChangeAddress} errors={putCustomerError?.response?.data?.violations}/>
                        <Input label="City" name="city" type="text" placeholder="Type the city of the company" value={editAddress?.city} onChange={onInputChangeAddress} errors={putCustomerError?.response?.data?.violations}/>
                        <Input label="KVK number" name="kvkNumber" type="text" placeholder="Type the KVK number of your company" value={customer.kvkNumber} onChange={onInputChangeCustomer} errors={putCustomerError?.response?.data?.violations}/>
                        <Input label="VAT number" name="vatNumber" type="text" placeholder="Type the VAT number of your company" value={customer.vatNumber} onChange={onInputChangeCustomer} errors={putCustomerError?.response?.data?.violations}/>
                        <Button block={false} submit={true} loading={putCustomerLoading}>Save company profile</Button>
                    </form>}
                </Panel>
            </div>

            <div className="w-full p-4 lg:w-1/2">
                <Panel>
                    <h3 className="text-lg font-bold leading-6 mb-4">Company appearance</h3>
                    <form onSubmit={handleSubmitFile} className="space-y-6">
                        <Textarea label="Introduction text" placeholder="This introduction text is used when requesting feedback to a client" value={introduction} onChange={(e) => setIntroduction(e.target.value)} />
                        <ColorPicker label="Background color" name="background-color" value={colors.get('background-color') ?? "#ffffff"} onChange={setColor}/>
                        <ColorPicker label="Text color" name="text-color" value={colors.get('text-color') ?? "#000000"} onChange={setColor}/>
                        <ColorPicker label="Button color" name="button-color" value={colors.get('button-color') ?? "#28348a"} onChange={setColor}/>
                        <ColorPicker label="Button text color" name="button-text-color" value={colors.get('button-text-color') ?? "#000000"} onChange={setColor}/>
                        <Upload
                            label="Upload company logo"
                            description="Images of type jpg, png, and gif with a maximum size of 5MB are supported."
                            name="logo"
                            accept="image/jpeg,image/png,image/gif"
                            initialImgFileUrl={themeMedia(['logo']).data.path} />
                        <Upload
                            label="Upload company background"
                            description="
                                Images of type jpg, png, and gif with a maximum size of 5MB are supported.
                                Videos of type mp4, webm, and mov with a maximum size of 50MB are supported."
                            name="background"
                            accept="image/jpeg,image/png,image/gif,video/mp4,video/ogg,video/webm,video/quicktime"
                            initialMediaIsVideo={themeMedia(['background', 'video_background']).data.type === 'video_background'}
                            initialImgFileUrl={themeMedia(['background', 'video_background']).data.path} />
                        <Button block={false} loading={loadingMediaPost || loadingThemePut} submit={true}>Save company appearance</Button>
                    </form>
                </Panel>
            </div>
        </div>
    )
}
