import { serializeTheme, Theme } from "types/Theme";

export interface Project {
	id?: number
	idPath?: string
	name: string
	client: {idPath: string, name?: string}
	state: string,
	description: string
	cart?: {token: string, idPath: string}
	createdAt?: string
	updatedAt?: string
	voices: string[]
	orders: string[]
	comments: string[]
	theme?: Theme
}

export interface ProjectDTO {
	id?: number
	name: string
	reseller?: string
	client: string
	description: string
	state: string
	createdAt?: string
	updatedAt?: string
	voices: string[]
	comments: string[]
}

export const projectInit: Project = {
	name: '',
	client: {idPath: "", name: '-'},
	state: 'open',
	description: '',
	voices: [],
	orders: [],
	comments: [],
}

export const projectDTOInit =  {
	name: '',
	reseller: '',
	client: '',
	description:'',
	state: 'open',
	createdAt: '',
	updatedAt: '',
	voices: [],
	comments: []
}

export function serializeProject(data: any): Project {
	return {
		id: data['@id'] ? +data['@id'].replace('/api/v2/shop/projects/', '') : data.id ? data.id : 0,
		name: data.name,
		client: data.client ? {
			idPath: data.client['@id'],
			name: data.client.company
		} : {
			idPath: '',
			name: ''
		},
		state: data.state,
		description: data.description,
		cart: data.cart && data.cart['@id'] ? {
			token: data.cart['@id'].replace('/api/v2/shop/orders/', ''),
			idPath: data.cart['@id']
		} : data.orders.length === 0 ? undefined : {
			token: data.orders[data.orders.length - 1].replace('/api/v2/shop/orders/', ''),
			idPath: data.orders[data.orders.length - 1]
		},
		voices: data.voices,
		orders: data.orders,
		createdAt: data.createdAt,
		updatedAt: data.updatedAt,
		comments: data.comments,
		theme: serializeTheme(data.theme)
	}
}

export function serializeProjects(data: any): {items: Project[], totalItems: number } {
	return {
		items: data['hydra:member'].map(item => serializeProject(item)),
		totalItems: data['hydra:totalItems']
	}
}
