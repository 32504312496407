import React, { useEffect } from 'react';
import { RouteElement } from 'Routes';
import {
	Link,
	useRouteMatch,
	useHistory,
	useLocation
} from 'react-router-dom';
import useStyling from 'hooks/useStyling';
import useNavigation from 'hooks/useNavigation';


const TabBar: React.FC<
	{
		routes: RouteElement[]
		data?: any
	}
> = ( props => {

	let { url, path } = useRouteMatch();
	const { switchClassNames } = useStyling()
	const { current } = useNavigation()
	const history = useHistory();
	const location = useLocation()

	const changeTab = ((e)=>{
		history.push(e.target.value);
	})


	const getRemainingPath = ((routePath) => {
		return routePath.replace(path, '')
	})

	const isCurrentPath = (item) =>{
		//return location?.pathname + location?.search === item.path || current?.path + location?.search === item.path || current?.path.includes(item.path)
		return location?.pathname + location?.search === item.path || current?.path + location?.search === item.path
	}
	
	useEffect(() => {
		if (current?.redirect){
			history.push(`${url}${getRemainingPath(current.redirect)}` )
		
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [current])


	return (
		<>
		{props.routes && 
			<div>	
				<div className="mb-8 sm:hidden">
					<label htmlFor="tabs" className="sr-only">
						Select a tab
					</label>
					<select
						id="tabs"
						name="tabs"
						className="block w-full py-2 pl-3 pr-10 text-base border-gray-300 rounded-md focus:outline-none focus:ring-cobalt-500 focus:border-cobalt-500 sm:text-sm"
						defaultValue={`${url}${current?.link}`}
						onChange={changeTab}
					>
						{props.routes.map((item) => (
							<option key={item.name} value={`${url}${getRemainingPath(item.path)}`}>{item.name}</option>
						))}
					</select>
				</div>

				<div className="hidden sm:block">
					<div className="mb-8 border-b-2 border-blacks-50">
						<nav className="flex -mb-px space-x-8" aria-label="Tabs">
							
							{props.routes.map((item) => (
								<Link
									key={item.name}
									to={`${url}${getRemainingPath(item.path)}`}
									className={switchClassNames(
										isCurrentPath(item)
											? 'border-cobalt-500 text-cobalt-500'
											: 'border-transparent  hover:text-gray-700 hover:border-blacks-300',
										'whitespace-nowrap py-4 px-1 border-b-2 font-normal text-sm'
									)}
									aria-current={current === item ? 'page' : undefined}
								>
									{item.name}
								</Link>
							))}
						</nav>
					</div>
				</div>
				
			</div>

			
		}
		</>
	)
})

export default TabBar