import useToken from "./useToken";
import {useGetCustomerByUrl} from "./api/customers";

const useCurrentUserRole = () => {
	const { getUser } = useToken();
	const { data: customer } = useGetCustomerByUrl(getUser() ? getUser().customer : "");

	if (customer && customer.reseller)
		return 'client';
	else if (customer && !customer.reseller)
		return 'reseller';
	else
		return '';
}

export { useCurrentUserRole };
