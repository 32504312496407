import React, { useState } from "react";
import Button from "components/elements/Button";
import Input from "components/elements/form/Input";
import Select from "components/elements/form/Select";
import Stack from "components/elements/stack/Stack";
import { toast } from "react-toastify";
import { useGetCountries } from "hooks/api/countries";
import { useClientDTO, usePutClient } from "hooks/api/clients";
import { usePostAddress, usePutAddress } from "hooks/api/addresses";
import { Client } from "types/Client";
import { Address } from "types/Address";

export interface ClientEditProps {
	show: boolean
	client: Client
	onClose: () => void
}

const ClientEdit: React.FC<ClientEditProps> = ({
	show,
	client,
	onClose
}) => {

	const [editClient, setEditClient] = useState<Client>(client as Client)
	const [editAddress, setEditAddress] = useState<Address>(client.defaultAddress as Address)

	const { data: dataCountries } = useGetCountries()
	const { mutateAsync: putClient, isLoading: loading, error } = usePutClient(client.id)
	const { mutateAsync: postAddress, isLoading: loadingPostAddress } = usePostAddress()
	const { mutateAsync: putAddress, isLoading: loadingAddress } = usePutAddress(client?.defaultAddress?.id as number)

	const { clientDTO } = useClientDTO( editClient )
		
	const onInputChangeCompany = (e) => {
		setEditClient( { ...editClient, [e.target.name]: e.target.value })
	}

	const onInputChangeAddress = ( e ) => {
		setEditAddress( { ...editAddress, [e.target.name]: e.target.value } )
	}

	const saveClient = (e) => {
		e.preventDefault()
		putClient(clientDTO)
		.then(resp => {
			if(editAddress.id === 0) {
				let postAddressPromise: Promise<any> = Promise.resolve();
				if (!(editAddress.city == "" && editAddress.postcode == "" && editAddress.street == "")) {
					postAddressPromise = postAddress({
						city: editAddress.city,
						countryCode: editAddress.countryCode,
						postcode: editAddress.postcode,
						street: editAddress.street,
						firstName: editAddress.firstName,
						lastName: editAddress.lastName,
						type: editAddress.type,
						customer: resp.idPath
					})
				}
				postAddressPromise.then(() => {
					toast.success( 'Client succesfully saved' );
					onClose()
				}).catch(e => {
					toast.error( `There was a problem creating the client ${e.data['hydra:description']}` );
				})
			} else {
				putAddress( editAddress ).then(() => {
					toast.success( 'Client succesfully saved' );
					onClose()
				}).catch(e => {
					toast.error( `There was a problem creating the client ${e.data['hydra:description']}` );
				})
			}
		}).catch(e =>{
			toast.error( 'There was a problem saving the client' );
		})
	}

	return (
		<>
			<Stack isShow={show} onClose={() => onClose()} title="Edit client">
				<div className="px-8 mt-12">
					<h3 className="text-xl font-bold">Client</h3>
					<p className="text-sm">Edit the client details:</p>
				</div>
				<form onSubmit={saveClient}>
					<div className="px-8 mt-4 space-y-4">
						<Input label="Name of the company" name="company" type="text" placeholder="Type the company name" value={editClient.company} onChange={onInputChangeCompany} required />
						<Input label="Company email" name="email" type="text" placeholder="Type the email address of the company" value={editClient.email} onChange={onInputChangeCompany} required />
						<Input label="Address" name="street" type="text" placeholder="Type the address of the company" value={editAddress?.street} onChange={onInputChangeAddress} optional={true} />
						<Input label="Zipcode" name="postcode" type="text" placeholder="Type the zipcode of the company" value={editAddress?.postcode} onChange={onInputChangeAddress} optional={true} />
						<Input label="City" name="city" type="text" placeholder="Type the city of the company" value={editAddress?.city} onChange={onInputChangeAddress} optional={true} />
						{<Select label="Select a country" name="countryCode" value={editAddress.countryCode} placeholder="Select a country" onChange={onInputChangeAddress} optional={true}>
							{dataCountries && dataCountries.items.map( ( item ) => (
								<option key={item.code} value={item.code}>{item.name}</option>
							) )}
						</Select>}
						<Input label="Chamber of Commerce number" name="kvkNumber" type="text" placeholder="Type the KVK number of the company" value={editClient.kvkNumber} onChange={onInputChangeCompany} optional={true} errors={error?.response?.data?.violations} />
						<Input label="VAT number" name="vatNumber" type="text" placeholder="Type the VAT number of the company" value={editClient.vatNumber} onChange={onInputChangeCompany} optional={true} errors={error?.response?.data?.violations} />
					</div>
				

					<div className="flex px-8 my-12 space-x-4">
						<Button type="primary" submit={true} loading={loading || loadingAddress || loadingPostAddress}>Save client</Button>
						<Button type="grayoutline" onClick={() => onClose()}>Cancel</Button>
					</div>
				</form>
			</Stack>
		</>
	)
}

export default ClientEdit