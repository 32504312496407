import React from 'react'
import { CollectionIcon } from '@heroicons/react/outline'

export interface EmptyProps extends React.HTMLAttributes < HTMLElement > {}

const Empty: React.FC<EmptyProps> = ( {children, className= ''} ) => (
	<div className={`${className}`}>
		<div className="mb-6">
			<CollectionIcon className="w-16 h-16 mx-auto text-gray-200" />
		</div>
		<p className="max-w-lg mx-auto text-center">{children}</p>
	</div>
)

export default Empty