import { useState, useEffect } from 'react'
import Select from 'components/elements/form/Select'
import LoaderSpinning from 'components/elements/LoaderSpinning'
import { useGetProductVariantByUrl } from 'hooks/api/products'
import { useUpdateOrderItemVoice } from 'hooks/api/order-items'

export default function OrderItem({
    order,
    product,
    voices,
    editable
}) {
    const [selectedVoice, setSelectedVoice] = useState('')
    const [instructionId, setInstructionId] = useState(0)
    const {mutateAsync: updateVoice, data, isLoading: loading} = useUpdateOrderItemVoice(order)
    const {data: dataVoiceVariant} = useGetProductVariantByUrl(
        data ? data.voices[0] : product && product.voice.replace('products', 'product-variants')
    )

    useEffect(() => {
        setSelectedVoice(product.voice)
        const result = product.instructions.find(item => item.instruction === product.voice)
        if (result) setInstructionId(result.id)
    }, [product.voice])

    const voiceChangeHandler = (e) => {
        const previousVoice = selectedVoice
        const voice = e.target.value
        setSelectedVoice(voice)
        updateVoice({voice, previousVoice, instructionId})
    }

    return (
        <>
            {voices && ((!editable && dataVoiceVariant) || editable) && voices.length !== 0 && (
                <>
                { loading ? <LoaderSpinning/> : <>
                    <div className="col-span-2 items-center text-sm font-medium text-gray-500 lg:pl-5 lg:col-span-3 xl:col-span-2">Voice:</div>
                    <div className="col-span-3 lg:pl-10 lg:col-span-6 xl:col-span-7">
                        { editable && (
                            <Select name="voice" className="relative -top-3 w-full" value={selectedVoice ? selectedVoice : ''}  placeholder="Select voice" onChange={voiceChangeHandler}>
                                <option value="" disabled={true}>Select voice</option>
                                {voices && voices.map((item) => {
                                    return ( <option key={item?.id} value={item.idPath} >{item.name}</option>)
                                })}
                            </Select>
                        )}
                        { !editable && (
                            <p className="pr-5">{dataVoiceVariant && dataVoiceVariant.name ? dataVoiceVariant.name : ''}</p>
                        )}
                    </div>
                    <div className="text-xs text-right lg:text-lg sm:col-span-1 lg:col-start-10 xl:col-start-12 lg:col-span-1">
                        {new Intl.NumberFormat( 'nl-NL', { style: 'currency', currency: 'EUR' } ).format( dataVoiceVariant ? dataVoiceVariant.price / 100 : 0 )}
                    </div>
                </>}
            </>)}
        </>
    )
}