import React from 'react'

export interface PanelProps extends React.HTMLAttributes<HTMLElement> {
	hoverType?: 'none' | 'default';
}

const hoverStyle = {
	none: '',
	default: 'hover:bg-cobalt-400 hover:shadow-xs',
}

const Panel: React.FC<PanelProps> = ({
	hoverType = 'none',
	className= '',
	children
}) => (
	<div className={`${className} w-full px-8 py-8 overflow-hidden mb-4 bg-white rounded-lg shadow transition-all duration-300 ${hoverStyle[hoverType]}`}>
		{children}
	</div>
)

export default Panel
