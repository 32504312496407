import { ThemeMedia, themeMediaInit, serializeThemeMedia } from "types/ThemesMedia"
import {serializeThemeColor, ThemeColor} from "./ThemesColor";

export interface Theme {
    idPath: string
    id: number
    customer: string
    feedbackIntroduction: string
    media: ThemeMedia[]
	colors: ThemeColor[]
}

export const themeInit: Theme = {
    idPath: '',
    id: 0,
    customer: '',
    feedbackIntroduction: '',
    media: [],
	colors: []
}

export function serializeTheme( data ): Theme {
    if ( !data ) return themeInit;

    return {
        id: data.id ? data.id : parseInt(data['@id'].replace( '/api/v2/shop/themes/', '' )),
        idPath: data['@id'],
        customer: data.customer,
        feedbackIntroduction: data.feedbackIntroduction,
        media: data.media.map(item => typeof(item) === 'string' ? themeMediaInit : serializeThemeMedia(item)),
	    colors: data.colors.map(item => typeof(item) === 'string' ? themeMediaInit : serializeThemeColor(item)),
    }
}
