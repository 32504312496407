import React, { useState, useEffect } from 'react'
import Panel from 'components/elements/Panel'
import { ClockIcon } from "@heroicons/react/outline";
import { dateFromString, dateToStringWithTime } from "common/DateUtils";

export default function NotificationItem({notification}) {
    const [isNew, setIsNew] = useState<boolean>(!notification.read)

    useEffect(() => {
        setIsNew(!notification.read)
    }, [notification.read])

    const goToProject = () => {
        return notification.onClick()
    }

    return (
        <Panel className={`relative max-w-3xl w-full m-auto ${isNew ? 'border-l-4 border-cobalt-500 relative max-w-3xl w-full' : ''}`}>
            <div className="flex grid grid-cols-1 justify-between items-center">
                <div className="mb-4">
                    {isNew && <span className="inline relative -top-px px-2 py-1 mr-3 text-xs font-bold text-white uppercase rounded-full bg-cobalt-500">New</span>}
                    <div className="inline text-sm font-bold text-gray-900">{notification.title}</div>
                </div>
                <p className="block text-sm text-gray-900">{notification.message}</p>
                <div className="flex justify-between items-end">
                    <button className="mt-6 text-sm text-left underline text-cobalt-500 hover:no-underline hover:cursor-pointer shrink-0" onClick={goToProject}>{notification.linkText}</button>
                    <span className="inline relative -top-px text-xs text-right text-gray-400">
                        <ClockIcon className="inline relative top-px mr-1 w-4 h-4 text-xs" aria-hidden="true" />
                        <span className="relative top-0.5">{dateToStringWithTime( dateFromString( notification.createdAt ) )}</span>
                    </span>
                </div>
            </div>
        </Panel>
    )
}