import React, {useEffect, useState} from 'react'
import { useLocation } from 'react-router-dom'
import Button from 'components/elements/Button'
import Textarea from 'components/elements/form/Textarea'
import Panel from 'components/elements/Panel'
import VoicesGrid from 'components/elements/VoicesGrid'
import Select from 'components/elements/form/Select'
import LoaderSpinning from 'components/elements/LoaderSpinning'
import useToken from 'hooks/useToken'
import { useTokenRefresh } from 'hooks/api/auth'
import { useGetProject} from 'hooks/api/projects'
import { useGetComment, usePostComment } from 'hooks/api/comments'
import { useGetVoicesByUrlArray } from 'hooks/api/voices'
import { Comment, commentInit } from 'types/Comment'
import Empty from "components/elements/Empty";
import { useThemeColor } from "hooks/api/themes-color";


const Feedback: React.FC = ( ) => {
	const location = useLocation()
	const link = new URLSearchParams(location.search).get('link')
	const themeColor = useThemeColor()
	const { data: buttonColor } = themeColor("button-color")
	const { data: buttonTextColor } = themeColor("button-text-color")
	const { data: textColor } = themeColor("text-color")

	const [commentId, setCommentId] = useState<number>(0)
	const [projectId, setProjectId] = useState<number>(0)
	const [newComment, setNewComment] = useState<Comment>(commentInit)
	const [voices, setSelectedVoices] = useState<string>('')
	const [selectedVoice, setSelectedVoice] = useState<string>('')
	const [showAlert, setShowAlert] = useState<boolean>(false)
	const [error, setError] = useState<string>('')
	const [client, setClient] = useState<string>('')

	const { data: dataComment, isLoading: loadingComment } = useGetComment(commentId, !!client)
	const { data: dataProject, isLoading: loadingProject } = useGetProject(projectId)
	const { data: dataVoices, isLoading: loadingVoices } = useGetVoicesByUrlArray(client && voices)
	const { mutateAsync: postComment, isLoading: loadingCommentPost} = usePostComment( )
	const { mutateAsync: postTokenRefresh } = useTokenRefresh()
	const { updateLocalAccessToken, getUser, setUser} = useToken()

	const setErrorInvalid = () => {
		setError("This link is invalid")
	}

	useEffect(() => {
		if (!link) return setErrorInvalid()
		try {
			const decoded = JSON.parse(atob(decodeURI(link)))
			if (!decoded.data && !decoded.data.refreshToken) return setErrorInvalid
			const idPath = decoded.data.id.replace("\\", "")
			const id = parseInt(idPath.replace("/api/v2/shop/project-comment/", ""))
			setCommentId(id)

			if (!decoded.data.refreshToken) return setErrorInvalid()
			const user = getUser()
			if (user && user.customer) return setClient(user.customer)
			setUser({token: "", refreshToken: decoded.data.refreshToken})
			if (user && user.customer) return setClient(user.customer)
			postTokenRefresh({refreshToken: decoded.data.refreshToken}).then(data => {
				updateLocalAccessToken(data)
				setClient(getUser()?.customer)
			}).catch(() => setError("This link has expired"))
		} catch {
			setErrorInvalid()
		}
	}, [link] )

	useEffect(() => {
		if (!dataComment) return
		setProjectId(dataComment.project.id)
	}, [dataComment])

	useEffect( () => {
		if (dataProject){
			setSelectedVoices(dataProject.voices)
		}
	}, [dataProject] )

	const onInputChangeFeedback = ( e ) => {
		setNewComment({
			...newComment, [e.target.name]: e.target.value }
		)
	}

	const sendFeedback = (e) => {
		e.preventDefault()

		return postComment( {
				...newComment,
				customer: client,
				project: `/api/v2/shop/projects/${projectId}`,
				parent: `/api/v2/shop/project-comments/${commentId}`,
				payload: {
					voices: [selectedVoice]
				}
			}
		).then( resp => {
			setShowAlert(true)
		})
	}

	const selectVoice = (e) => {
		setSelectedVoice(e.target.value)
	}

	return (
		 <div className="mt-2">
			 {!showAlert ?
				 <>
					 <div className="mb-4 text-center">
						 <h1 className="text-2xl font-bold">Listen to our voice suggestions</h1>
					 </div>
					 { error ? <Empty>{error}</Empty> : dataProject && !loadingVoices && !loadingComment && !loadingProject ?
						 <>
							 {dataProject.theme && dataProject.theme.feedbackIntroduction &&
								 <div className={`mb-6 ${dataProject.theme.feedbackIntroduction > 150 ? 'text-justify' : 'text-left'}`}>
									 <p style={{color: textColor.color}}>{dataProject.theme.feedbackIntroduction}</p>
								 </div>
							 }
							 {dataComment &&
								 <div className={`mb-6 ${dataComment.comment.length > 150 ? 'text-justify' : 'text-left'}`}>
									<p style={{color: textColor.color}}>{dataComment.comment}</p>
								 </div>
							 }
							 <Panel>
								 <VoicesGrid voices={dataProject.voices} grid='md'/>
							 </Panel>
							 <Panel className="p-8">
								 <form onSubmit={sendFeedback} className="space-y-6">
									 {dataProject &&
										 <Select label="Select the voice that you would like" name="selected_voice" value={selectedVoice} required onChange={selectVoice}>
											 <option value={""} disabled={true}>Select a voice</option>
											 {dataVoices && dataVoices.map( ( item, index ) => (
												 <option key={index}>{item.name}</option>
											 ))}
										 </Select>
									 }
									 <Textarea label="Your feedback" name="comment" placeholder="Add additional feedback" required onChange={onInputChangeFeedback}/>
									 <Button block={true} loading={loadingCommentPost} submit={true} style={{backgroundColor: buttonColor.color, borderColor: buttonColor.color, color: buttonTextColor.color}}>Send feedback</Button>
								 </form>
							 </Panel>
						 </>
						 : <LoaderSpinning color={textColor.color} />
					 }
				 </> :
				 <h1 className="text-xl font-bold mt-2 mb-0 text-center">Your feedback has been sent.</h1>
			 }
		</div>
	)
}

export default Feedback
