import React from 'react'
import { Link, useRouteMatch } from 'react-router-dom'
import { RouteElement } from 'Routes'


export interface ParamLinkProps extends React.HTMLAttributes<HTMLElement> {
	route: RouteElement
}

const ParamLink: React.FC<ParamLinkProps> = ({ 
		route,
		className,
		...restProps
	}: ParamLinkProps) => {

	let match = useRouteMatch({path:route?.path, strict:true})

	return (
		<>
		{ match?.url && <Link
			to={match.url}
			className={className}
			{...restProps}
			>
				{route.name}
			 </Link>
		}
		</>
	)
}

export default ParamLink