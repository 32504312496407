import { useQuery } from "react-query"
import { getProducts, getProductVariantByUrl } from 'services/requests/products'
import { useGetResourcesByUrlArray } from './base'
import { ProductVariant } from "types/ProductVariant";


export const useGetProducts = ( params ) => {
	const defaultParams = {
		page: 1,
		itemsPerPage: 30
	}

	return useQuery(
		['products', {...defaultParams, ...params}],
		() => getProducts({...defaultParams, params})
	)
}

export function useGetProductVariantsByUrlArray(variants: string[]) {
	return useGetResourcesByUrlArray<ProductVariant>(getProductVariantByUrl, variants)
}


export const useGetProductVariantByUrl = (url: string) => {
	return useQuery(
		['byUrl', url],
		() => getProductVariantByUrl(url),
		{enabled: !!url}
	)
}
