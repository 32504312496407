import request from "services/Request"


export function login(data) {
	return request({
		url: `/shop/authentication-token`,
		method: 'POST',
		data
	}, data => data)
}

export function clientToken(id: number) {
	return request({
		url: `/shop/clients/refresh-token`,
		method: 'POST',
		data: { clientId: id }
	}, data => data)
}

export function refreshToken(data) {
	return request({
		url: `/shop/authentication-token/refresh`,
		method: 'POST',
		data
	}, data => data)
}

export function requestPasswordReset(data) {
	return request({
		url: `/shop/reset-password-requests`,
		method: 'POST',
		data
	}, data => data)
}

export function passwordReset(token: string, data) {
	return request({
		url: `/shop/reset-password-requests/${token}`,
		method: 'PATCH',
		headers: { 'content-type': 'application/merge-patch+json' },
		data
	}, data => data)
}
