import useNavigation from 'hooks/useNavigation'
import ErrorLayout from './ErrorLayout'
import AuthLayout from './AuthLayout'
import AppLayout from './AppLayout'

export default function Layout(props: any) {
	const { current } = useNavigation()

	if (!current) {
		return <ErrorLayout />
	}


	switch (current.layout) {
		case 'AuthLayout':
			return (
				<AuthLayout >
					{props.children}
				</AuthLayout>
			)
		case 'AppLayout':
			return (
				<AppLayout >
					{props.children}
				</AppLayout>
			)
		default:
			return <>
				{props.children}
			</>
	}
}