import React from 'react'
import { dateFromString, dateToStringWithTime } from "common/DateUtils"
import useStyling from "hooks/useStyling"
import { Project } from "types/Project"

const ProjectRow: React.FC<{project:Project, index: number, onClick: (id:any) => void}> = ( props => {

	const { switchClassNames } = useStyling()

	return (
		<tr key={props.project.id} className={switchClassNames( props.index % 2 === 0 ? 'bg-transparent' : 'bg-transparent', 'hover:bg-blacks-50 hover:text-cobalt-500 hover:bg-opacity-5 cursor-pointer transition-colors duration-150' )} onClick={() => props.onClick( props.project.id )}>
			<td className="px-6 py-6 text-sm whitespace-nowrap text-cobalt-500 lg:w-96">{props.project.name}</td>
			<td className="px-6 py-6 text-sm whitespace-nowrap">{props.project.client.name}</td>
			<td className="px-6 py-6 text-sm capitalize whitespace-nowrap capitalize">{props.project.state}</td>
			<td className="hidden px-6 py-6 text-sm whitespace-nowrap md:table-cell">{props.project.createdAt && dateToStringWithTime( dateFromString( props.project.createdAt ) )}</td>
			<td className="hidden px-6 py-6 text-sm whitespace-nowrap md:table-cell">{props.project.updatedAt && dateToStringWithTime( dateFromString( props.project.updatedAt ) )}</td>
		</tr>
	)
})

export default ProjectRow