import React, { useState } from "react";
import { useParams } from "react-router-dom"
import Button from "components/elements/Button";
import Input from "components/elements/form/Input";
import Stack from "components/elements/stack/Stack";
import { toast } from "react-toastify";
import { usePutContact } from "hooks/api/contacts";
import { Contact } from "types/Contact";


const ContactEdit: React.FC<{
	show: boolean
	contact: Contact
	onClose: () => void
}> = ( props ) => {

	const { clientId } = useParams()

	const [newContact, setNewContact] = useState<Contact>( props.contact )

	const { mutateAsync: putContact, isLoading } = usePutContact( props.contact.id ? props.contact.id : 0, clientId )

	const onInputChangeContact = ( e ) => {
		setNewContact( { ...newContact, [e.target.name]: e.target.value } )
	}

	const saveContact = (e) => {
		e.preventDefault()
		putContact( newContact )
		.then(() => {
			toast.success('Contact successfully saved')
			closeStack()
		})
	}

	const closeStack = () => {
		if(props.onClose) props.onClose()
	}

	return (
		<>
			<Stack isShow={props.show} onClose={() => closeStack()} title="Edit contact">
				<div className="px-8 py-4 mt-4">
					<h3 className="text-xl font-medium">Contact details</h3>
					<p className="text-sm">Enter the Contact details that you wish to add:</p>
				</div>
				<form onSubmit={saveContact}>
					<div className="px-8 py-6 space-y-4">
						<Input label="First name" name="firstName" type="text" placeholder="Type the contact firstname like 'John'" value={newContact.firstName} onChange={onInputChangeContact} required />
						<Input label="Last name" name="lastName" type="text" placeholder="Type the contact lastname like 'Smith'" value={newContact.lastName} onChange={onInputChangeContact} required />
						<Input label="Email" name="email" type="text" placeholder="Type the email address of the contact like 'john@company.com'" value={newContact.email} onChange={onInputChangeContact} required />
						<Input label="Phone number" name="phoneNumber" type="text" placeholder="Type the phone number like '+31612345678' or '0612345678'" value={newContact.phoneNumber} onChange={onInputChangeContact} optional={true} />

					</div>
					<div className="flex px-8 py-4 my-4 space-x-4">
						<Button type="primary" submit={true} loading={isLoading}>Save Contact</Button>
						<Button type="grayoutline" onClick={() => closeStack()}>Cancel</Button>
					</div>
				</form>
			</Stack>
		</>
	)
}

export default ContactEdit