import request from "services/Request"
import { serializeProducts, serializeProduct } from "types/Product"
import { serializeProductVariant, serializeProductVariants } from "types/ProductVariant"

export function getProducts(params) {
	return request({
		url: `/shop/products`,
		method: 'GET',
		params
	}, serializeProducts)
}

export function getProductByUrl(url: string) {
	return request({
		url: url.replace('/api/v2', ''),
		method: 'GET'
	}, serializeProduct)
}

export function getProductVariants(params) {
	return request({
		url: `/shop/product-variants`,
		method: 'GET',
		params
	}, serializeProductVariants)
}

export function getProductVariantByUrl(url: string) {
	return request({
		url: url.replace('/api/v2', ''),
		method: 'GET'
	}, serializeProductVariant)
}