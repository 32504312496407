import React, {useEffect} from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router, useLocation} from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import './assets/css/App.scss';
import './assets/css/tailwind.css';
import './i18n';
import App from './App';

import {QueryClientProvider} from 'react-query'
import {queryClient} from 'services/QueryClient'

const Wrapper = ({children}) => {
    const location = useLocation();

    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'auto'});
    }, [location.pathname]);

    return children;
};


ReactDOM.render(
    <React.StrictMode>
        <Router>
            <Wrapper>
                <QueryClientProvider client={queryClient}>
                    <App/>
                </QueryClientProvider>
            </Wrapper>
        </Router>
    </React.StrictMode>,
    document.getElementById('root')
);