import { Address } from "./Address";

export interface Contact extends Address {
	idPath: string
}

export interface ContactDTO {
	customer?: string
	firstName: string
	lastName: string
	phoneNumber?: string
	company?: string
	countryCode: string
	provinceCode?: string
	provinceName?: string
	street: string
	city: string
	postcode: string
	email?: string
	type: string
}

export const contactInit = {
	id: 0,
	idPath: '',
	firstName: '',
	lastName: '',
	countryCode: 'NL',
	street: '--',
	city: '--',
	postcode: '--',
	phoneNumber: '',
	//provinceCode:'',
	//provinceName:'',
	email: '',
	type: 'contact'
}

export const contactDTOInit = {
	firstName: '',
	lastName: '',
	countryCode: 'NL',
	street: '--',
	city: '--',
	postcode: '--',
	phoneNumber: '',
	//provinceCode:'',
	//provinceName:'',
	email: '',
	type: 'contact'
}

export function serializeContact(data): Contact {
	if ( !data ) return contactInit;

	return {
		...contactInit,
		id: data.id ? data.id : parseInt(data['@id'].replace( '/api/v2/shop/addresses/', '' )),
		idPath: data['@id'],
		firstName: data.firstName,
		lastName: data.firstName,
		countryCode: data.countryCode,
		street: data.street ? data.street : '',
		phoneNumber: data.phoneNumber,
		city: data.city,
		postcode: data.postcode,
		type: 'contact'
	}
}