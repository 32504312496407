export const dateFromString = (date: string): Date => {
    // Fix for an error in safari when the iso date contains +0000 format
    if (date.toString().indexOf('+') !== -1) {
        return new Date(date.split('+')[0])
    }

    // Fix NaN error in Safari
    const newDate = new Date(date.replace(/-/g, "/"));
    return new Date(newDate)
}

export const dateToString = (date: Date, reversed?: boolean): string => {
    if (reversed) {
        return date.getFullYear() + '-' + getMonth(date) + '-' + getDay(date)
    }
    return getDay(date) + '-' + getMonth(date) + '-' + date.getFullYear()
}

export const dateToStringWithTime = (date: Date): string => {
    return getDay(date) + '-' + getMonth(date) + '-' + date.getFullYear() + ' at ' + getHours(date) + ':' + getMinutes(date)
}

export const getDay = (date: Date): string => {
    return date.getDate() < 10 ? '0' + date.getDate() : '' + date.getDate()
}

export const getMonth = (date: Date): string => {
    return (date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : '' + (date.getMonth() + 1)
}

export const getHours = (date: Date): string => {
    return (date.getHours()) < 10 ? '0' + date.getHours() : '' + date.getHours()
}

export const getMinutes = (date: Date): string => {
    return (date.getMinutes()) < 10 ? '0' + date.getMinutes() : '' + date.getMinutes()
}

export const sortDateString = (firstDateString: string, secondDateString: string) => {
    const firstDate = dateFromString(firstDateString)
    const secondDate = dateFromString(secondDateString)
    return +secondDate - +firstDate
}
