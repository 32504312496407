import React, { useState, useEffect } from "react"
import { useHistory } from 'react-router-dom'
import MainPage from "components/elements/page/MainPage"
import Steps from "components/elements/Steps"
import Panel from "components/elements/Panel"
import Input from "components/elements/form/Input"
import Textarea from "components/elements/form/Textarea"
import Button from "components/elements/Button"
import PanelTitle from "components/elements/PanelTitle"
import ClientCreate from "../client/ClientCreate"
import ProductAdd from "./ProductAdd"
import OrderItem from "../order/OrderItem"
import VoicesSelect from "./VoicesSelect"
import Voice from "components/elements/Voice"
import PaginatedSelectBox from "components/elements/PaginatedSelectBox";
import {
	CollectionIcon,
	MicrophoneIcon
} from '@heroicons/react/outline'
import { toast } from "react-toastify"
import useToken from "hooks/useToken"
import { setActiveVoice } from "hooks/useActiveVoice"
import { useGetClients } from "hooks/api/clients"
import { usePostProject, usePutProject, usePutProjectVoices } from "hooks/api/projects"
import { useGetOrder } from "hooks/api/orders"
import { useAddProduct } from "hooks/api/order-items";
import { Voice as VoiceElement } from "types/Voice"
import { ProjectDTO, projectDTOInit } from "types/Project"


export default function ProjectCreate ( props: any ) {
	const history = useHistory()
	const [projectId, setProjectId] = useState<number>(0)

	const [orderId, setOrderId] = useState('')
	const {data: orderData} = useGetOrder(orderId)
	const {data, mutateAsync: postProject, isLoading: postProjectLoading} = usePostProject()
	const {mutateAsync: putProject} = usePutProject(projectId)
	const {mutateAsync: putProjectVoices} = usePutProjectVoices(projectId)

	const onOrderCreate = orderId => setOrderId(orderId)
	const {mutateAsync: addProduct} = useAddProduct(orderData, projectId, onOrderCreate)

	const { getLocalCustomerIdPath } = useToken();

	const [newProject, setNewProject] = useState<ProjectDTO>( { ...projectDTOInit, reseller: getLocalCustomerIdPath() } )

	const [showAddClient, setShowAddClient] = useState<boolean>( false )
	const [showAddProduct, setShowAddProduct] = useState<boolean>( false )
	const [showSelectVoices, setShowSelectVoices] = useState<boolean>( false )
	const [stepIndex, setStepIndex] = useState<number>( 0 )

	const [voices, setVoices] = useState<VoiceElement[]>( [] )

	const [clientValue, setClientValue] = useState<{value: string, name: string}>({value: "", name: "client"})

	const steps = [
		{ id: 'Step 1', name: 'Project details', href: '#' },
		{ id: 'Step 2', name: 'Select products', href: '#' },
		{ id: 'Step 3', name: 'Select voices', href: '#' },
	]

	useEffect(() =>{
		if(data && data.cart){
			setOrderId( data.cart.token )
		} else {
			setOrderId( '' )
		}
	}, [data])

	useEffect( () => {
		const voicesByPath = voices.map( item => { return item.idPath })
		setNewProject( { ...newProject, voices: voicesByPath } )
	}, [voices] )

	const closeAddClient = () => {
		setShowAddClient( false )
	}

	const closeSelectVoices = ( voices ) => {
		if ( voices ) {
			setVoices( voices )
		}
		setShowSelectVoices( false )
	}

	const closeAddProduct = ( product ) => {
		if (!product) return setShowAddProduct(false)
		return addProduct(product).then(() => {
			setShowAddProduct(false)
			toast.success( 'Product succesfully added' )
		})
	}

	const submitHandler = ( e ) => {
		e.preventDefault()
		if ( stepIndex === 0 ) {
			if (projectId) {
				updateProject().then(() => {
					toast.success('Project succesfully updated');
					setStepIndex(stepIndex + 1)
				})
			} else {
				createProject().then(project => {
					toast.success('Project succesfully created');
                    if (newProject.client)
					    setStepIndex(stepIndex + 1)
                    else
                        setStepIndex(stepIndex + 2)
				})
			}
		} else if ( stepIndex === 1 ) {
			setStepIndex( stepIndex + 1 )
		} else if ( stepIndex === 2 ) {
			if (voices.length === 0) return history.push( `/projects/${projectId}/overview` )
			const voicesIdPaths = { voices: voices.map(voice => voice.idPath) }
			putProjectVoices(voicesIdPaths).then(() => {
				toast.success(`Added ${voices.length} voices to project`);
				history.push( `/projects/${projectId}/overview` )
			})
		}
	}

	const createProject = () => {
		return postProject(newProject).then(project =>
			setProjectId(project.id)
		)
	}

	const updateProject = () => {
		return putProject(newProject)
	}

	const onInputChangeProject = ( e ) => {
		setNewProject( { ...newProject, [e.target.name]: e.target.value } )
	}

	useEffect( () => {
		if (clientValue.value)
			setNewProject( {...newProject, client: clientValue.value} )
		else
			setNewProject( {...newProject, client: ""} )
	}, [clientValue] )

	const onInputChangeProjectClient = ( client ) => {
		if (client)
			setClientValue( client );
		else
			setClientValue( {value: "", name: "client"} )
	}

    return (
		<MainPage title=''>
			<div className="mb-6">
				<Steps steps={steps} currentIndex={stepIndex} onChange={setStepIndex} />
			</div>

			<form onSubmit={submitHandler}>

				{stepIndex === 0 &&

					<Panel>

						<div className="flex flex-wrap md:flex-nowrap md:gap-8">
							<div className="w-full md:w-1/4 md:border-r">

								<div className="pr-4 h-full rounded">
									<PanelTitle>Project Details</PanelTitle>
									<p className="mt-1 mb-4 text-sm text-gray-500">
										In this first step we are going to create the project. We add information about the project and select or add a client to the project.
									</p>
								</div>

							</div>

							<div className="mt-8 w-full md:mt-0 md:w-3/4">

								<div className="space-y-6">
									<Input label="Name of the project" name="name" type="text" className="max-w-md" placeholder="Type the project name" defaultValue={newProject.name} onChange={onInputChangeProject} required />

									<div>
										<PaginatedSelectBox
											hook={useGetClients}
											parse={item => { return { name: item.company, value: item.idPath } } }
											label="Select a client"
											name="client"
											value={clientValue}
											onChange={onInputChangeProjectClient}
											optional={false}
										/>
										<div className="p-4 mt-4 max-w-md text-sm bg-gray-100 rounded">
											New client? <button className="ml-1 text-sm underline transition-colors duration-300 ease-in-out text-cobalt-500 hover:no-underline hover:text-cobalt-400" onClick={( e ) => { e.preventDefault(); setShowAddClient( true ) }}>Click to add new client</button>
										</div>
									</div>
									<Textarea label="Description" name="description" placeholder="Type a description of the project" defaultValue={newProject.description} onChange={onInputChangeProject} optional={true} />
								</div>
							</div>
						</div>
					</Panel>
				}

				{stepIndex === 1 &&

					<Panel >
						<div className="flex flex-wrap md:flex-nowrap md:gap-8">
							<div className="w-full md:w-1/4 md:border-r">

								<div className="pr-4 h-full rounded">
									<PanelTitle>Add products</PanelTitle>

									<p className="mt-1 mb-4 text-sm text-gray-500">
										A project can contain different products.
									</p>

									<p className="mt-1 mb-4 text-sm text-gray-500">
										In this step you can add products to your project. Not sure yet about which products to add? Don't worry, you can always add them later.
									</p>

                                    {newProject.client && <Button type="primary" onClick={() => setShowAddProduct( true )}>Add product</Button>}
								</div>

							</div>

							<div className="mt-8 w-full md:mt-0 md:w-3/4">

								<div className="space-y-6">
									{orderData && orderData.items.length > 0 ?

										<div className="space-y-6">
											{orderData.items.map( ( item, index ) => (

												<OrderItem key={index} order={orderData} product={item} voices={[]} editable={true} onUpdate={null}/>

											) )
											}

											<div>
												<div className="grid grid-cols-6 gap-4 lg:grid-cols-12">
													<div className="col-span-6 font-bold lg:col-span-9">
														Total
													</div>
													<div className="my-auto lg:col-start-10 xl:col-start-11">
														<span className="text-lg font-bold">
															{new Intl.NumberFormat( 'nl-NL', { style: 'currency', currency: 'EUR' } ).format( orderData.total / 100 )}
														</span>
													</div>
													<div className="w-6"></div>
												</div>
											</div>

										</div>
										:
										<div>
											<div className="mb-6">
												<CollectionIcon className="mx-auto w-16 h-16 text-gray-200" />
											</div>
                                            {newProject.client ? <p className="mx-auto max-w-lg text-center">
												There are no products added yet to this project.<br />Add them by pressing the add product button or <button onClick={() => setShowAddProduct( true )} className="text-cobalt-500">here</button>.
											</p> : <p className="mx-auto max-w-lg text-center">Your project cannot have products without a client.<br /> Please add a client in the previous step.</p>}
										</div>
									}
								</div>

							</div>
						</div>

					</Panel>

				}

				{stepIndex === 2 &&
					<Panel>
						<div className="flex flex-wrap md:flex-nowrap md:gap-8">
							<div className="w-full md:w-1/4 md:border-r">

								<div className="pr-4 h-full rounded">
									<PanelTitle>Add voices</PanelTitle>

									<p className="mt-1 mb-4 text-sm text-gray-500">
                                        A project can contain one or more selected voices.
									</p>

									<p className="mt-1 mb-4 text-sm text-gray-500">
                                        In this step you can add the voice you would like to book, or you can create a selection of voices that you want to present to your client.
									</p>

									<Button type="primary" onClick={() => setShowSelectVoices( true )}>Select voices</Button>
								</div>

							</div>

							<div className="mt-8 w-full md:mt-0 md:w-3/4">
								<div className="space-y-6">
									{voices.length > 0 ?

										<div className="grid grid-cols-3 gap-4 lg:grid-cols-4 xl:grid-cols-6 2xl:grid-cols-6">
											{voices.map( ( item, index ) => (
												<div className="h-auto aspect-w-10 aspect-h-10" key={item.id}>
													<Voice onClick={(e) => {e.preventDefault(); setActiveVoice(item) }} voice={item} selectable={false} />
												</div>
											) )}
										</div>
										:
										<div>
											<div className="mb-6">
												<MicrophoneIcon className="mx-auto w-16 h-16 text-gray-200" />
											</div>
											<p className="mx-auto max-w-lg text-center">
												There are no voices selected for this project yet.<br />Add them by pressing the select voices button or <button onClick={() => setShowSelectVoices( true )} className="text-cobalt-500">here</button>.
											</p>
										</div>
									}
								</div>
							</div>
						</div>
					</Panel>
				}

				<div className="flex justify-end items-center">
					<div className="space-x-2">
						{!!stepIndex && <Button type="grayoutline" className="" onClick={() => setStepIndex(stepIndex - 1)} >Back</Button>}
						<Button type="primary" submit={true} loading={postProjectLoading}>{stepIndex === 2 ? 'Finish' : 'Next Step'}</Button>
					</div>
				</div>

			</form>

			<ClientCreate show={showAddClient} onClose={() => closeAddClient()} />
			<ProductAdd show={showAddProduct} onClose={closeAddProduct} />
			<VoicesSelect show={showSelectVoices} voices={voices} onClose={closeSelectVoices} />

		</MainPage>
	)

}
