import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { Menu } from '@headlessui/react'
import useStyling from 'hooks/useStyling'
import { usePopper } from 'react-popper'
import { DotsVerticalIcon } from '@heroicons/react/outline'
import ConfirmationDialog from 'components/elements/ConfirmationDialog'
import ContactEdit from './ContactEdit'
import { useDeleteContact } from 'hooks/api/contacts'
import { Contact } from 'types/Contact'

const ContactCard: React.FC<{
	contact: Contact
	onEdit: () => void
}> = ( props ) => {
	const { clientId } = useParams()
	const {switchClassNames} = useStyling()
	const [referenceElement, setReferenceElement] = useState<HTMLButtonElement | null>(null)
	const [popperElement, setPopperElement] = useState<HTMLDivElement | null>( null )
	const [showConfirmDialog, setShowConfirmDialog] = useState( false )
	const [showEditContact, setShowEditContact] = useState( false )
	const { styles, attributes } = usePopper( referenceElement, popperElement,{
		placement: "left-start"
	});
	const { mutateAsync: deleteContact, isLoading } = useDeleteContact( props.contact.id ? props.contact.id : 0, clientId )

	const deleteHandler = () => {
		deleteContact().then(resp => {
			setShowConfirmDialog( false )
			if(props.onEdit) props.onEdit()
		})
	}

	return (
		<div key={props.contact.email} className="flex relative items-center px-6 py-5 space-x-3 bg-white rounded-lg border border-gray-300 shadow-sm">
			<div className="flex-1 min-w-0">
				<div className="focus:outline-none">
					<span className="absolute inset-0" aria-hidden="true" />
					<p className="text-sm font-medium text-gray-900">{props.contact.firstName} {props.contact.lastName}</p>
					<p className="text-sm text-gray-500 truncate">{props.contact.email}</p>
					{props.contact.phoneNumber &&<p className="text-sm text-gray-500 truncate">{props.contact.phoneNumber}</p>}
				</div>
			</div>

			<Menu as="div" className="inline-block relative text-left">
				<Menu.Button ref={setReferenceElement} className="text-gray-700 transition-colors duration-300 ease-in-out hover:text-gray-400">
					<DotsVerticalIcon className="w-5 h-5"/>
				</Menu.Button>
				<Menu.Items ref={setPopperElement} style={styles.popper} {...attributes.popper} className="z-50 w-32 bg-white rounded-md divide-y divide-gray-100 ring-1 ring-black ring-opacity-5 shadow-lg focus:outline-none">
					<div className="p-1">
						<Menu.Item>
							{( { active } ) => (
								<button
								onClick={()=>setShowEditContact(true)}
								className={switchClassNames(
									active ? 'bg-gray-100' : '',
									'block text-left w-full px-4 py-2 text-sm text-gray-700'
								)}
								>

									Edit
								</button>
							)}
						</Menu.Item>
						<Menu.Item>
							{( { active } ) => (
								<button
								onClick={()=>{setShowConfirmDialog(true)}}
								className={switchClassNames(
									active ? 'bg-gray-100' : '',
									'block text-left w-full px-4 py-2 text-sm text-gray-700'
								)}
								>

									Delete
								</button>
							)}
						</Menu.Item>
					</div>
				</Menu.Items>
			</Menu>

			<ConfirmationDialog isShow={showConfirmDialog} title="Are you sure?" confirmText="Yes, delete" type="danger" loading={isLoading} onClose={() => { setShowConfirmDialog( false ) }} onConfirm={() => { deleteHandler() }}>
				<p className="text-sm text-gray-500">Are you sure you want to delete this contact?</p>
			</ConfirmationDialog>

			<ContactEdit show={showEditContact} contact={props.contact} onClose={() => setShowEditContact( false )} />
		</div>
	)
}

export default ContactCard