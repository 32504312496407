import { useState, useEffect } from 'react'
import { useMutation } from 'react-query'
import useQueryClient from '../useQueryClient'
import useToken from '../useToken'
import { postThemeMedia, deleteThemeMedia } from 'services/requests/themes-media'
import { useGetCustomer } from './customers'
import { useGetTheme } from './themes'
import {themeMediaInit} from "types/ThemesMedia";

export const useThemeMedia = (themeIdOverride = 0) => {
    const { getLocalCustomerId } = useToken()
    const [themeId, setThemeId] = useState<number>(themeIdOverride ?? 0)

    const {data: dataCustomer, isLoading: loadingCustomer } = useGetCustomer(getLocalCustomerId())
    const {data: dataTheme, isLoading: loadingTheme } = useGetTheme(themeId)

    useEffect(() => {
        if (themeIdOverride || !dataCustomer) return
        setThemeId(dataCustomer.reseller ? dataCustomer.reseller.themeId : dataCustomer.theme ? dataCustomer.themeId : 0)
    }, [dataCustomer])

    return (types) => {
        if (!dataTheme) return {
            isLoading: loadingCustomer || loadingTheme,
            data: themeMediaInit
        }
        const img = dataTheme.media.sort((a, b) => b.id - a.id).find(media => types.includes(media.type))
        return {
            isLoading: loadingCustomer || loadingTheme,
            data: img ? img : themeMediaInit
        }
    }
}

export const usePostThemeMedia = (themeId) => {
    const queryClient = useQueryClient()
    return useMutation<any, unknown, FormData>(themeMedia => postThemeMedia(themeMedia), {
        onSuccess: () => queryClient.invalidateQueries(['themes', themeId])
    })
}

export const useDeleteThemeMedia = () => {
    return useMutation<any, unknown, number>(imageId => deleteThemeMedia(imageId))
}
