import { Menu } from "Routes"
import { useParams } from "react-router-dom"
import { useGetProject } from "hooks/api/projects"
import RouteBuilder from "components/elements/RouteBuilder"
import MainPage from "components/elements/page/MainPage"
import TabBar from "components/elements/TabBar"

export default function Project(props: any) {
	const {projectId} = useParams()
	const { data, isLoading: loading }  = useGetProject(projectId);

	return (
		<MainPage title={data?.name} isLoading={loading} >
			<TabBar routes={props.routes.filter((item) => item.menu === Menu.MENU_PROJECT)}/>
			<RouteBuilder routes={props.routes} data={{ data: data }} />
		</MainPage>
	)
}