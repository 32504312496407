/* This example requires Tailwind CSS v2.0+ */
import React from 'react'
import { Transition } from '@headlessui/react';
import { ExclamationIcon, XCircleIcon, CheckCircleIcon, InformationCircleIcon, XIcon } from '@heroicons/react/solid'

export interface AlertProps extends React.HTMLAttributes<HTMLElement> {
	show: boolean
	type?: 'success' | 'warning' | 'danger' | 'info'
	icon?: boolean
	dismiss?: boolean
	onDismiss?: (e: React.MouseEvent) => void
	link?: boolean
	linkText?: string,
	onLink?: (e: React.MouseEvent) => void
}

const typeStyle = {
	success: {
		default: 'bg-green-50 text-green-800',
		icon:'text-green-400',
		action:'bg-green-50 text-green-500 hover:bg-green-100 focus:ring-offset-green-50 focus:ring-green-600',
		link: 'text-green-700 hover:text-green-600'
	},
	warning: {
		default: 'bg-yellow-50 text-yellow-700',
		icon: 'text-yellow-400',
		action: 'bg-yellow-50 text-yellow-500 hover:bg-yellow-100 focus:ring-offset-yellow-50 focus:ring-yellow-600',
		link: 'text-yellow-700 hover:text-yellow-600'
	},
	danger: {
		default: 'bg-red-50 text-red-700',
		icon: 'text-red-400',
		action: 'bg-red-50 text-red-500 hover:bg-red-100 focus:ring-offset-red-50 focus:ring-red-600',
		link: 'text-red-700 hover:text-red-600'
	},
	info: {
		default: 'bg-blue-50 text-blue-700',
		icon: 'text-blue-400',
		action: 'bg-blue-50 text-blue-500 hover:bg-blue-100 focus:ring-offset-blue-50 focus:ring-blue-600',
		link: 'text-blue-700 hover:text-blue-600'
	}
};


const icontype = (type) => {
	if(type === 'success'){
		return (<CheckCircleIcon className={` ${typeStyle[type]['icon']} w-5 h-5`} aria-hidden="true" />)
	}
	else if (type === 'warning') {
		return (<ExclamationIcon className={` ${typeStyle[type]['icon']} w-5 h-5`} aria-hidden="true" />)
	}
	else if (type === 'danger') {
		return (<XCircleIcon className={` ${typeStyle[type]['icon']} w-5 h-5`} aria-hidden="true" />)
	}
	else if (type === 'info') {
		return (<InformationCircleIcon className={` ${typeStyle[type]['icon']} w-5 h-5`} aria-hidden="true" />)
	}
	return null
}

const Alert: React.FC<AlertProps> = ({
	show = false,
	type = 'info',
	icon = true,
	dismiss = false,
	onDismiss,
	link = false,
	linkText = 'Info',
	onLink,
	children
}) =>
{
	return (
		<Transition
			show={show}
			enter="transition-opacity duration-300"
			enterFrom="opacity-0"
			enterTo="opacity-100"
			leave="transition-opacity duration-300"
			leaveFrom="opacity-100"
			leaveTo="opacity-0"
		>
		<div className={` ${typeStyle[type]['default']} p-4 mb-4 rounded-md`}>
			<div className="flex">
				{icon && <div className="flex-shrink-0">
					{icontype(type)}
				</div>
				}
				<div className="ml-3">
					<p className="text-sm font-medium text-current ">{children}</p>
				</div>
				<div className="pl-3 ml-auto">
											
					{dismiss && 
						<div className="-mx-1.5 -my-1.5">
						<button
							type="button"
							className={` ${typeStyle[type]['action']} inline-flex rounded-md p-1.5 focus:outline-none focus:ring-2 focus:ring-offset-2`}
							onClick={onDismiss}
						>
							<span className="sr-only">Dismiss</span>
							<XIcon className="w-5 h-5" aria-hidden="true" />
						</button>
						</div>
					}


					{link && <p className="mt-3 text-sm md:mt-0 md:ml-6">
						<button onClick={onLink} className={` ${typeStyle[type]['link']} font-medium whitespace-nowrap cursor-pointer `}>
							{linkText} <span aria-hidden="true">&rarr;</span>
						</button>
						</p>
					}
				</div>
			</div>
		</div>
		</Transition>
	)
}

export default Alert