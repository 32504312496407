export interface ThemeMedia {
    id: number
    type: string
    path: string
}

export const themeMediaInit: ThemeMedia =  {
    id: 0,
    type: '',
    path: ''
}


export function serializeThemeMedia( data ): ThemeMedia {
    if ( !data ) return themeMediaInit;

    return {
        ...themeMediaInit,
        id: data.id ? data.id : 0,
        type: data.type,
        path: data.path,
    }
}
