const useStyling = (): {
	switchClassNames: (...classes: any) => string,
} => {

	const switchClassNames = (...classes: any): string => {

		return classes.filter(Boolean).join(' ')
	}

	return { switchClassNames }
}

export default useStyling
