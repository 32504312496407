import request from "services/Request"
import { serializeVoices, serializeVoice } from "types/Voice"

export function getVoices(params) {
	return request({
		url: `/shop/voices`,
		method: 'GET',
		params
	}, serializeVoices)
}

export function getVoiceByUrl(url: string) {
	return request({
		url: url.startsWith('/api/v2/shop/products/') ?
            url.replace('/api/v2/shop/products', '/shop/voices') :
            url.replace('/api/v2', ''),
		method: 'GET'
	}, serializeVoice)
}

export function getVoiceByCode(code: string) {
	return request({
		url: `/shop/voices/${code}`,
		method: 'GET'
	}, serializeVoice)
}
