import React, { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import Button from './Button';

export interface ConfirmationDialogProps extends React.HTMLAttributes < HTMLElement > {
	type?: 'primary' | 'danger' | 'warning' | 'outline' | 'grayoutline'
	isShow: boolean
	title?: string
	confirmText?: string
	cancelText?: string
	hideCancel?: boolean
	loading?: boolean
	onClose: () => void
	onConfirm:() => void
};

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ( props:ConfirmationDialogProps ) => {

	return (
		<Transition show={props.isShow} as={Fragment}>
			<Dialog as="div" className="fixed inset-0 z-10 overflow-y-auto" onClose={props.onClose}>
				<div className="min-h-screen px-4 text-center">
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-out duration-200 delay-100"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<Dialog.Overlay className="fixed inset-0 transition-opacity bg-black bg-opacity-50" />
					</Transition.Child>

					{/* This element is to trick the browser into centering the modal contents. */}
					<span className="inline-block h-screen align-middle" aria-hidden="true">
						&#8203;
					</span>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0 scale-95"
						enterTo="opacity-100 scale-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100 scale-100"
						leaveTo="opacity-0 scale-95"
					>
						<div className="inline-block w-full max-w-lg p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white rounded-md shadow-xl">
							<Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
								{props.title ? props.title : '-'}
							</Dialog.Title>
							<div className="mt-2">
								{props.children}
								
							</div>

							<div className="flex justify-end mt-4 space-x-4">
								{!props.hideCancel && 
									<Button type="grayoutline" onClick={props.onClose}>
										{props.cancelText ? props.cancelText: 'Cancel'}
									</Button>
								}
								<Button type={props.type? props.type : 'primary'} loading={props.loading} onClick={props.onConfirm}>
									{props.confirmText ? props.confirmText: 'Okay'}
								</Button>
							</div>
						</div>
					</Transition.Child>
				</div>
			</Dialog>
		</Transition>
	)
}

export default ConfirmationDialog