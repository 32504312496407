export interface Product {
	id?: number
	idPath?: string
	name: string
	variants: string[]
	type: string
}

export const productInit: Product = {
	name: '',
	variants: [],
	type: ''
}

export function serializeProduct(data: any): Product {
	if ( !data || !data.mainTaxon) return productInit


	const mainTaxon = data.mainTaxon.replace('/api/v2/shop/taxons/', '')

	return {
		id: data.id ? data.id : 0,
		idPath: data['@id'],
		name: data.name,
		variants: data.variants,
		type: mainTaxon.includes('product') ? 'product' : mainTaxon
	}
}

export function serializeProducts(data: any): {items: Product[], totalItems: number } {
	return {
		items: data['hydra:member'].map(item => serializeProduct(item)),
		totalItems: data['hydra:totalItems']
	}
}

