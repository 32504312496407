import { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { sortDateString } from "../common/DateUtils"
import { useGetComments, usePutComment } from "hooks/api/comments"
import { Notification } from "types/Notification"
import { Comment } from "types/Comment"


function useMapCommentToNotification(): (comment: Comment) => Notification {
    const history = useHistory()
    const { mutate: putComment } = usePutComment()

    return comment => {
        return {
            title: 'Received feedback',
            message: comment.comment,
            createdAt: comment.createdAt,
            read: comment.payload && !!comment.payload.read,
            linkText: 'Go to project',
            onClick: () => {
                history.push(`/projects/${comment.project?.id}/communication`)
                if (comment.payload && !comment.payload.read) {
                    putComment({id: comment.id ? comment.id : 0, comment: {payload: {...comment.payload, read: true}}})
                }
            }
        }
    }
}

export const useNotifications = ({ currentPage, pageSize }) => {
    const mapCommentToNotification = useMapCommentToNotification()
    const [notifications, setNotifications]= useState<Notification[]>([])
    const [totalItems, setTotalItems] = useState<number>(0)
    const { data, isFetching } = useGetComments({ page: currentPage, itemsPerPage: pageSize })


    useEffect(() => {
        if (!data) return
        setTotalItems(data.totalItems)
        const newNotifications = data.items
            .map(item => item.children.filter(item => item.payload && item.payload.voices))
            .flat()
            .map(item => mapCommentToNotification(item))
        if (currentPage === 1) return setNotifications([...newNotifications].sort((a, b) => sortDateString(a.createdAt, b.createdAt)))
        setNotifications([...notifications, ...newNotifications].sort((a, b) => sortDateString(a.createdAt, b.createdAt)))
    }, [data])

    return { data: notifications, isFetching, moreAvailable: currentPage * pageSize < totalItems }

}