import React, {useEffect, useState} from 'react'
import { useHistory } from 'react-router-dom'
import { useGetVoicesByUrlArray } from 'hooks/api/voices'
import PanelTitle from 'components/elements/PanelTitle'
import OrderItem from './OrderItem'
import Panel from 'components/elements/Panel'
import Button from 'components/elements/Button'
import LoaderSpinning from 'components/elements/LoaderSpinning'

export default function SelectedProducts({
     order,
     voices,
     setShowProductAdd,
     contextIsCart = true, // if true displayed as order is not yet finished
     isLoading = true
 }) {
    const history = useHistory()
    const [projectOverview, setProjectOverview] = useState<boolean>(false)
    const [priceDifference, setPriceDifference] = useState<number>(0)
    const {data: dataVoices, isLoading: loadingVoices} = useGetVoicesByUrlArray(voices)

    useEffect(() => {
        setProjectOverview(setShowProductAdd ? true : false)
    }, [setShowProductAdd])

    const previewOrder = () => {
        history.push('./order-overview')
    }

    const orderIsClosed = () => order.state !== 'parked' && order.state !== 'cart'
    const showOrder = () => order && (contextIsCart && !orderIsClosed() || !contextIsCart) && order.items.length !== 0

    return (
        !contextIsCart && !showOrder() ? <></> : <Panel>
            <div className="flex justify-between items-center">
                <PanelTitle>{showOrder() ? 'Selected products' : 'Add products to your project'}</PanelTitle>
                {projectOverview && (
                    <button onClick={() => setShowProductAdd(true)} className="mb-4 text-sm underline text-cobalt-500 hover:no-underline hover:cursor-pointer">Add product</button>
                )}
            </div>

            <div className="px-8 pt-16 pb-0 -mx-8 mt-4 border-t border-gray-200">
                {isLoading && <LoaderSpinning className="mt-6" />}

                {!isLoading && !showOrder() && <>
                    <p className="mb-4">A project can contain different products. Add the products that you would like for this project. You can always add products at a later moment if you want to.</p>
                    <Button onClick={() => setShowProductAdd(true)}>Add product</Button>
                </>}

                {showOrder() && voices && order.items.map(item =>
                    <OrderItem key={`${item.id}-${item.instructions[0].payload.index}`} order={order} product={item} voices={dataVoices} editable={projectOverview} onUpdate={diff => setPriceDifference(priceDifference + diff)}  />
                )}
            </div>

            {showOrder() && <div className="flex grid grid-cols-6 gap-4 lg:grid-cols-12 align-start">
                <div className="col-span-6 mb-4 leading-8 lg:px-5 lg:col-span-9">
                    <h3 className="mb-0">Sub total</h3>
                </div>
                <div className="col-span-6 lg:col-start-10 xl:col-start-11 lg:col-span-2">
                    <h3 className="text-xs text-right lg:text-lg">{new Intl.NumberFormat( 'nl-NL', { style: 'currency', currency: 'EUR' } ).format((order.subtotal) / 100)}</h3>
                </div>
                <div className="col-span-6 mb-4 leading-8 lg:px-5 lg:col-span-9">
                    <h3 className="mb-0">Tax</h3>
                </div>
                <div className="col-span-6 lg:col-start-10 xl:col-start-11 lg:col-span-2">
                    <h3 className="text-xs text-right lg:text-lg">{new Intl.NumberFormat( 'nl-NL', { style: 'currency', currency: 'EUR' } ).format((order.tax) / 100)}</h3>
                </div>
                <div className="col-span-12">
                    <hr className="pt-4 pb-0 mt-8 border-t border-gray-200"/>
                </div>
                <div className="col-span-6 mb-4 leading-8 lg:px-5 lg:col-span-9">
                    <h3 className="mb-0 font-bold">Total</h3>
                </div>
                <div className="col-span-6 lg:col-start-10 xl:col-start-11 lg:col-span-2">
                    <h3 className="text-xs font-bold text-right lg:text-lg">{new Intl.NumberFormat( 'nl-NL', { style: 'currency', currency: 'EUR' } ).format((order.total) / 100)}</h3>
                </div>
            </div>}
            {showOrder() && projectOverview && (<div className="float-right mt-8">
                <Button onClick={previewOrder}>Preview Order</Button>
            </div>)}
        </Panel>
    )
}