import request from "services/Request"
import { serializeOrders, serializeOrder } from "types/Order"

export function getOrderItems(orderId: string) {
	return request({
		url: `/shop/orders/${orderId}/items`,
		method: 'GET'
	}, serializeOrders)
}

export function getOrderItem(orderId: string, itemId: number) {
	return request({
		url: `/shop/orders/${orderId}/items/${itemId}`,
		method: 'GET'
	}, serializeOrder)
}

export function postOrderItem(orderId: string, data) {
	return request({
		url: `/shop/orders/${orderId}/items`,
		method: 'POST',
		data
	}, serializeOrder)
}

export function putOrderItem(orderId: string, itemId: number, data) {
	return request({
		url: `/shop/orders/${orderId}/items/${itemId}`,
		method: 'PUT',
		data
	}, serializeOrder)
}

export function patchOrderItem(orderId: string, itemId: number, data) {
	return request({
		url: `/shop/orders/${orderId}/items/${itemId}`,
		method: 'PATCH',
		headers: {
			"Content-Type": "application/merge-patch+json"
		},
		data
	}, serializeOrder)
}

export function deleteOrderItem(orderId: string, itemId: number) {
	return request({
		url: `/shop/orders/${orderId}/items/${itemId}`,
		method: 'DELETE'
	}, serializeOrder)
}