import React from "react";
import { useParams, useHistory } from "react-router-dom";
import PanelTitle from "components/elements/PanelTitle";
import Panel from "components/elements/Panel";
import PanelFooter from "components/elements/PanelFooter";
import Button from "components/elements/Button";

export default function OrderCompleted() {
	let { projectId } = useParams();
	const history = useHistory()

	const goToProjectOverview = () => {
		history.push(`/projects/${projectId}/overview`);
	}

	return (
		<Panel className="text-base">
			<PanelTitle>Thank you for your order!</PanelTitle>
			<p>Your order has been completed, and you might be wondering: what happens next?</p><br/>
			<ol className="text-sm list-decimal ml-4">
				<li>You will receive an order confirmation by email within a few moments.</li>
				<li>
					We schedule a studio recording with the selected voice.
					If the payment has been successful and you have received the order confirmation, then everything has been processed correctly and we start working on your project.
					We will contact the selected voice and schedule a recording session as soon as possible.
				</li>
			</ol><br/>
			<p>Feel free to reach out with any questions or concerns that may arise.</p>
			<PanelFooter>
				<Button type='primary' onClick={goToProjectOverview}>Back to Project</Button>
			</PanelFooter>
		</Panel>
	)
}
